import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Nav, Tabs, Tab, Dropdown, Form, Modal, Button } from "react-bootstrap";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default function Fiction() {
    const [entries, setEntries] = useState([{}]);  // Initialize with one entry
    const [entriesNar, setEntriesNar] = useState([{}]);
    const [selection, setSelection] = useState('yes');

    const addNewCharacter = () => {
        setEntries([...entries, {}]);  // Add a new empty object to represent a new character form
    };
    const addNewNarrator = () => {
        setEntriesNar([...entriesNar, {}]);  // Add a new empty object to represent a new character form
    };

    const handleChange = (index, value) => {
        // Create a new copy of entries and update the specific entry's value
        const newEntries = [...entries];
        newEntries[index] = { ...newEntries[index], value };
        setEntries(newEntries);
    };

    const handleChangeNar = (index, value) => {
        // Create a new copy of entries and update the specific entry's value
        const newEntries = [...entriesNar];
        newEntries[index] = { ...newEntries[index], value };
        setEntriesNar(newEntries);
    };

    const handleDelete = (index) => {
        // Remove the entry at the specified index
        const filteredEntries = entries.filter((_, i) => i !== index);
        setEntries(filteredEntries);
    };
    const handleDeleteNar = (index) => {
        const filteredEntries = entriesNar.filter((_, i) => i !== index);
        setEntriesNar(filteredEntries);
    };

    return (
        <>
            <div className="nar-box">
                {entriesNar.map((entry, index) => (
                    <Row className="narr-row">
                        <Button variant="danger" onClick={() => handleDeleteNar(index)}>
                            <FontAwesomeIcon icon={solid("trash")} />
                        </Button>
                        <Col md={12}>
                            <Form.Group className="mb-3" controlId="fic-n-name">
                                <Form.Label>What is the narrator's name?</Form.Label>
                                <Form.Control type="text" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="fic-n-gen">
                                <Form.Label>What is the narrator's gender?</Form.Label>
                                <Form.Control type="text" />
                            </Form.Group>
                            <p>Will the narrator be the voice for all the characters in the book, like a traditional audiobook? (Recommended)</p>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name={`yesVFA-${index}`}
                                    id={`yesVFA-${index}`}
                                    value="yes"
                                    checked={entry.value === 'yes'}
                                    onChange={() => handleChangeNar(index, 'yes')}
                                />
                                <label className="form-check-label" htmlFor={`yesVFA-${index}`}>
                                    Yes
                                </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name={`yesVFA-${index}`}
                                    id={`noVFA-${index}`}
                                    value="no"
                                    checked={entry.value === 'no'}
                                    onChange={() => handleChangeNar(index, 'no')}
                                />
                                <label className="form-check-label" htmlFor={`noVFA-${index}`}>
                                    No
                                </label>
                            </div>

                            {entry.value === 'yes' && (
                                <div className="mt-3">
                                    <p>Are you cloning your voice (and/or someone else’s) to be a narrator?</p>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name={`yesClone-${index}`}
                                            id={`yesClone-${index}`}
                                            value="yes"
                                            checked={selection === 'yes'}
                                            onChange={() => setSelection('yes')}
                                        />
                                        <label className="form-check-label" htmlFor={`yesClone-${index}`}>
                                            Yes
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name={`yesClone-${index}`}
                                            id={`noClone-${index}`}
                                            value="no"
                                            checked={selection === 'no'}
                                            onChange={() => setSelection('no')}
                                        />
                                        <label className="form-check-label" htmlFor={`noClone-${index}`}>
                                            No
                                        </label>
                                    </div>

                                    {selection === 'yes' ? (
                                        <div id="div1">
                                            <p>Record your voice or someone else’s voice (with permission) acting as the narrator for at least 2 minutes,
                                                reading anything (preferably your book), in a very quiet space with no resonance (for example, record in
                                                a closet full of clothing).</p>
                                            <p>When reading aloud, be mindful of voice clarity, reading pace, and background noises (even as faint as
                                                an HVAC blowing thru the wall vents). Artificial Intelligence (A.I.) takes everything it hears into
                                                consideration when cloning a voice. Once completed, upload the voice file in MP3 format.</p>
                                            <p>If you have multiple narrators where you or other people’s voices (with permission) will be acting as
                                                different narrators, repeat this process by clicking the button “Add New Narrator”.</p>
                                            <Form.Control type="file" />
                                        </div>
                                    ) : (
                                        <div id="div2">
                                            <p>Create a free account at ElevenLabs (hyperlink).
                                                Next, go to “Voice Library” and select a voice for
                                                the character. Please enter the voice name and
                                                title description, below (example: Marcus - Strong
                                                Gamer)</p>
                                            <Form.Group className="mb-3">
                                                <Form.Control type="text" />
                                            </Form.Group>
                                            <p>If you have multiple characters, repeat this
                                                process by clicking the button “Add New
                                                Character”.</p>
                                        </div>
                                    )}

                                </div>
                            )}
                            {entry.value === 'no' && (
                                <div className="mt-3">
                                    <p>Complete this narrator section for at least one narrator, then
                                        click the button, “Add New Character” to select voices for each character in your book.</p>

                                </div>
                            )}

                        </Col>
                    </Row>
                ))}
                <Button className="btn-style yellow-btn mb-2" onClick={addNewNarrator}>Add New Narrator</Button>
            </div>
            <div className="carac-box">
                {entries.map((entry, index) => (
                    <Row className="narr-row" key={index}>
                        <Button variant="danger" onClick={() => handleDelete(index)}>
                            <FontAwesomeIcon icon={solid("trash")} />
                        </Button>
                        <Col md={12}>
                            <Form.Group className="mb-3" controlId={`fic-c-name-${index}`}>
                                <Form.Label>What is the character’s name?</Form.Label>
                                <Form.Control type="text" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId={`fic-c-gen-${index}`}>
                                <Form.Label>What’s the character’s gender?</Form.Label>
                                <Form.Control type="text" />
                            </Form.Group>
                            <p>Are you cloning your voice (and/or someone else’s) to be a character?</p>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name={`yesNoRadio-${index}`}
                                    id={`yesRadio-${index}`}
                                    value="yes"
                                    checked={entry.value === 'yes'}
                                    onChange={() => handleChange(index, 'yes')}
                                />
                                <label className="form-check-label" htmlFor={`yesRadio-${index}`}>
                                    Yes
                                </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name={`yesNoRadio-${index}`}
                                    id={`noRadio-${index}`}
                                    value="no"
                                    checked={entry.value === 'no'}
                                    onChange={() => handleChange(index, 'no')}
                                />
                                <label className="form-check-label" htmlFor={`noRadio-${index}`}>
                                    No
                                </label>
                            </div>

                            {entry.value === 'yes' && (
                                <div className="mt-3">
                                    <p>Record your voice or someone else’s voice (with permission) acting as the character for at least 2
                                        minutes, reading anything (preferably your book), in a very quiet space with no resonance (for
                                        example, record in a closet full of clothing). Please read aloud in the voice of the character while
                                        recording.</p>
                                    <p>When reading aloud, be mindful of voice clarity, reading pace, and background noises (even as faint
                                        as an HVAC blowing thru the wall vents). Artificial Intelligence (A.I.) takes everything it hears into
                                        consideration when cloning a voice. Once competed, upload the voice file in MP3 format.</p>
                                    <p>If you have multiple characters where you or other people’s voices (with permission) will be acting as the characters, repeat this process by clicking the button “Add New Character”.</p>
                                    <Form.Control type="file" />
                                </div>
                            )}
                            {entry.value === 'no' && (
                                <div className="mt-3">
                                    <p>Create a free account at ElevenLabs (hyperlink).
                                        Next, go to “Voice Library” and select a voice for
                                        the character. Please enter the voice name and
                                        title description, below (example: Marcus - Strong
                                        Gamer)</p>
                                    <Form.Group className="mb-3">
                                        <Form.Control type="text" />
                                    </Form.Group>
                                    <p>If you have multiple characters, repeat this
                                        process by clicking the button “Add New
                                        Character</p>
                                </div>
                            )}
                        </Col>
                    </Row>
                ))}
                <Button className="btn-style yellow-btn mb-2" onClick={addNewCharacter}>Add New Character</Button>
            </div>
        </>
    )
}
