import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// MEDIA
import produceicon from "assets/005-book.png";
import publishicon from "assets/007-book-1.png";

export default function Produce() {
    const [expanded, setExpanded] = useState(false);
    const setExpand = () => {
        setExpanded(false);
        window.scrollTo(0, 0);
    };
    return (
        <>
            <div className='dbody-area'>
                <div className='dpage-heading'>
                    <h1>New Audiobook Production Options</h1>
                </div>
                <div className='db-content'>
                    <div className='start-pro'>
                        <Row className='justify-content-center'>
                            <Col md={4}>
                                <div className='spro-box'>
                                    <div className='icon-holder'>
                                        <img src={publishicon} alt='icon' />
                                    </div>
                                    <h4>Produce an A.I Audiobook</h4>
                                    <p>Follow our guidelines for producing A.I Audiobook</p>
                                    <Link to='/theblaklisted/newaudiobook' className='btn-style yellow-btn'>Get Started</Link>

                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    )
}
