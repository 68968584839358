import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AgreementDetails from "theblaklisted/components/AgreementDetails";

export default function Agreement() {
  return (
    <>
      <div className="dbody-area">
        <div className="dpage-heading">
          <h1>Audiobook Title</h1>
        </div>
        <div className="db-content">
          <div className="top-checks">
            <ul>
              <li><Link to='/theblaklisted/publish' className="step-done"><FontAwesomeIcon icon={solid("square-check")} /> <span>Get Started</span></Link></li>
              <li><Link to='/theblaklisted/publish-metadata' className="step-done"><FontAwesomeIcon icon={solid("square-check")} /> <span>Metadata</span></Link></li>
              <li><Link to='/theblaklisted/publish-audio-and-covers' className="step-done"><FontAwesomeIcon icon={solid("square-check")} /> <span>Audio & Covers</span></Link></li>
              <li><Link to='/theblaklisted/publish-agreement'><FontAwesomeIcon icon={regular("square")} /> <span>Agreement</span></Link></li>
            </ul>
          </div>
          <div className="forms-area">
            <div className="start-form">
              <h3>Distribution & Publication Agreement</h3>
              <div className="heading-with-note mt-4">
                <h4>Distribution Options</h4>
              </div>
              <div className="agree-checks">
                <Row>
                  <Col md={4}>
                    <Form.Check
                      type="checkbox"
                      label="Audible/Amazon"
                      checked
                    />
                  </Col>
                  <Col md={8}>
                    <Row>
                      <Col md={6}>
                        <p>
                          Use BlaklistEd to sell your audiobooks through
                          Audible.
                        </p>
                      </Col>
                      <Col md={6}>
                        <p>
                          You receive 70% of the net income from the sale of
                          your audiobook through these channels.
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className="agree-checks">
                <Row>
                  <Col md={4}>
                    <Form.Check
                      type="checkbox"
                      label="Preferred Channels"
                      checked
                    />
                  </Col>
                  <Col md={8}>
                    <Row>
                      <Col md={6}>
                        <p>
                          List your audiobooks with these channels to make them
                          available everywhere in the market and get maximum
                          exposure to all kinds of audiobook listeners.
                        </p>
                      </Col>
                      <Col md={6}>
                        <p>
                          You receive 70% of the net income from the sale of
                          your audiobook through these channels.
                        </p>
                      </Col>
                      <Col md={12}>
                        <div className="agreement-links">
                          <div class="distributorsList">
                            <a
                              href="https://www.audiobooks.com"
                              target="_blank"
                            >
                              Audiobooks.com
                            </a>
                            <a
                              href="https://www.hoopladigital.com/"
                              target="_blank"
                            >
                              Hoopla
                            </a>
                            <a
                              href="https://audiobookstore.com/"
                              target="_blank"
                            >
                              The Audiobook Store
                            </a>
                            <a href="http://www.downpour.com/" target="_blank">
                              Downpour
                            </a>
                            <a
                              href="https://www.overdrive.com/"
                              target="_blank"
                            >
                              Overdrive
                            </a>
                            <a href="https://libro.fm/" target="_blank">
                              Libro.FM
                            </a>
                            <a
                              href="https://www.audiobooksnow.com/"
                              target="_blank"
                            >
                              Audiobooks Now
                            </a>
                            <a href="https://www.storytel.com/" target="_blank">
                              StoryTel
                            </a>
                            <a href="https://bookmate.com/" target="_blank">
                              BookMate
                            </a>
                            <a href="https://www.nextory.se/j" target="_blank">
                              Nextory
                            </a>
                            <a href="https://www.kobo.com/" target="_blank">
                              Kobo
                            </a>
                            <a href="https://www.scribd.com/" target="_blank">
                              Scribd, Inc.
                            </a>
                            <a
                              href="https://www.barnesandnoble.com/"
                              target="_blank"
                            >
                              Barnes &amp; Noble
                            </a>
                            <a
                              href="https://www.nookaudiobooks.com/"
                              target="_blank"
                            >
                              Nook
                            </a>
                            <a
                              href="http://www.3leafgroup.com/"
                              target="_blank"
                            >
                              3 Leaf Group
                            </a>
                            <a
                              href="https://www.baker-taylor.com/"
                              target="_blank"
                            >
                              Baker &amp; Taylor, Inc.
                            </a>
                            <a
                              href="https://www.bibliotheca.com/"
                              target="_blank"
                            >
                              Bibliotheca, LLC
                            </a>
                            <a
                              href="https://www.follettlearning.com/"
                              target="_blank"
                            >
                              Follett Library Resources
                            </a>
                            <a href="https://books.google.com/" target="_blank">
                              Google LLC
                            </a>
                            <a
                              href="https://www.mackin.com/hq/"
                              target="_blank"
                            >
                              Mackin
                            </a>
                            <a
                              href="https://www.anyplay.audio/"
                              target="_blank"
                            >
                              Anyplay
                            </a>
                            <a href="https://www.odilo.us/" target="_blank">
                              Odilo
                            </a>
                            <a
                              href="https://www.wheelers.co.nz/"
                              target="_blank"
                            >
                              Wheelers Book Club Limited
                            </a>
                            <a href="https://www.beek.io/" target="_blank">
                              Beek
                            </a>
                            <a
                              href="https://www.apple.com/ca/apple-books/"
                              target="_blank"
                            >
                              Apple
                            </a>
                            <a
                              href="https://www.24symbols.com/"
                              target="_blank"
                            >
                              24Symbols
                            </a>
                            <a
                              href="https://www.chirpbooks.com/"
                              target="_blank"
                            >
                              Chirp
                            </a>
                            <a href="https://audioteka.com/en/" target="_blank">
                              Audioteka
                            </a>
                            <a href="https://www.ubook.com/" target="_blank">
                              UBook
                            </a>
                            <a href="https://en.youscribe.com/" target="_blank">
                              YouScribe
                            </a>
                            <a href="https://www.bookbeat.com/" target="_blank">
                              BookBeat
                            </a>
                            <a href="https://cliqdigital.com/" target="_blank">
                              Cliq Digital
                            </a>
                            <a href="https://instaread.co/" target="_blank">
                              Instaread
                            </a>
                            <a href="https://www.onleihe.net/" target="_blank">
                              Divibib
                            </a>
                            <a href="https://listeneru.com/" target="_blank">
                              ListenerU
                            </a>
                            <a href="http://rahvaraamat.ee/en" target="_blank">
                              Rahva Raamat
                            </a>
                            <a href="" target="_blank">
                              Books-A-Million
                            </a>
                            <a href="https://fonos.vn/" target="_blank">
                              Fonos
                            </a>
                            <a href="https://www.pocketfm.com" target="_blank">
                              PocketFM
                            </a>
                            <a href="" target="_blank">
                              MLOL
                            </a>
                            <a
                              href="https://myaudiobooklibrary.com/"
                              target="_blank"
                            >
                              My Audiobook Library
                            </a>
                            <a href="" target="_blank">
                              Radish
                            </a>
                            <a href="" target="_blank">
                              Globook
                            </a>
                            <a href="" target="_blank">
                              Kids.Radio
                            </a>
                            <a href="" target="_blank">
                              Spotify
                            </a>
                            <a href="speechifybooks.com" target="_blank">
                              Speechify
                            </a>
                            <a href="" target="_blank">
                              Ulverscroft
                            </a>
                            <a href="" target="_blank">
                              Behear
                            </a>
                            <a href="" target="_blank">
                              Bookwalker
                            </a>
                            <a href="" target="_blank">
                              StoryFair
                            </a>
                            <a href="" target="_blank">
                              Sounded
                            </a>
                            <a href="https://www.fountain.fm/" target="_blank">
                              Fountain.FM
                            </a>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className="agree-checks">
                <Row>
                  <Col md={4}>
                    <Form.Check
                      type="checkbox"
                      label="Music Channels"
                      checked
                    />
                  </Col>
                  <Col md={8}>
                    <Row>
                      <Col md={6}>
                        <p>
                          List your titles with these channels to make them
                          available on music streaming channels.
                        </p>
                      </Col>
                      <Col md={6}>
                        <p>
                          You receive 70% of the net income from the sale of
                          your audiobook through these channels.
                        </p>
                      </Col>
                      <Col md={12}>
                        <div className="agreement-links">
                          <div class="distributorsList">
                            <a
                              href="https://www.deezer.com/en/"
                              target="_blank"
                            >
                              Deezer
                            </a>
                            <a href="https://us.napster.com/" target="_blank">
                              Napster
                            </a>
                            <a
                              href="https://www.apple.com/ca/music/"
                              target="_blank"
                            >
                              Apple Music
                            </a>
                            <a
                              href="https://music.youtube.com/"
                              target="_blank"
                            >
                              YouTube Music
                            </a>
                            <a href="https://calmradio.com/en" target="_blank">
                              Calm Radio
                            </a>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className="heading-with-note mt-4">
                <h4>Publication Agreement</h4>
              </div>
              <div className="full-agreement">
                <div className="agreement-details">
                  <AgreementDetails />
                </div>
                <div className="agreement-check">
                  <Form.Check
                    type="checkbox"
                    label="By checking this box, I acknowledge that I have read, understand and agree to the BlaklistEd Publishing Agreement."
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="setps-options">
            <Row>
              <Col>
                <div className="lft-side">
                  <Link to="" className="btn-style black-btn">
                    Cancel
                  </Link>
                </div>
              </Col>
              <Col>
                <div className="ryt-side">
                  <Link
                    to="/theblaklisted/publish-agreement"
                    className="btn-style yellow-btn"
                  >
                    Submit for Review
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}
