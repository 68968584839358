import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Form, Button, Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// DATA
import selectLanguages from "../../data/langOptions.json";
import selectGenre from "../../data/genreOptions.json";

// MEDIA
import menuscriptPDF from "assets/PrepareYourManuscriptforRecording.pdf";
import CountrySelect from "theblaklisted/components/CountrySelect";


export default function AudioBookDetails() {

    // SCROLL TO TOP
    const [expanded, setExpanded] = useState(false);
    const setExpand = () => {
        setExpanded(false);
        window.scrollTo(0, 0);
    };

    // MENUSCRIPT TIP
    const renderMenuTooltip = (props) => (
        <Tooltip id="MenuTooltip" {...props}>
            EPUB and PDF files accepted. This is the full script we will use to produce your audio files.
        </Tooltip>
    );

    // BOOKSOVER TIP
    const bookCoverTooltip = (props) => (
        <Tooltip id="bookCoverTooltip" {...props}>
            JPG and PNG files accepted. We will convert the dimensions of your existing book cover into the dimensions of an audio book cover.
        </Tooltip>
    );

    // LANGUAGE TIP
    const renderLanguageTooltip = (props) => (
        <Tooltip id="LanguageTooltip" {...props}>
            Let us know which language your audiobook is narrated in.
        </Tooltip>
    );

    // ABRIDGED?
    const renderadridgedTooltip = (props) => (
        <Tooltip id="AdridgedTooltip" {...props}>
            Choose yes if this book is a shortened version of the original (not the full book).
        </Tooltip>
    );

    // EXPLICIT
    const renderexplicitTooltip = (props) => (
        <Tooltip id="ExplicitTooltip" {...props}>
            Choose yes if this book contains content not suitable for individuals under 18 years of age.
        </Tooltip>
    );

    // CHILDBOOK
    const renderchildbookTooltip = (props) => (
        <Tooltip id="ChildbookTooltip" {...props}>
            Choose yes if the content of this book is specifically written for a child audience between ages 0-18.
        </Tooltip>
    );

    // DESCBOOK
    const renderdescTooltip = (props) => (
        <Tooltip id="DescTooltip" {...props}>
            Please describe your audiobook in 2,000 characters or less. This description will appear on the description page of your audiobook in the BlaklistEd app.
        </Tooltip>
    );

    // OWNER NAME
    const renderonameTooltip = (props) => (
        <Tooltip id="OnameTooltip" {...props}>
            Provide the name of the rights holder who owns the print version of this book. Chances are, this is you!
        </Tooltip>
    );

    // COPYRIGHTS
    const rendercopyrTooltip = (props) => (
        <Tooltip id="CopyrTooltip" {...props}>
            Use commas if there are multiple years. Ex. 2021, 2022.
        </Tooltip>
    );

    // AUDIOBOOK OWNER NAME
    const renderaudiobnameTooltip = (props) => (
        <Tooltip id="AudiobnameTooltip" {...props}>
            Provide the name of the rights holder who owns the audio version of this book. Chances are, this is you!
        </Tooltip>
    );

    // AUDIOBOOK COPYRIGHTS
    const renderaudiobcopyTooltip = (props) => (
        <Tooltip id="AudiobcopyTooltip" {...props}>
            Use commas if there are multiple years. Ex. 2021, 2022.
        </Tooltip>
    );

    // MSRP
    const renderMSRPTooltip = (props) => (
        <Tooltip id="MSRPTooltip" {...props}>
            MSRP (manufacture's suggested retail price) is required. This is the price to purchase your audiobook. This is the price we will show if a customer wants to purchase your audiobook withour being a subscriber to BlaklistEd. NOTE: A BlaklistEd subscription is $14.99. You must make your audiobook price greater than our subscription price.
        </Tooltip>
    );

    // DR ISBN
    const renderDRISBNTooltip = (props) => (
        <Tooltip id="DRISBNTooltip" {...props}>
            Each format of your book requires a different International Standard Book Number (ISBN). If you do not have one for your audiobook, leave this field blank. We will happily provide one to you for FREE.
        </Tooltip>
    );
    // DL ISBN
    const renderDLISBNTooltip = (props) => (
        <Tooltip id="DLISBNTooltip" {...props}>
            Library ISBN Each format of your book require a different International Standard Book Number (ISBN). If you do not have one for your audiobook, leave this field blank. We will happily provide one to you for FREE.
        </Tooltip>
    );
    // ASIN
    const renderASINTooltip = (props) => (
        <Tooltip id="ASINTooltip" {...props}>
            Amazon Sales Inventory Number: If you do not know your ASIN or if you do not have one, simply leave this field blank.
        </Tooltip>
    );

    // WORDCOUNT
    const wordCountTooltip = (props) => (
        <Tooltip id="wordCountTooltip" {...props}>
            Estimate the total word count of all sections of your manuscript that you wish to be recorded.
        </Tooltip>
    );

    // PRO GUIDE
    const proguideTooltip = (props) => (
        <Tooltip id="proguideTooltip" {...props}>
            Provide the phonetic pronunciation of characters' names and/or any words you believe we should know the precise pronunciation.
        </Tooltip>
    );

    // CHILDBOOK OPTIONS    
    const [hasChildBook, setHasChildBook] = useState(false);

    const handleChildBookChange = (e) => {
        setHasChildBook(e.target.value === 'Yes');
    };

    // DISTRIBUTE COUNTRY OPTIONS    
    const [hasDisBook, setHasDisBook] = useState(false);

    const handleDisBookChange = (e) => {
        setHasDisBook(e.target.value === 'SelectedCountries');
    };

    // MODAL FORM FIELDS
    const [narratorShow, setNarratorShow] = useState(false);
    const [narratorsList, setNarratorsList] = useState([]);
    const [narratorData, setNarratorData] = useState({

        word: '',
        pronunciation: '',

    });

    const narratorShowHandler = () => setNarratorShow(true);
    const narratorCloseHandler = () => {
        setNarratorShow(false);
        setNarratorData({
            word: '',
            pronunciation: '',
        });
    };

    const addNarratorHandler = () => {
        const newNarrator = { ...narratorData, id: Date.now() };
        setNarratorsList([...narratorsList, newNarrator]);
        narratorCloseHandler();
    };

    const removeNarratorHandler = (id) => {
        setNarratorsList(narratorsList.filter((narrator) => narrator.id !== id));
    };


    // COUNT CHARACTERS
    const [charCount, setCharCount] = useState(0);
    const maxChars = 2000;
    const maxCharsAfterLimit = 2000;
    const [disabled, setDisabled] = useState(false);

    const handleInputChange = (e) => {
        let inputValue = e.target.value;
        let currentCharCount = inputValue.length;

        if (currentCharCount <= maxChars) {
            if (currentCharCount > maxCharsAfterLimit) {
                inputValue = inputValue.slice(0, maxCharsAfterLimit); // Truncate input to 200 characters
                currentCharCount = maxCharsAfterLimit;
                setDisabled(true); // Disable textarea once the limit is reached
            } else {
                setDisabled(false); // Enable textarea if the character count is within limit
            }
            setCharCount(currentCharCount);
        }
    };





    return (
        <>
            <div className='dbody-area'>
                <div className='dpage-heading'>
                    <h1>Audiobook Details</h1>
                </div>
                <div className='db-content'>
                    <div className="top-checks">
                        <ul>
                            <li><Link className="step-done" to='/theblaklisted/newaudiobook'><FontAwesomeIcon icon={solid("square-check")} /> <span>Get Started</span></Link></li>
                            <li><Link to='/theblaklisted/audiobookdetails'><FontAwesomeIcon icon={regular("square")} /> <span>Audiobook Details</span></Link></li>
                            <li><Link to='/theblaklisted/newaudiobook' disabled><FontAwesomeIcon icon={regular("square")} /> <span>Narrator/Character Details</span></Link></li>
                            <li><Link to='/theblaklisted/newaudiobook' disabled><FontAwesomeIcon icon={regular("square")} /> <span>Agreement</span></Link></li>
                        </ul>
                    </div>

                    <div className="forms-area">
                        <div className="start-form">
                            <Row>
                                <Col md={12}>
                                    <div className="mini-note">
                                        <p>Before attaching the manuscript, please read and complete the tasks in this article, by <a href={menuscriptPDF} target="_blank">clicking here</a>.</p>
                                    </div>
                                </Col>
                            </Row>

                            {/* MENUSCRIPT */}
                            <Row>
                                <Col md={12}>
                                    <div class="heading-with-note">
                                        <h4>Manuscript</h4>
                                        <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={renderMenuTooltip}
                                        >
                                            <Button variant="link">
                                                <FontAwesomeIcon icon={solid("circle-info")} />
                                            </Button>
                                        </OverlayTrigger>
                                    </div>
                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Form.Label>Upload e-Book/Manuscript</Form.Label>
                                        <Form.Control type="file" accept=".pdf,epub" />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    <Form.Label>Pronunciation Guide <span class="ob-txt">(optional)</span>
                                        <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={proguideTooltip}
                                        >
                                            <Button variant="link">
                                                <FontAwesomeIcon icon={solid("circle-info")} />
                                            </Button>
                                        </OverlayTrigger>
                                    </Form.Label><br />
                                    <Button
                                        variant="primary mt-1 mb-2"
                                        onClick={narratorShowHandler}
                                        className="btn-style yellow-btn"
                                    >
                                        Add New
                                    </Button>
                                    <Modal show={narratorShow} onHide={narratorCloseHandler}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Pronunciation</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="modal-form">
                                                <Row>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-3" controlId="word-prefix">
                                                            <Form.Label>Word</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder=""
                                                                value={narratorData.firstName}
                                                                onChange={(e) =>
                                                                    setNarratorData({ ...narratorData, word: e.target.value })
                                                                }
                                                                required
                                                            />
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md={6}>
                                                        <Form.Group className="mb-3" controlId="author-first-name">
                                                            <Form.Label>Pronunciation</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder=""
                                                                value={narratorData.pronunciation}
                                                                onChange={(e) =>
                                                                    setNarratorData({ ...narratorData, pronunciation: e.target.value })
                                                                }
                                                                required
                                                            />
                                                        </Form.Group>
                                                    </Col>

                                                </Row>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button
                                                className="btn-style yellow-btn"
                                                variant="secondary"
                                                onClick={addNarratorHandler}
                                            >
                                                Add Word
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </Col>
                                <Col md={9}>
                                    <div className="authorslist mt-3 mb-4">
                                        {narratorsList.map((narrator) => (
                                            <div key={narrator.id}>
                                                <button onClick={() => removeNarratorHandler(narrator.id)}>X</button>
                                                <span>{narrator.word}</span> / <span>{narrator.pronunciation}</span>
                                                <input type="hidden" name={`word-${narrator.id}`} value={narrator.word} />
                                                <input type="hidden" name={`pronunciation-${narrator.id}`} value={narrator.pronunciation} />
                                            </div>
                                        ))}
                                    </div>
                                </Col>


                            </Row>

                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="MWC">
                                        <Form.Label>Manuscript Word Count <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={wordCountTooltip}
                                        >
                                            <Button variant="link">
                                                <FontAwesomeIcon icon={solid("circle-info")} />
                                            </Button>
                                        </OverlayTrigger></Form.Label>
                                        <Form.Control type="text" placeholder="" />
                                    </Form.Group>
                                </Col>

                            </Row>

                            {/* BOOK COVER */}
                            <Row>
                                <Col md={12}>
                                    <div class="heading-with-note">
                                        <h4>Book Cover</h4>
                                        <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={bookCoverTooltip}
                                        >
                                            <Button variant="link">
                                                <FontAwesomeIcon icon={solid("circle-info")} />
                                            </Button>
                                        </OverlayTrigger>
                                    </div>
                                    <Form.Group controlId="bookCover" className="mb-3">
                                        <Form.Label>Upload book cover</Form.Label>
                                        <Form.Control type="file" accept=".pdf,epub" name="bookCover" />
                                    </Form.Group>
                                </Col>
                            </Row>

                            {/* ABOUT YOUR AUDIOBOOK */}
                            <Row className="mt-3">
                                <Col md={12}>
                                    <div class="heading-with-note">
                                        <h4>About Your Audiobook</h4>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="book-lang">
                                        <Form.Label>
                                            Language
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderLanguageTooltip}
                                            >
                                                <Button variant="link">
                                                    <FontAwesomeIcon icon={solid("circle-info")} />
                                                </Button>
                                            </OverlayTrigger>

                                        </Form.Label>
                                        <Form.Select aria-label="book language">
                                            {selectLanguages.languages.map((language, index) => (
                                                <option key={index} value={language.value}>
                                                    {language.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="abridged">
                                        <Form.Label>Is the book abridged?
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderadridgedTooltip}
                                            >
                                                <Button variant="link">
                                                    <FontAwesomeIcon icon={solid("circle-info")} />
                                                </Button>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        {['radio'].map((type) => (
                                            <div key={`inline-${type}`} className="mb-3">
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="brandname"
                                                    type={type}
                                                    id={`abridged-${type}-1`}
                                                    value="Yes"
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="brandname"
                                                    type={type}
                                                    id={`abridged-${type}-2`}
                                                    value="No" checked
                                                />
                                            </div>
                                        ))}
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exp-content">
                                        <Form.Label>Does it include explicit content?
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderexplicitTooltip}
                                            >
                                                <Button variant="link">
                                                    <FontAwesomeIcon icon={solid("circle-info")} />
                                                </Button>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        {['radio'].map((type) => (
                                            <div key={`inline-${type}`} className="mb-3">
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="exp-content"
                                                    type={type}
                                                    id={`excontent-${type}-1`}
                                                    value="Yes"
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="exp-content"
                                                    type={type}
                                                    id={`excontent-${type}-2`}
                                                    value="No" checked
                                                />
                                            </div>
                                        ))}
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="childbook">
                                        <Form.Label>Is this a children’s book (ages 0-18)?

                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderchildbookTooltip}
                                            >
                                                <Button variant="link">
                                                    <FontAwesomeIcon icon={solid("circle-info")} />
                                                </Button>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        {['radio'].map((type) => (
                                            <div key={`inline-${type}`} className="mb-3">
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="child-book"
                                                    type={type}
                                                    id={`childbook-${type}-1`}
                                                    value="Yes"
                                                    onChange={handleChildBookChange}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="child-book"
                                                    type={type}
                                                    id={`childbook-${type}-2`}
                                                    value="No"
                                                    onChange={handleChildBookChange}
                                                    checked={!hasChildBook}
                                                />
                                            </div>
                                        ))}
                                    </Form.Group>
                                    {hasChildBook && (
                                        <Form.Group className="mb-3" controlId="child-age">
                                            <Form.Label>Children's Age Group</Form.Label>
                                            <Form.Select aria-label="Default select example">
                                                <option value="0" selected="selected">--Select--</option>
                                                <option value="1">None</option>
                                                <option value="2">Picture Books (3 to 6 Years of Age)</option>
                                                <option value="3">Early Reader (6 to 10 Years of Age)</option>
                                                <option value="4">Middle Grade (8 to 12 Years of Age)</option>
                                                <option value="5">Young Adult (12 and older)</option>
                                            </Form.Select>
                                        </Form.Group>
                                    )}

                                    <Form.Group className="mb-3" controlId="genre-req">
                                        <Form.Label>
                                            Genre
                                        </Form.Label>
                                        <Form.Select aria-label="book genre">
                                            {selectGenre.map((option, index) => (
                                                <option key={index} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="add-genre">
                                        <Form.Label>
                                            Additional Genres (Optional)
                                        </Form.Label>
                                        <Form.Select aria-label="additional genre" className="mb-1">
                                            {selectGenre.map((option, index) => (
                                                <option key={index} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Select aria-label="additional genre">
                                            {selectGenre.map((option, index) => (
                                                <option key={index} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="align-items-center">
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="bookdesc">
                                        <Form.Label>Description
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderdescTooltip}
                                            >
                                                <Button variant="link">
                                                    <FontAwesomeIcon icon={solid("circle-info")} />
                                                </Button>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={4}
                                            onChange={handleInputChange}
                                            disabled={disabled} // Disable textarea when the limit is reached
                                        />
                                        <span>{charCount} characters of {maxChars} Max</span>
                                    </Form.Group>

                                </Col>
                                <Col md={6}>
                                    <div className="badge-info">
                                        <strong>This description will appear on the description page of your audiobook in the BlaklistEd app.</strong>
                                    </div>
                                </Col>
                            </Row>

                            {/* COPYRIGHTS */}
                            <Row className="mt-3">
                                <Col md={6}>
                                    <div class="heading-with-note">
                                        <h4>Copyright Information</h4>
                                    </div>

                                    <Form.Group className="mb-3" controlId="ci-printowner">
                                        <Form.Label>Print Owner Name (Optional)
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderonameTooltip}
                                            >
                                                <Button variant="link">
                                                    <FontAwesomeIcon icon={solid("circle-info")} />
                                                </Button>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        <Form.Control type="text" />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="ci-printyear">
                                        <Form.Label>Print Copyright Year (Optional)
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={rendercopyrTooltip}
                                            >
                                                <Button variant="link">
                                                    <FontAwesomeIcon icon={solid("circle-info")} />
                                                </Button>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        <Form.Control type="text" />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="ci-abowner">
                                        <Form.Label>Audiobook Owner Name
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderaudiobnameTooltip}
                                            >
                                                <Button variant="link">
                                                    <FontAwesomeIcon icon={solid("circle-info")} />
                                                </Button>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        <Form.Control type="text" />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="ci-abcopyr">
                                        <Form.Label>Audiobook Copyright Year
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderaudiobcopyTooltip}
                                            >
                                                <Button variant="link">
                                                    <FontAwesomeIcon icon={solid("circle-info")} />
                                                </Button>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        <Form.Control type="text" />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col md={6}>
                                    <div class="heading-with-note">
                                        <h4>Distribution Information</h4>
                                    </div>

                                    <Form.Group className="mb-3" controlId="">
                                        <Form.Label>Where to distribute your audiobook?
                                        </Form.Label>
                                        {['radio'].map((type) => (
                                            <div key={`inline-${type}`} className="mb-3">
                                                <Form.Check
                                                    inline
                                                    label="Worldwide"
                                                    name="dis-audio-book"
                                                    type={type}
                                                    id={`distribute-${type}-1`}
                                                    value="Worldwide"
                                                    onChange={handleDisBookChange}
                                                    checked={!hasDisBook}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="Selected Countries"
                                                    name="dis-audio-book"
                                                    type={type}
                                                    id={`distribute-${type}-2`}
                                                    value="SelectedCountries"
                                                    onChange={handleDisBookChange}

                                                />
                                            </div>
                                        ))}
                                    </Form.Group>
                                    {hasDisBook && (
                                        <CountrySelect />                                        
                                    )}

                                    <Form.Group className="mb-3" controlId="MSRP">
                                        <Form.Label>MSRP
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderMSRPTooltip}
                                            >
                                                <Button variant="link">
                                                    <FontAwesomeIcon icon={solid("circle-info")} />
                                                </Button>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        <Form.Control type="number" />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="align-items-center mt-3">
                                <Col md={6}>
                                    <div class="heading-with-note op-txt">
                                        <h4>Identifications Numbers</h4>
                                        <span className="optional-txt">(Optional)</span>
                                    </div>
                                    <Form.Group className="mb-3" controlId="dr-ISBN">
                                        <Form.Label>Digital Retail ISBN
                                            {/* <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderDRISBNTooltip}
                                            >
                                                <Button variant="link">
                                                    <FontAwesomeIcon icon={solid("circle-info")} />
                                                </Button>
                                            </OverlayTrigger> */}
                                        </Form.Label>
                                        <Form.Control type="text" placeholder="" required />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="dl-ISBN">
                                        <Form.Label>Digital Library ISBN
                                            {/* <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderDLISBNTooltip}
                                            >
                                                <Button variant="link">
                                                    <FontAwesomeIcon icon={solid("circle-info")} />
                                                </Button>
                                            </OverlayTrigger> */}
                                        </Form.Label>
                                        <Form.Control type="text" placeholder="" required />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="ASIN">
                                        <Form.Label>ASIN
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderASINTooltip}
                                            >
                                                <Button variant="link">
                                                    <FontAwesomeIcon icon={solid("circle-info")} />
                                                </Button>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        <Form.Control type="text" placeholder="" required />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <div className="badge-info">
                                        If you have identification numbers already, enter them here. Otherwise, <strong>leave blank</strong>.
                                    </div>
                                </Col>
                            </Row>

                        </div>

                        <div className="setps-options">
                            <Row>
                                <Col>
                                    <div className="lft-side">
                                        <Link to="" className="btn-style black-btn">Cancel</Link>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="ryt-side">
                                        <Link to="/theblaklisted/narratordetails" className="btn-style yellow-btn">Narrator Details</Link>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
