import React, { useState, useEffect } from "react";
import { Container, Row, Col, Tabs, Tab, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// MEDIA
import logo from 'assets/logobk.png'

export default function Header() {
  return (
    <>
      <div className="dheader">

        <Container fluid>
          <Row className="align-items-center">
            <Col md={3} lg={2}>
              <Link to="dashboard">
                <img src={logo} alt="logo" className="dlogo" />
              </Link>
            </Col>
            <Col md={9} lg={10}>
              <Row>
                <Col md={6} className="d-flex align-items-center">
                  <div className="lft-side">
                    <Link to="/" className="btn-style yellow-btn">
                      <FontAwesomeIcon icon={solid("plus")} /> New Audio Book
                    </Link>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="ryt-side">
                    <Dropdown>
                      <Dropdown.Toggle variant="link" id="noti-dropdown" className="noti-drop">
                        <span><FontAwesomeIcon icon={solid("bell")} /></span>
                        <strong>01</strong>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>                        
                      </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown>
                      <Dropdown.Toggle variant="link" id="author-dropdown" className="acc-drop">
                        <span><FontAwesomeIcon icon={solid("pencil")} /></span> Author
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>                        
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

      </div>
    </>
  )
}
