import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Form, Button, Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// MEDIA
import produceicon from "assets/005-book.png";
import publishicon from "assets/007-book-1.png";

export default function NewAudiobook() {
    // SCROLL TO TOP
    const [expanded, setExpanded] = useState(false);
    const setExpand = () => {
        setExpanded(false);
        window.scrollTo(0, 0);
    };

    // MODAL FORM FIELDS
    const [authorShow, setAuthorShow] = useState(false);
    const [authorsList, setAuthorsList] = useState([]);
    const [authorData, setAuthorData] = useState({
        prefix: '',
        suffix: '',
        firstName: '',
        middleName: '',
        lastName: '',
    });

    const authorShowHandler = () => setAuthorShow(true);
    const authorCloseHandler = () => {
        setAuthorShow(false);
        // Clear author form data on modal close
        setAuthorData({
            prefix: '',
            suffix: '',
            firstName: '',
            middleName: '',
            lastName: '',
        });
    };

    const addAuthorHandler = () => {
        const newAuthor = { ...authorData, id: Date.now() };
        setAuthorsList([...authorsList, newAuthor]);
        authorCloseHandler();
    };

    const removeAuthorHandler = (id) => {
        setAuthorsList(authorsList.filter((author) => author.id !== id));
    };

    // PUBLISHER FIELD
    const [hasBrandName, setHasBrandName] = useState(false);

    const handleBrandNameChange = (e) => {
        setHasBrandName(e.target.value === 'Yes');
    };

    // BRANDNAME
    const brandTooltip = (props) => (
        <Tooltip id="brandTooltip" {...props}>
            If you do not supply a publisher name, this will be set to &quot;Independently Published&quot;
        </Tooltip>
    );

    return (
        <>
            <div className='dbody-area'>
                <div className='dpage-heading'>
                    <h1>Get Started</h1>
                </div>
                <div className='db-content'>
                    <div className="top-checks">
                        <ul>
                            
                        <li><Link to='/theblaklisted/newaudiobook'><FontAwesomeIcon icon={regular("square")} /> <span>Get Started</span></Link></li>
                        <li><Link to='/theblaklisted/newaudiobook' disabled><FontAwesomeIcon icon={regular("square")} /> <span>Audiobook Details</span></Link></li>
                        <li><Link to='/theblaklisted/newaudiobook' disabled><FontAwesomeIcon icon={regular("square")} /> <span>Narrator/Character Details</span></Link></li>
                        <li><Link to='/theblaklisted/newaudiobook' disabled><FontAwesomeIcon icon={regular("square")} /> <span>Agreement</span></Link></li>

                    </ul>
                </div>

                <div className="forms-area">
                    <div className="start-form">
                        <Row>
                            <Col md={6}><Form.Group className="mb-3" controlId="title">
                                <Form.Label>Title</Form.Label>
                                <Form.Control type="text" placeholder="" required />
                            </Form.Group></Col>
                            <Col md={6}><Form.Group className="mb-3" controlId="subtitle">
                                <Form.Label>Subtitle (Optional)</Form.Label>
                                <Form.Control type="text" placeholder="" />
                            </Form.Group></Col>
                            <Col md={3}>
                                <Button
                                    variant="primary mt-3 mb-4"
                                    onClick={authorShowHandler}
                                    className="btn-style yellow-btn"
                                >
                                    Add Author
                                </Button>
                                <Modal show={authorShow} onHide={authorCloseHandler}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Add Author</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="modal-form">
                                            <Row>
                                                <Col md={6}>
                                                    <Form.Group className="mb-3" controlId="author-prefix">
                                                        <Form.Label>Prefix</Form.Label>
                                                        <Form.Select
                                                            aria-label="Select Prefix"
                                                            value={authorData.prefix}
                                                            onChange={(e) =>
                                                                setAuthorData({ ...authorData, prefix: e.target.value })
                                                            }
                                                        >
                                                            <option value=""></option>
                                                            <option value="B.C.E">B.C.E.</option>
                                                            <option value="Dr.">Dr.</option>
                                                            <option value="Esq.">Esq.</option>
                                                            <option value="Jr.">Jr.</option>
                                                            <option value="M.D.">M.D.</option>
                                                            <option value="Ph.D.">Ph.D.</option>
                                                            <option value="Prof.">Prof.</option>
                                                            <option value="Rev.">Rev.</option>
                                                            <option value="Rt. Hon.">Rt. Hon.</option>
                                                            <option value="Sr.">Sr.</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group className="mb-3" controlId="author-sufix">
                                                        <Form.Label>Suffix</Form.Label>
                                                        <Form.Select
                                                            aria-label="Select Suffix"
                                                            value={authorData.suffix}
                                                            onChange={(e) =>
                                                                setAuthorData({ ...authorData, suffix: e.target.value })
                                                            }
                                                        >
                                                            <option value=""></option>
                                                            <option value="B.C.E">B.C.E.</option>
                                                            <option value="Dr.">Dr.</option>
                                                            <option value="Esq.">Esq.</option>
                                                            <option value="Jr.">Jr.</option>
                                                            <option value="M.D.">M.D.</option>
                                                            <option value="Ph.D.">Ph.D.</option>
                                                            <option value="Prof.">Prof.</option>
                                                            <option value="Rev.">Rev.</option>
                                                            <option value="Rt. Hon.">Rt. Hon.</option>
                                                            <option value="Sr.">Sr.</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Group className="mb-3" controlId="author-first-name">
                                                        <Form.Label>First Name</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            value={authorData.firstName}
                                                            onChange={(e) =>
                                                                setAuthorData({ ...authorData, firstName: e.target.value })
                                                            }
                                                            required
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Group className="mb-3" controlId="author-middle-name">
                                                        <Form.Label>Middle Name(s) / Initial(s)</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            value={authorData.middleName}
                                                            onChange={(e) =>
                                                                setAuthorData({ ...authorData, middleName: e.target.value })
                                                            }
                                                            required
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Group className="mb-3" controlId="author-last-name">
                                                        <Form.Label>Last Name</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder=""
                                                            value={authorData.lastName}
                                                            onChange={(e) =>
                                                                setAuthorData({ ...authorData, lastName: e.target.value })
                                                            }
                                                            required
                                                        />

                                                    </Form.Group>
                                                </Col>

                                            </Row>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button
                                            className="btn-style yellow-btn"
                                            variant="secondary"
                                            onClick={addAuthorHandler}
                                        >
                                            Add Author
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </Col>
                            <Col md={9}>
                                <div className="authorslist mt-3 mb-4">
                                    {authorsList.map((author) => (
                                        <div key={author.id}>
                                            <button onClick={() => removeAuthorHandler(author.id)}>X</button>
                                            <span>{author.firstName}</span>
                                            <input type="hidden" name={`author-prefix-${author.id}`} value={author.prefix} />
                                            <input type="hidden" name={`author-suffix-${author.id}`} value={author.suffix} />
                                            <input type="hidden" name={`author-first-name-${author.id}`} value={author.firstName} />
                                            <input type="hidden" name={`author-middle-name-${author.id}`} value={author.middleName} />
                                            <input type="hidden" name={`author-last-name-${author.id}`} value={author.lastName} />
                                        </div>
                                    ))}
                                </div>
                            </Col>
                            <Col md={12}>
                                <Form.Group className="mb-3" controlId="subtitle">
                                    <Form.Label>Do you have a publishing or brand name?
                                        <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={brandTooltip}
                                        >
                                            <Button variant="link">
                                                <FontAwesomeIcon icon={solid("circle-info")} />
                                            </Button>
                                        </OverlayTrigger>
                                    </Form.Label>
                                    {['radio'].map((type) => (
                                        <div key={`inline-${type}`} className="mb-3">
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="brandname"
                                                type={type}
                                                id={`inline-${type}-1`}
                                                value="Yes"
                                                onChange={handleBrandNameChange}
                                            />
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="brandname"
                                                type={type}
                                                id={`inline-${type}-2`}
                                                value="No"
                                                onChange={handleBrandNameChange}
                                                checked={!hasBrandName}
                                            />
                                        </div>
                                    ))}
                                </Form.Group>

                                {hasBrandName && (
                                    <Form.Group className="mb-3" controlId="publisher-name">
                                        <Form.Label>Publisher</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Publisher name"
                                            required
                                        />
                                    </Form.Group>
                                )}

                            </Col>

                        </Row>


                    </div>

                    <div className="setps-options">
                        <Row>
                            <Col>
                                <div className="lft-side">
                                    <Link to="" className="btn-style black-btn">Cancel</Link>
                                </div>
                            </Col>
                            <Col>
                                <div className="ryt-side">
                                    <Link to="/theblaklisted/audiobookdetails" className="btn-style yellow-btn">Audio Book Details</Link>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div >
        </>
    )
}
