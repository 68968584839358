import React from 'react'
import { Table } from "react-bootstrap";

export default function AgreementDetails() {
    return (
        <>
            <h4>AUDIOBOOK CREATION SERVICES AGREEMENT</h4>
            <p>THIS AGREEMENT (the "Agreement") made and entered into this ______ day of
                ____________, 20____ (the "Execution Date"), BETWEEN: [Author's Full Legal
                Name], (the "Author") AND BlaklistEd, LLC (the "Producer").</p>
            <h5>Author’s Contact Information</h5>
            <ul>
                <li>Name:</li>
                <li>Company Name (if applicable):</li>
                <li>Address:</li>
                <li>Phone:</li>
                <li>Email:</li>
            </ul>
            <h5>BlaklistEd, LLC Contact Information</h5>
            <ul>
                <li>Address:</li>
                <li>Contact:</li>
                <li>Title:</li>
                <li>Phone:</li>
                <li>Email:</li>
            </ul>
            <p>WHEREAS the Author is the legal owner of the copyright in and to certain literary work(s) and wishes to engage the services of the Producer to create an audiobook version of such work(s);</p>
            <p>NOW, THEREFORE, in consideration of the mutual covenants contained in this Agreement, and for other good and valuable consideration, the receipt and sufficiency of which is hereby acknowledged, the parties agree as follows:</p>
            <h5>1. SERVICES, COMPENSATION, RESPONSIBILITIES, AND TERRITORY</h5>
            <p>1.1 The Author hereby engages the Producer to create an audiobook version of the
                work(s), identified in Schedule A attached hereto and uploaded via the BlaklistEd
                Platform, (the "Work").</p>
            <p>1.2 As compensation for the Producer's services, the Author shall share with the
                Producer 50% of the net profits from sales of the audiobook. The Author shall receive
                their portion of the profits on a quarterly basis. The Producer shall provide a detailed
                account of the profits when requested in writing by the Author. The Producer may
                disqualify the Author from any profit payments if Author: (a) is in breach of this
                Agreement; (b) engages in fraudulent activity with respect to (i) this Agreement, (ii) its
                use of the BlaklistEd Platform, or (ii) any other offer made available by Producer; (c)
                manipulates purchase of the Audiobook through automated methods, an action which
                may also result in Author’s account termination on the BlaklistEd Platform; or (d)
                assigns their account on the BlaklistEd Platform to a third party without first obtaining
                written consent for such assignment from Producer, as is required under Section 8.6
                herein.</p>
            <p>1.3 Profit payments may be paid via PayPal to the PayPal account specified by Author on
                their client account on the BlaklistEd Platform, provided, however, that for payments
                made via PayPal, there shall be a two percent (2%) deduction from Author’s Profit on
                Net Income to account for the PayPal processing fee. Producer reserves the right, in its
                sole discretion, to select an alternative payment service and payment structure. It is the
                responsibility of Author to inform Producer of any changes in their payment
                information. Producer is authorized to hold, without penalty, payment of any owed
                profit until valid payment information is received. Payments will be made to Author in
                United States Dollars (USD). If a currency exchange is required on Net Income, the
                profit will be converted to USD in accordance with the applicable bank conversion rate.
                Producer will not be liable for any bank fees or losses resulting from conversions or
                changes in exchange rates.</p>
            <p>1.4 Author is responsible for any income or other taxes due and payable as a result of the
                Profit payments made by Producer to Author under this Agreement. Producer will remit
                the Author’s profit payments to the Author without deducting or withholding any
                amount on account of income taxes, withholding taxes or other amounts unless
                Producer is required to do so under applicable tax legislation. If BlaklistEd issues a
                refund to an end-user for an Audiobook, such refund will be either charged back to
                Author, or, if the Profit payment for such returned Audiobook has not yet been made,
                then such refund shall be deducted from the Profit payment.</p>
            <p>1.5 The territory is worldwide, except as otherwise provided in applicable Metadata with
                respect to individual Works supplied by Author.</p>
            <p>1.6 Producer shall host and maintain the online portal on their website for the purpose
                of receiving the digital print version of the manuscript for which audiobook creation
                services have been secured. The BlaklistEd Platform is operated in the sole discretion of
                Producer and may be modified, changed or discontinued in whole or in part at any time
                in the sole discretion of Producer without any liability to Author. Use of the BlaklistEd
                Platform requires compliance with its Terms of Service.</p>
            <p>1.7 Along with this signed agreement, Author shall deliver to Producer, through the
                BlaklistEd Platform and in the format specified by Producer, the digital print version of
                the manuscript, along with the associated Preview, Artwork, metadata and other
                information as may be required in the sole discretion of BlaklistEd. Further, Author
                shall also submit, simultaneous with initial delivery of the digital print version of the
                manuscript, secured permissions or clearances, if any are necessary for the work,
                together with any required third-party credits.</p>
            <p>1.8 If the digital print version of the manuscript or other information and materials
                provided by Author do not meet Producer’s specifications, Producer may inform Author
                of the errors, in which case Author must correct the errors and resubmit the corrected
                materials to Producer before the work can be considered for audiobook creation by
                Producer. The specifications Producer requires for the digital print version of the
                manuscript shall be in the sole discretion of Producer. Author acknowledges and agrees
                that Producer may, at its discretion, update its policies and guidelines, including with
                respect to delivery requirements or specifications required for a digital print verison of
                the manuscript, and Author agrees to exercise best efforts to comply with any updated
                policies and guidelines.</p>
            <p>1.9 Author acknowledges and agrees that any views and opinions expressed in the
                Author’s Work and therefore, in the audiobook derived from the Work, do not reflect the
                views or opinions of Producer, whether Producer accepts or rejects the digital print
                version of the manuscript.</p>
            <p>1.10 Author acknowledges and consents to Producer’s use of Artificial Intelligence to
                fulfill the audiobook creation service.</p>
            <h5>2. INTELLECTUAL PROPERTY</h5>
            <p>2.1 The Author retains all ownership and intellectual property rights in and to the Work,
                including the copyright in the original content.</p>
            <p>2.2 The Producer is granted a limited license to use the Work, and create derivatives
                thereof, solely for the purposes of creating the audiobook and promoting its sales on the
                BlaklistEd Platform.</p>
            <h5>3. TERM AND TERMINATION</h5>
            <p>3.1 This Agreement shall commence on the Execution Date and continue in full force
                and effect until terminated by either party with 30 days written notice.</p>
            <p>3.2 If Producer materially breaches this Agreement, Author may terminate this
                Agreement by providing written notice of termination to Producer including the nature
                of the material breach and the termination shall be effective thirty (30) days from
                receipt of notice unless Producer cures such breach within said thirty (30) day period.
                Author may terminate this Agreement without cause upon sixty (60) days’ written
                notice to Producer.</p>
            <p>3.3 Producer may, in its sole discretion, terminate this Agreement at any time for any
                reason upon written notice to Author. Upon termination, Producer shall withdraw the
                created audiobook and all associated material from the BlaklistEd Platform and
                Producer reserves the right to delete Author’s account from the BlaklistEd Platform,
                resulting in prevention of further access or use of the BlaklistEd Platform by Author as
                well as removal of any of Author’s materials submitted on the BlaklistEd Platform. In
                the event of such termination, Producer shall pay out a final Profit payment to Author.
                This final payment shall account for the profits earned from the date of the last payout
                to the date of termination. Any loss or necessary fees resulting from termination shall be
                deducted from Author’s final compensation.</p>
            <p>3.4 Any rights granted by Producer to Author in connection with this Agreement shall
                revert to Producer upon termination of this Agreement.</p>
            <h5>4. CONFIDENTIALITY</h5>
            <p>4.1 The Producer and Author agree to keep confidential all non-public information that
                it learns about the Work during the term of this Agreement.</p>
            <h5>5. GOVERNING LAW</h5>
            <p>5.1 This Agreement shall be governed by and construed in accordance with the laws of
                Georgia.</p>
            <h5>6. DISPUTE RESOLUTION</h5>
            <p>6.1 Any disputes that arise between the parties relating to this Agreement shall first be
                attempted to be resolved through good faith negotiation.</p>
            <p>6.2 If unable to resolve the dispute through negotiation, the dispute shall then be
                submitted to mediation in Georgia. If mediation is unsuccessful, the dispute shall then
                be submitted to final and binding arbitration in accordance with the rules of the
                American Arbitration Association.</p>
            <h5>7. INDEMNIFICATION</h5>
            <p>7.1 Each party shall indemnify and hold harmless the other against any claims, actions,
                damages, liabilities, costs, and expenses, including reasonable attorneys' fees and
                expenses, arising out of or related to any breach of any representation, warranty, or
                obligation set forth in this Agreement.</p>
            <h5>8. REPRESENTATIONS & WARRANTIES</h5>
            <p>8.1 Each party represents and warrants that it has the full right, power, and authority to
                enter into this Agreement and to perform its obligations hereunder.</p>
            <p>8.2 The Producer represents and warrants that the Services will be performed in a
                professional and workmanlike manner, in accordance with industry standards.</p>
            <p>8.3 The Author represents and warrants that the Work does not, to their knowledge,
                infringe upon any third party's intellectual property rights.</p>
            <p>8.4 With respect to the BlaklistEd Platform, Author will not (i) modify, adapt, translate,
                reverse engineer, decompile, disassemble or convert into any readable form any of the
                contents of the BlaklistEd Platform; (ii) attempt to interfere with, harm, reverse
                engineer, misappropriate, or gain unauthorized access to the BlaklistEd Platform, user
                accounts, or technology, software and equipment supporting the BlaklistEd Platform; or
                (iii) use the BlaklistEd platform in an illegal way or to commit an illegal act in relation
                to the BlaklistEd Platform, or that otherwise results in fines, penalties, and other
                liability to Producer, its licensors, partners, affiliates and others;</p>
            <p>8.5 All information submitted by Author via the BlaklistEd Platform, including without
                limitation all information provided on the Author’s account details, the Author’s
                biographical information, the image of Author, and payment information is accurate;</p>
            <p>8.6 Author is the sole owner and party responsible for and in control of the account they
                have registered on the BlaklistEd Platform through which the relevant services stated
                herein shall be performed and such account shall not be transferred without the prior
                written consent of Producer;</p>
            <p>8.7 Author’s use of the BlaklistEd Platform shall comply with Producer’s Terms of
                Service and other rules, regulations and requirements, and Author agrees to and accepts
                Producer’s Privacy Policy; and</p>
            <p>8.8 Author’s use of the BlaklistEd Platform and provision and use of the digital print
                version of the manuscript shall be in compliance with all applicable federal, state, and
                local laws and regulations.</p>
            <h5>9. RIGHT OF APPROVAL</h5>
            <p>9.1 If either party is prevented or interrupted in the performance of their obligations
                under this Agreement by reason of any event beyond such party’s reasonable control,
                including, but not limited to, governmental law, acts of God, strike, armed conflict, viral
                or bacterial pandemic, death, or any similar cause ("force majeure event"), then such
                obligations may be suspended for the duration of such force majeure event and the
                respective party(ies) shall not be held responsible for any delays or breaches in this
                Agreement caused by the force majeure event. Parties’ obligations under this Agreement
                shall be extended by a period equal to any period of force majeure (circumstance beyond
                the control of the parties) that prevents the parties from performing such obligations.</p>
            <p>9.2 The Producer shall submit to the Author a final version of the Audiobook for the
                Author's approval before it is released. The Author shall have the right to review and
                approve the final version of the Audiobook to ensure it meets the Author's standards
                and is consistent with the Author's vision for the Work.</p>
            <p>9.3 The Author's approval shall not be unreasonably withheld or delayed. If the Author
                does not approve the final version of the Audiobook, the Author shall provide the
                Producer with detailed feedback and suggested changes. The Producer agrees to make
                reasonable efforts to incorporate the Author's feedback and changes.</p>
            <p>9.4 If the Author fails to provide approval or feedback within 10 business days of
                receiving the final version of the Audiobook from the Producer, the Audiobook shall be
                deemed approved by the Author.</p>
            <p>9.5 Producer has the right to remove any Works from sale and distribution on the
                Producers Sites at its sole discretion. Producer shall control the pricing of the Work to
                its Customers and be responsible for all billing and collections of amounts due by
                Customers who have purchased Copies from Producer. Upon written notice to Author,
                Producer will immediately cease all marketing, display, sales and distribution of such
                Copies associated with the Works, including associated Metadata, Artwork, Excerpts,
                and Contributor Information, and all licenses and rights granted herein in connection
                with such Work shall immediately cease to exist; provided that Customers who
                purchased the Copy prior to such cancellation may continue to access and use such Copy
                through the Customer’s library and Producer may use and distribute, after such
                cancellation, any materials licensed to Producer prior to such cancellation solely to the
                extent necessary to provide such customers customer support and maintenance
                pursuant to Producer’s agreement with such Customers.</p>
            <h5>10. FORCE MAJEURE</h5>
            <p>10.1 If either party is prevented or interrupted in the performance of their obligations
                under this Agreement by reason of any event beyond such party’s reasonable control,
                including, but not limited to, governmental law, acts of God, strike, armed conflict, viral
                or bacterial pandemic, death, or any similar cause ("force majeure event"), then such
                obligations may be suspended for the duration of such force majeure event and the
                respective party(ies) shall not be held responsible for any delays or breaches in this
                Agreement caused by the force majeure event. Parties’ obligations under this Agreement
                shall be extended by a period equal to any period of force majeure (circumstance beyond
                the control of the parties) that prevents the parties from performing such obligations.</p>
            <h5>11. LIMITATIONS ON LIABILITY</h5>
            <p>11.1 EXCEPT AS EXPRESSLY PROVIDED IN THIS AGREEMENT, PRODUCER
                MAKES NO WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
                INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY,
                NON-INFRINGEMENT OR FITNESS FOR A PARTICULAR PURPOSE WITH
                RESPECT TO THE BLAKLISTED PLATFORM, THE AUDIOBOOK AND PRODUCER’S
                SERVICES, INCLUDING DISTRIBUTION. AUTHOR’S USE OF THE BLAKLISTED
                PLATOFRM, THE AUDIOBOOK AND DISTRIBUTION IS AT THEIR SOLE RISK AND
                THE BLAKLISTED PLATFORM, AUDIOBOOK AND DISTRIBUTION SERVICES ARE
                PROVDED “AS IS” AND “AS AVAILABLE.” SPECIFICALLY, WITHOUT LIMITING
                THE GENERALITY OF THE FOREGOING, PRODUCER AND ITS AFFILIATES MAKE
                NO REPRESENTATION OR WARRANTY THAT THE BLAKLISTED PLATFORM,
                AUDIOBOOK AND DISTRIBUTION (A) WILL MEET AUTHORS REQUIREMENTS;
                (B) WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE OR ERROR
                FREE BASIS; (C) WILL BE ACCURATE, USABLE, RELIABLE, FREE OF VIRUSES,
                COMPLETE, LEGAL OR SAFE; OR (D) THE USE AND DISTRIBUTION THEREOF
                WILL BE FREE FROM INFRINGEMENT OF ANY THIRD PARTY INTELLECTUAL
                PROPERTY RIGHTS. CONSEQUENTLY, PRODUCER DISCLAIMS ANY LIABILITY IN
                CASE ANY SUCH USE AND DISTRIBUTION INFRINGE ANY THIRD PARTY’S
                INTELLECTUAL PROPERTY RIGHTS. FURTHER, PRODUCER HEREBY DISCLAIMS
                ANY WARRANTY AND LIABILIY WHATSOVER FOR ANY DEVELOPMENT
                CREATED FOR AUTHOR, INCLUDING THE AUDIOBOOK. IN NO EVENT WILL
                PRODUCER OR ITS AFFILIATES BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
                CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES, INCLUDING
                BUT NOT LIMITED TO LOSS OF PROFITS OR LOSS OF BUSINESS OPPORTUNITY,
                EVEN IF SUCH DAMAGES ARE FORESEEABLE AND WHETHER OR NOT
                PRODUCER HAS BEEN ADVISED OF THE POSSIBILITY THEREOF. IN NO EVENT
                SHALL PRODUCER’S OR ITS AFFILIATES’ TOTAL LIABILITY ARISING OUT OF OR
                RELATING TO THIS AGREEMENT EXCEED THE AMOUNT PAID BY PRODUCER TO
                AUTHOR HEREUNDER DURING THE 12 MONTH PERIOD PRECEDING THE
                EVENT GIVING RISE TO LIABILITY HEREUNDER.</p>
            <h5>12. ENTIRE AGREEMENT</h5>
            <p>12.1 This Agreement constitutes the entire agreement between the parties with respect
                to its subject matter and supersedes all prior agreements, understandings, and
                negotiations, both written and oral, between the parties with respect to its subject
                matter.</p>
            <p>IN WITNESS WHEREOF, the parties have executed this Agreement as of the Execution
                Date.</p>
            <p><strong>By checking this box, I, the Author described herein, acknowledge that I
                have read, understand, and agree to the terms of the agreement for
                audiobook creation services with BlaklistEd. In addition to the terms
                outlined above, I further agree that my checking this box serves as a digital
                signature and legally binding agreement to the aforementioned agreement.</strong></p>
            <Table bordered hover>
                <thead>
                    <tr>
                        <th>BlaklistEd, LLC</th>
                        <th>AUTHOR</th>
                        
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>By:</td>
                        <td>By:</td>                        
                    </tr>
                    <tr>
                        <td>Name:</td>
                        <td>Name:</td>                        
                    </tr>
                    <tr>
                        <td>Title:</td>
                        <td>Title:</td>                        
                    </tr>
                    <tr>
                        <td>Date:</td>
                        <td>Date:</td>                        
                    </tr>
                   
                </tbody>
            </Table>
        </>
    )
}
