import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Form, Button, Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// DATA
import selectLanguages from "../../data/langOptions.json";

export default function Publish() {

    // MODAL FORM FIELDS
    const [authorShow, setAuthorShow] = useState(false);
    const [authorsList, setAuthorsList] = useState([]);
    const [authorData, setAuthorData] = useState({
        prefix: '',
        suffix: '',
        firstName: '',
        middleName: '',
        lastName: '',
    });

    const authorShowHandler = () => setAuthorShow(true);
    const authorCloseHandler = () => {
        setAuthorShow(false);
        // Clear author form data on modal close
        setAuthorData({
            prefix: '',
            suffix: '',
            firstName: '',
            middleName: '',
            lastName: '',
        });
    };

    const addAuthorHandler = () => {
        const newAuthor = { ...authorData, id: Date.now() };
        setAuthorsList([...authorsList, newAuthor]);
        authorCloseHandler();
    };

    const removeAuthorHandler = (id) => {
        setAuthorsList(authorsList.filter((author) => author.id !== id));
    };

    // NARRATORS AREA

    // MODAL FORM FIELDS
    const [narratorsShow, setNarratorsShow] = useState(false);
    const [narratorsList, setNarratorsList] = useState([]);
    const [narratorsData, setNarratorsData] = useState({
        prefix: '',
        suffix: '',
        firstName: '',
        middleName: '',
        lastName: '',
    });

    const narratorsShowHandler = () => setNarratorsShow(true);
    const narratorsCloseHandler = () => {
        setNarratorsShow(false);
        // Clear author form data on modal close
        setNarratorsData({
            prefix: '',
            suffix: '',
            firstName: '',
            middleName: '',
            lastName: '',
        });
    };

    const addNarratorsHandler = () => {
        const newNarrators = { ...narratorsData, id: Date.now() };
        setNarratorsList([...narratorsList, newNarrators]);
        narratorsCloseHandler();
    };

    const removeNarratorsHandler = (id) => {
        setNarratorsList(narratorsList.filter((narrators) => narrators.id !== id));
    };

    // PUBLISHER FIELD
    const [hasBrandName, setHasBrandName] = useState(false);

    const handleBrandNameChange = (e) => {
        setHasBrandName(e.target.value === 'Yes');
    };

    // LANGUAGE TIP
    const renderLanguageTooltip = (props) => (
        <Tooltip id="LanguageTooltip" {...props}>
            Let us know which language your audiobook is narrated in.
        </Tooltip>
    );

    // ABRIDGED?
    const renderadridgedTooltip = (props) => (
        <Tooltip id="AdridgedTooltip" {...props}>
            Choose yes if this book is a shortened version of the original (not the full book).
        </Tooltip>
    );

    // EXPLICIT
    const renderexplicitTooltip = (props) => (
        <Tooltip id="ExplicitTooltip" {...props}>
            Choose yes if this book contains content not suitable for individuals under 18 years of age.
        </Tooltip>
    );

    return (
        <>
            <div className='dbody-area'>
                <div className='dpage-heading'>
                    <h1>New Audiobook</h1>
                </div>
                <div className='db-content'>
                    <div className="top-checks">
                        <ul>
                            <li><Link to='/theblaklisted/publish'><FontAwesomeIcon icon={regular("square")} /> <span>Get Started</span></Link></li>
                            <li><Link to='/theblaklisted/publish-metadata' disabled><FontAwesomeIcon icon={regular("square")} /> <span>Metadata</span></Link></li>
                            <li><Link to='/theblaklisted/publish-audio-and-covers' disabled><FontAwesomeIcon icon={regular("square")} /> <span>Audio & Covers</span></Link></li>
                            <li><Link to='/theblaklisted/publish-agreement' disabled><FontAwesomeIcon icon={regular("square")} /> <span>Agreement</span></Link></li>
                        </ul>
                    </div>

                    <div className="forms-area">
                        <div className="start-form">
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="title">
                                        <Form.Label>Title</Form.Label>
                                        <Form.Control type="text" placeholder="" required />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="subtitle">
                                        <Form.Label>Subtitle (Optional)</Form.Label>
                                        <Form.Control type="text" placeholder="" />
                                    </Form.Group>
                                </Col>

                                {/* ADD AUTHOR */}
                                <Col md={3}>
                                    <Button
                                        variant="primary mt-3 mb-4"
                                        onClick={authorShowHandler}
                                        className="btn-style yellow-btn"
                                    >
                                        Add Author
                                    </Button>
                                    <Modal show={authorShow} onHide={authorCloseHandler}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Add Author</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="modal-form">
                                                <Row>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-3" controlId="author-prefix">
                                                            <Form.Label>Prefix</Form.Label>
                                                            <Form.Select
                                                                aria-label="Select Prefix"
                                                                value={authorData.prefix}
                                                                onChange={(e) =>
                                                                    setAuthorData({ ...authorData, prefix: e.target.value })
                                                                }
                                                            >
                                                                <option value=""></option>
                                                                <option value="B.C.E">B.C.E.</option>
                                                                <option value="Dr.">Dr.</option>
                                                                <option value="Esq.">Esq.</option>
                                                                <option value="Jr.">Jr.</option>
                                                                <option value="M.D.">M.D.</option>
                                                                <option value="Ph.D.">Ph.D.</option>
                                                                <option value="Prof.">Prof.</option>
                                                                <option value="Rev.">Rev.</option>
                                                                <option value="Rt. Hon.">Rt. Hon.</option>
                                                                <option value="Sr.">Sr.</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-3" controlId="author-sufix">
                                                            <Form.Label>Suffix</Form.Label>
                                                            <Form.Select
                                                                aria-label="Select Suffix"
                                                                value={authorData.suffix}
                                                                onChange={(e) =>
                                                                    setAuthorData({ ...authorData, suffix: e.target.value })
                                                                }
                                                            >
                                                                <option value=""></option>
                                                                <option value="B.C.E">B.C.E.</option>
                                                                <option value="Dr.">Dr.</option>
                                                                <option value="Esq.">Esq.</option>
                                                                <option value="Jr.">Jr.</option>
                                                                <option value="M.D.">M.D.</option>
                                                                <option value="Ph.D.">Ph.D.</option>
                                                                <option value="Prof.">Prof.</option>
                                                                <option value="Rev.">Rev.</option>
                                                                <option value="Rt. Hon.">Rt. Hon.</option>
                                                                <option value="Sr.">Sr.</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Form.Group className="mb-3" controlId="author-first-name">
                                                            <Form.Label>First Name</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder=""
                                                                value={authorData.firstName}
                                                                onChange={(e) =>
                                                                    setAuthorData({ ...authorData, firstName: e.target.value })
                                                                }
                                                                required
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Form.Group className="mb-3" controlId="author-middle-name">
                                                            <Form.Label>Middle Name(s) / Initial(s)</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder=""
                                                                value={authorData.middleName}
                                                                onChange={(e) =>
                                                                    setAuthorData({ ...authorData, middleName: e.target.value })
                                                                }
                                                                required
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Form.Group className="mb-3" controlId="author-last-name">
                                                            <Form.Label>Last Name</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder=""
                                                                value={authorData.lastName}
                                                                onChange={(e) =>
                                                                    setAuthorData({ ...authorData, lastName: e.target.value })
                                                                }
                                                                required
                                                            />

                                                        </Form.Group>
                                                    </Col>

                                                </Row>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button
                                                className="btn-style yellow-btn"
                                                variant="secondary"
                                                onClick={addAuthorHandler}
                                            >
                                                Add Author
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </Col>
                                <Col md={9}>
                                    <div className="authorslist mt-3 mb-4">
                                        {authorsList.map((author) => (
                                            <div key={author.id}>
                                                <button onClick={() => removeAuthorHandler(author.id)}>X</button>
                                                <span>{author.firstName}</span>
                                                <input type="hidden" name={`author-prefix-${author.id}`} value={author.prefix} />
                                                <input type="hidden" name={`author-suffix-${author.id}`} value={author.suffix} />
                                                <input type="hidden" name={`author-first-name-${author.id}`} value={author.firstName} />
                                                <input type="hidden" name={`author-middle-name-${author.id}`} value={author.middleName} />
                                                <input type="hidden" name={`author-last-name-${author.id}`} value={author.lastName} />
                                            </div>
                                        ))}
                                    </div>
                                </Col>

                                {/* ADD NARRATOR */}
                                <Col md={3}>
                                    <Button
                                        variant="primary mt-3 mb-4"
                                        onClick={narratorsShowHandler}
                                        className="btn-style yellow-btn"
                                    >
                                        Add Narrators
                                    </Button>
                                    <Modal show={narratorsShow} onHide={narratorsCloseHandler}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Add Narrator</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="modal-form">
                                                <Row>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-3" controlId="narrators-prefix">
                                                            <Form.Label>Prefix</Form.Label>
                                                            <Form.Select
                                                                aria-label="Select Prefix"
                                                                value={narratorsData.prefix}
                                                                onChange={(e) =>
                                                                    setNarratorsData({ ...narratorsData, prefix: e.target.value })
                                                                }
                                                            >
                                                                <option value=""></option>
                                                                <option value="B.C.E">B.C.E.</option>
                                                                <option value="Dr.">Dr.</option>
                                                                <option value="Esq.">Esq.</option>
                                                                <option value="Jr.">Jr.</option>
                                                                <option value="M.D.">M.D.</option>
                                                                <option value="Ph.D.">Ph.D.</option>
                                                                <option value="Prof.">Prof.</option>
                                                                <option value="Rev.">Rev.</option>
                                                                <option value="Rt. Hon.">Rt. Hon.</option>
                                                                <option value="Sr.">Sr.</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-3" controlId="narrators-sufix">
                                                            <Form.Label>Suffix</Form.Label>
                                                            <Form.Select
                                                                aria-label="Select Suffix"
                                                                value={narratorsData.suffix}
                                                                onChange={(e) =>
                                                                    setNarratorsData({ ...narratorsData, suffix: e.target.value })
                                                                }
                                                            >
                                                                <option value=""></option>
                                                                <option value="B.C.E">B.C.E.</option>
                                                                <option value="Dr.">Dr.</option>
                                                                <option value="Esq.">Esq.</option>
                                                                <option value="Jr.">Jr.</option>
                                                                <option value="M.D.">M.D.</option>
                                                                <option value="Ph.D.">Ph.D.</option>
                                                                <option value="Prof.">Prof.</option>
                                                                <option value="Rev.">Rev.</option>
                                                                <option value="Rt. Hon.">Rt. Hon.</option>
                                                                <option value="Sr.">Sr.</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Form.Group className="mb-3" controlId="narrators-first-name">
                                                            <Form.Label>First Name</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder=""
                                                                value={narratorsData.firstName}
                                                                onChange={(e) =>
                                                                    setNarratorsData({ ...narratorsData, firstName: e.target.value })
                                                                }
                                                                required
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Form.Group className="mb-3" controlId="narrators-middle-name">
                                                            <Form.Label>Middle Name(s) / Initial(s)</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder=""
                                                                value={narratorsData.middleName}
                                                                onChange={(e) =>
                                                                    setNarratorsData({ ...narratorsData, middleName: e.target.value })
                                                                }
                                                                required
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Form.Group className="mb-3" controlId="narrators-last-name">
                                                            <Form.Label>Last Name</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder=""
                                                                value={narratorsData.lastName}
                                                                onChange={(e) =>
                                                                    setNarratorsData({ ...narratorsData, lastName: e.target.value })
                                                                }
                                                                required
                                                            />

                                                        </Form.Group>
                                                    </Col>

                                                </Row>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button
                                                className="btn-style yellow-btn"
                                                variant="secondary"
                                                onClick={addNarratorsHandler}
                                            >
                                                Add Narrator
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </Col>
                                <Col md={9}>
                                    <div className="authorslist mt-3 mb-4">
                                        {narratorsList.map((narrators) => (
                                            <div key={narrators.id}>
                                                <button onClick={() => removeNarratorsHandler(narrators.id)}>X</button>
                                                <span>{narrators.firstName}</span>
                                                <input type="hidden" name={`narrators-prefix-${narrators.id}`} value={narrators.prefix} />
                                                <input type="hidden" name={`narrators-suffix-${narrators.id}`} value={narrators.suffix} />
                                                <input type="hidden" name={`narrators-first-name-${narrators.id}`} value={narrators.firstName} />
                                                <input type="hidden" name={`narrators-middle-name-${narrators.id}`} value={narrators.middleName} />
                                                <input type="hidden" name={`narrators-last-name-${narrators.id}`} value={narrators.lastName} />
                                            </div>
                                        ))}
                                    </div>
                                </Col>

                                {/* BRAND NAME */}
                                <Col md={12}>
                                    <Form.Group className="mb-3" controlId="subtitle">
                                        <Form.Label>Do you have a publishing or brand name?</Form.Label>
                                        {['radio'].map((type) => (
                                            <div key={`inline-${type}`} className="mb-3">
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="brandname"
                                                    type={type}
                                                    id={`inline-${type}-1`}
                                                    value="Yes"
                                                    onChange={handleBrandNameChange}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="brandname"
                                                    type={type}
                                                    id={`inline-${type}-2`}
                                                    value="No"
                                                    onChange={handleBrandNameChange}
                                                    checked={!hasBrandName}
                                                />
                                            </div>
                                        ))}
                                    </Form.Group>

                                    {hasBrandName && (
                                        <Form.Group className="mb-3" controlId="publisher-name">
                                            <Form.Label>Publisher</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Publisher name"
                                                required
                                            />
                                        </Form.Group>
                                    )}

                                </Col>

                                {/* LANGUAGE */}
                                <Col md={4}>
                                    <Form.Group className="mb-3" controlId="book-lang">
                                        <Form.Label>
                                            Language
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderLanguageTooltip}
                                            >
                                                <Button variant="link">
                                                    <FontAwesomeIcon icon={solid("circle-info")} />
                                                </Button>
                                            </OverlayTrigger>

                                        </Form.Label>
                                        {/* <Form.Select aria-label="book language">
                                            {selectLanguages.map((option, index) => (
                                                <option key={index} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </Form.Select> */}
                                        <Form.Select aria-label="book language">
                                            {selectLanguages.languages.map((language, index) => (
                                                <option key={index} value={language.value}>
                                                    {language.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3" controlId="abridged">
                                        <Form.Label>Is the book abridged?
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderadridgedTooltip}
                                            >
                                                <Button variant="link">
                                                    <FontAwesomeIcon icon={solid("circle-info")} />
                                                </Button>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        {['radio'].map((type) => (
                                            <div key={`inline-${type}`} className="mb-3">
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="abridged"
                                                    type={type}
                                                    id={`abridged-${type}-1`}
                                                    value="Yes"
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="abridged"
                                                    type={type}
                                                    id={`abridged-${type}-2`}
                                                    value="No" checked
                                                />
                                            </div>
                                        ))}
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3" controlId="exp-content">
                                        <Form.Label>Does it include explicit content?
                                            <OverlayTrigger
                                                placement="left"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderexplicitTooltip}
                                            >
                                                <Button variant="link">
                                                    <FontAwesomeIcon icon={solid("circle-info")} />
                                                </Button>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        {['radio'].map((type) => (
                                            <div key={`inline-${type}`} className="mb-3">
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="exp-content"
                                                    type={type}
                                                    id={`excontent-${type}-1`}
                                                    value="Yes"
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="exp-content"
                                                    type={type}
                                                    id={`excontent-${type}-2`}
                                                    value="No" checked
                                                />
                                            </div>
                                        ))}
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>

                        <div className="setps-options">
                            <Row>
                                <Col>
                                    <div className="lft-side">
                                        <Link to="" className="btn-style black-btn">Cancel</Link>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="ryt-side">
                                        <Link to="/theblaklisted/publish-metadata" className="btn-style yellow-btn">Save & Continue</Link>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
