import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Form, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MultiSelect } from "react-multi-select-component";

// DATA
import selectGenre from "../../data/genreOptions.json";
import selectCountry from "../../data/countryOptions.json";
import initialData from '../../data/worldData.json';

export default function PublishMetadata() {

    // DESCBOOK
    const renderdescTooltip = (props) => (
        <Tooltip id="DescTooltip" {...props}>
            Please describe your audiobook in 2,000 characters or less. This description will appear on our retail partners' platforms.
        </Tooltip>
    );

    // SALESRIGHTS
    const rendersalesrTooltip = (props) => (
        <Tooltip id="salesrightsTooltip" {...props}>
            Sales rights indicate where in the world you want your book for sale. If there are no legal restrictions select --WORLD--
        </Tooltip>
    );

    // CHILDBOOK
    const renderchildbookTooltip = (props) => (
        <Tooltip id="ChildbookTooltip" {...props}>
            Choose yes if the content of this book is specifically written for a child audience between ages 0-18.
        </Tooltip>
    );

    // CHILDBOOK OPTIONS    
    const [hasChildBook, setHasChildBook] = useState(false);
    const handleChildBookChange = (e) => {
        setHasChildBook(e.target.value === 'Yes');
    };

    // SALES RIGHTS OPTIONS
    // const [selected, setSelected] = useState([]);

    // const options = selectCountry.map(country => ({
    //     label: country.label,
    //     value: country.value,
    // }));
    // const initialData = [
    //     { value: "india", label: "India", type: "common-wealth" },
    //     { value: "australia", label: "Australia", type: "common-wealth" },
    //     { value: "germany", label: "Germany", type: "europe" },
    //     { value: "france", label: "France", type: "europe" }
    // ];
    const [selectedOption, setSelectedOption] = useState("All World");
    const [checkedCountries, setCheckedCountries] = useState([]);
    const [countryData, setCountryData] = useState(initialData);

    useEffect(() => {
        // Set checked countries for selected option
        if (selectedOption === "All World") {
            setCheckedCountries(initialData.map(country => country.value));
        } else {
            const countriesOfType = initialData.filter(country => country.type === selectedOption);
            setCheckedCountries(countriesOfType.map(country => country.value));
        }
    }, [selectedOption]); // Update checked countries whenever selectedOption changes

    const uniqueTypes = Array.from(new Set(countryData.map(item => item.type)));

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedOption(selectedValue);
    };

    const handleCheckboxChange = (event) => {
        const isChecked = event.target.checked;
        const countryValue = event.target.value;
        let updatedCheckedCountries;

        if (isChecked) {
            updatedCheckedCountries = [...checkedCountries, countryValue];
        } else {
            updatedCheckedCountries = checkedCountries.filter(country => country !== countryValue);
        }

        setCheckedCountries(updatedCheckedCountries);
    };


    // OWNER NAME
    const renderonameTooltip = (props) => (
        <Tooltip id="OnameTooltip" {...props}>
            Print Owner Name is required. Who owns the print version of your book? Chances are, this is you!
        </Tooltip>
    );

    // COPYRIGHTS
    const rendercopyrTooltip = (props) => (
        <Tooltip id="CopyrTooltip" {...props}>
            Print Copyright Year is required. When was the print version of your book copy written? If there is more than one year, separate the years with commas.
        </Tooltip>
    );

    // AUDIOBOOK OWNER NAME
    const renderaudiobnameTooltip = (props) => (
        <Tooltip id="AudiobnameTooltip" {...props}>
            Audiobook Owner Name is required. Who owns the audiobook version of your book? Chances are, this is you!
        </Tooltip>
    );

    // AUDIOBOOK COPYRIGHTS
    const renderaudiobcopyTooltip = (props) => (
        <Tooltip id="AudiobcopyTooltip" {...props}>
            Audiobook Copyright Year is required. When was the audiobook version of your book copy written? If there is more than one year, separate the years with commas.
        </Tooltip>
    );

    // MSRP
    const renderMSRPTooltip = (props) => (
        <Tooltip id="MSRPTooltip" {...props}>
            MSRP (manufacturer's suggested retail price) is required. This is the price to purchase your audiobook. This is the price we will show if a customer wants to purchase your audiobook without being a subscriber to BlaklistEd. NOTE: A BlaklistEd subscription is $14.99. You must make your audiobook price greater than our subscription price.
        </Tooltip>
    );

    // ASIN
    const renderASINTooltip = (props) => (
        <Tooltip id="ASINTooltip" {...props}>
            This is your eBook “Amazon Sales Inventory Number”. If you are unsure what your ASIN is or if you do not have an ASIN, simply leave this field empty.
        </Tooltip>
    );


    return (
        <>
            <div className='dbody-area'>
                <div className='dpage-heading'>
                    <h1>Audiobook Title</h1>
                </div>
                <div className='db-content'>
                    <div className="top-checks">
                        <ul>
                            <li><Link to='/theblaklisted/publish' className="step-done"><FontAwesomeIcon icon={solid("square-check")} /> <span>Get Started</span></Link></li>
                            <li><Link to='/theblaklisted/publish-metadata'><FontAwesomeIcon icon={regular("square")} /> <span>Metadata</span></Link></li>
                            <li><Link to='/theblaklisted/publish-audio-and-covers' disabled><FontAwesomeIcon icon={regular("square")} /> <span>Audio & Covers</span></Link></li>
                            <li><Link to='/theblaklisted/publish-agreement' disabled><FontAwesomeIcon icon={regular("square")} /> <span>Agreement</span></Link></li>
                        </ul>
                    </div>

                    <div className="forms-area">
                        <div className="start-form">
                            <Row>
                                <Col md={12}>
                                    <div className="heading-with-note">
                                        <h4>Audiobook Metadata</h4>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <Form.Group className="mb-3" controlId="bookdesc">
                                        <Form.Label>Description
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderdescTooltip}
                                            >
                                                <Button variant="link">
                                                    <FontAwesomeIcon icon={solid("circle-info")} />
                                                </Button>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        <Form.Control as="textarea" rows={4} />
                                        <span class="words-count">(0 Characters of Max 2,000)</span>
                                    </Form.Group>

                                </Col>
                                <Col md={12}>
                                    <Form.Group className="mb-3" controlId="childbook">
                                        <Form.Label>Is this a children’s book (ages 0-18)?

                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderchildbookTooltip}
                                            >
                                                <Button variant="link">
                                                    <FontAwesomeIcon icon={solid("circle-info")} />
                                                </Button>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        {['radio'].map((type) => (
                                            <div key={`inline-${type}`} className="mb-3">
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="child-book"
                                                    type={type}
                                                    id={`childbook-${type}-1`}
                                                    value="Yes"
                                                    onChange={handleChildBookChange}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="child-book"
                                                    type={type}
                                                    id={`childbook-${type}-2`}
                                                    value="No"
                                                    onChange={handleChildBookChange}
                                                    checked={!hasChildBook}
                                                />
                                            </div>
                                        ))}
                                    </Form.Group>
                                    {hasChildBook && (
                                        <Form.Group className="mb-3" controlId="child-age">
                                            <Form.Label>Children's Age Group</Form.Label>
                                            <Form.Select aria-label="Default select example">
                                                <option value="0" selected="selected">--Select--</option>
                                                <option value="1">None</option>
                                                <option value="2">Picture Books (3 to 6 Years of Age)</option>
                                                <option value="3">Early Reader (6 to 10 Years of Age)</option>
                                                <option value="4">Middle Grade (8 to 12 Years of Age)</option>
                                                <option value="5">Young Adult (12 and older)</option>
                                            </Form.Select>
                                        </Form.Group>
                                    )}
                                </Col>
                            </Row>

                            <Row className="pub-genre">
                                {/* GENRE */}
                                <Col md={5}>
                                    <Form.Group className="mb-3" controlId="genre-req">
                                        <Form.Label>
                                            Genre 1 <span className="req-star">*</span>
                                        </Form.Label>
                                        <Form.Select aria-label="book genre" required>
                                            {selectGenre.map((option, index) => (
                                                <option key={index} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={2} className="genre-arrow">
                                    <FontAwesomeIcon className="mb-3" icon={solid("arrow-right")}></FontAwesomeIcon>
                                </Col>
                                <Col md={5}>
                                    <Form.Group className="mb-3" controlId="genre-req-sub">
                                        <Form.Select aria-label="book genre">
                                            {selectGenre.map((option, index) => (
                                                <option key={index} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>

                                {/* GENRE 2 */}

                                <Col md={5}>
                                    <Form.Group className="mb-3" controlId="genre-req2">
                                        <Form.Label>
                                            Genre 2 (Optional)
                                        </Form.Label>
                                        <Form.Select aria-label="book genre">
                                            {selectGenre.map((option, index) => (
                                                <option key={index} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={2} className="genre-arrow">
                                    <FontAwesomeIcon className="mb-3" icon={solid("arrow-right")}></FontAwesomeIcon>
                                </Col>
                                <Col md={5}>
                                    <Form.Group className="mb-3" controlId="genre-req2-sub">
                                        <Form.Select aria-label="book genre">
                                            {selectGenre.map((option, index) => (
                                                <option key={index} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>

                                {/* GENRE 3*/}

                                <Col md={5}>
                                    <Form.Group className="mb-3" controlId="genre-req3">
                                        <Form.Label>
                                            Genre 3 (Optional)
                                        </Form.Label>
                                        <Form.Select aria-label="book genre">
                                            {selectGenre.map((option, index) => (
                                                <option key={index} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={2} className="genre-arrow">
                                    <FontAwesomeIcon className="mb-3" icon={solid("arrow-right")}></FontAwesomeIcon>
                                </Col>
                                <Col md={5}>
                                    <Form.Group className="mb-3" controlId="genre-req3-sub">
                                        <Form.Select aria-label="book genre">
                                            {selectGenre.map((option, index) => (
                                                <option key={index} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col md={12}>
                                    <div className="heading-with-note">
                                        <h4>Other Information</h4>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <Form.Group className="mb-3" controlId="salesRights">
                                        <Form.Label>Sales Rights <span className="req-star">*</span>
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={rendersalesrTooltip}
                                            >
                                                <Button variant="link">
                                                    <FontAwesomeIcon icon={solid("circle-info")} />
                                                </Button>
                                            </OverlayTrigger>
                                        </Form.Label>

                                        {/* <pre>{JSON.stringify(selected)}</pre> */}
                                        {/* <MultiSelect
                                            options={options}
                                            value={selected}
                                            onChange={setSelected}
                                            labelledBy="Select"
                                        /> */}
                                        <div>
                                            <Form.Select value={selectedOption} onChange={handleSelectChange}>
                                                <option value="All World">All World</option>
                                                {uniqueTypes.map((type, index) => (
                                                    <option key={index} value={type}>
                                                        {type}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <div className="contarea">
                                                {countryData.map((country, index) => (
                                                    <div key={index}>
                                                        <input
                                                            type="checkbox"
                                                            id={country.value}
                                                            value={country.value}
                                                            checked={checkedCountries.includes(country.value)}
                                                            onChange={handleCheckboxChange}
                                                            disabled={selectedOption !== "All World" && country.type !== selectedOption}
                                                        />
                                                        <label htmlFor={country.value}>{country.label}</label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="ci-printowner">
                                        <Form.Label>Print Owner Name *
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderonameTooltip}
                                            >
                                                <Button variant="link">
                                                    <FontAwesomeIcon icon={solid("circle-info")} />
                                                </Button>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        <Form.Control type="text" placeholder="Chances are this is you!" required/>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="ci-printyear">
                                        <Form.Label>Print Copyright Year *
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={rendercopyrTooltip}
                                            >
                                                <Button variant="link">
                                                    <FontAwesomeIcon icon={solid("circle-info")} />
                                                </Button>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        <Form.Control type="text" placeholder="2023, 2024" required/>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="ci-abowner">
                                        <Form.Label>Audiobook Owner Name <span className="req-star">*</span>
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderaudiobnameTooltip}
                                            >
                                                <Button variant="link">
                                                    <FontAwesomeIcon icon={solid("circle-info")} />
                                                </Button>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        <Form.Control type="text" placeholder="Chances are this is you!" />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="ci-abcopyr">
                                        <Form.Label>Audiobook Copyright Year <span className="req-star">*</span>
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderaudiobcopyTooltip}
                                            >
                                                <Button variant="link">
                                                    <FontAwesomeIcon icon={solid("circle-info")} />
                                                </Button>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        <Form.Control type="text" placeholder="2023, 2024" />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="MSRP">
                                        <Form.Label>MSRP <span className="req-star">*</span>
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderMSRPTooltip}
                                            >
                                                <Button variant="link">
                                                    <FontAwesomeIcon icon={solid("circle-info")} />
                                                </Button>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        <Form.Control type="number" placeholder="0.00" />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="ASIN">
                                        <Form.Label>ASIN
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderASINTooltip}
                                            >
                                                <Button variant="link">
                                                    <FontAwesomeIcon icon={solid("circle-info")} />
                                                </Button>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        <Form.Control type="text" placeholder="Provide your ASIN or leave empty if unsure" name="ProjectMeta.ASN" />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="mt-3">

                                <Col md={12}>
                                    <div className="heading-with-note">
                                        <h4>ISBNs (Optional)</h4>
                                    </div>
                                    <p>If you want to use your own ISBNs, you will need two: One for the digital retail channels, and one for the digital library channels. If you only have one ISBN we will assign a second ISBN for you.</p>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="dr-ISBN">
                                        <Form.Label>Digital Retail ISBN</Form.Label>
                                        <Form.Control type="text" placeholder="OPTIONAL" />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="dl-ISBN">
                                        <Form.Label>Digital Library ISBN
                                        </Form.Label>
                                        <Form.Control type="text" placeholder="OPTIONAL" />
                                    </Form.Group>
                                </Col>

                            </Row>
                        </div>
                        <div className="setps-options">
                            <Row>
                                <Col>
                                    <div className="lft-side">
                                        <Link to="" className="btn-style black-btn">Cancel</Link>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="ryt-side">
                                        <Link to="/theblaklisted/publish-audio-and-covers" className="btn-style yellow-btn">Save & Continue</Link>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
