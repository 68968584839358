import React from 'react'
import SignatureField from '../SignatureField'
import { Row, Col, Form } from 'react-bootstrap'

export default function W9Component() {
  return (
    <div>
      <Row>
        <Col md={12} className="top-pop-head">
          <strong>You are responsible for ensuring the accurary and completeness of the tax forms on submission to BlaklistEd</strong>
        </Col>
      </Row>
      <div className='pop-three'>
        <Row>
          <Col md={3}>
            <p>Form W-9</p>
            <span>(Rev. October 2018) Department of Treasury Internal Revenue Service</span>
          </Col>
          <Col md={6}>
            <p>Request for Taxpayer Identification Number and Certification (W-9)</p>
            <strong>For instructions go to</strong>
            <a href='https://www.irs.gov/forms-pubs/about-form-w-9' target='_blank'>https://www.irs.gov/forms-pubs/about-form-w-9</a>
          </Col>
          <Col md={3}>
            <span>Give Form to the requester. Do not send to the IRS.</span>
          </Col>
        </Row>
      </div>
      <Form>
        <Form.Group className="mb-2" controlId="pop-name">
          <Form.Label>1. Name (as shown on your income tax return). Your name is required; do not leave this line blank.</Form.Label>
          <Form.Control type="text" placeholder="Name as shown on your income tax return" />
        </Form.Group>
        <Form.Group className="mb-2" controlId="pop-businessname">
          <Form.Label>2. Business name / disregarded entity name, if different from above.</Form.Label>
          <Form.Control type="text" placeholder="Business name" />
        </Form.Group>
        <hr />
        <div className='tax-classification'>
          <Form.Label>3. Check appropriate box for federal tax classification of the person whose name is entered above.</Form.Label>
          <Row>
            <Col md={3}>
              <Form.Check
                name='tax-class'
                type="radio"
                label="Individual/sole proprietor or single-member LLC"
                id="individual"
              />
            </Col>
            <Col md={3}>
              <Form.Check
                name='tax-class'
                type="radio"
                label="C Corporation"
                id="c-corp"
              />
            </Col>
            <Col md={3}>
              <Form.Check
                name='tax-class'
                type="radio"
                label="S Corporation"
                id="s-corp"
              />
            </Col>
            <Col md={3}>
              <Form.Check
                name='tax-class'
                type="radio"
                label="Partnership"
                id="partnership"
              />
            </Col>
            <Col md={3}>
              <Form.Check
                name='tax-class'
                type="radio"
                label="Trust/Estate"
                id="trust-estate"
              />
            </Col>
            <Col md={3}>
              <Form.Check
                name='tax-class'
                type="radio"
                label="Limited liability company."
                id="limit-liab"
              />
            </Col>
            <Col md={12} className='pop-other-field'>
              <Form.Check
                custom
                type="radio"
                id="custom-radio"
                name="tax-class"
              />
              <Form.Label htmlFor="custom-radio">
                Other <a href="https://www.irs.gov/forms-pubs/about-form-w-9" target='_blank'>(See Instructions)</a>
              </Form.Label>
              <Form.Control type="text" placeholder="" />
            </Col>
          </Row>
        </div>
        <hr />
        <div className='exemptions mb-2'>
          <label>4. Exemptions (Applies to accounts maintained outside the U.S.)</label>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-2" controlId="exe-payee">
                <Form.Label>Exempt Payee (if any)</Form.Label>
                <Form.Control type="text" />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-2" controlId="exe-fatca">
                <Form.Label>Exemption from FATCA reporting code (if any)</Form.Label>
                <Form.Control type="text" />
              </Form.Group>
            </Col>
          </Row>
        </div>
        <hr />
        <div className='pop-address'>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-2" controlId="pa-address">
                <Form.Label>5. Address (Number, Street, and apt. or suite no.)</Form.Label>
                <Form.Control type="text" />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-2" controlId="pa-city">
                <Form.Label>6. City, State, and ZIP code</Form.Label>
                <Form.Control type="text" />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group className="mb-2" controlId="pa-list-account">
                <Form.Label>7. List account number(s) here (optional)</Form.Label>
                <Form.Control type="text" />
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className='part-1'>
          <div className='part-heading'>
            <strong>Part I</strong>
            <span>Taxpayer Identification Number (TIN)</span>
          </div>
          <div className='part-content'>
            <p>Enter your TIN in the appropriate box. The TIN provided must match the name given above to avoid backup withholding. For individuals, this is generally your social security number (SSN). However, for a resident alien, sole proprietor, or disregarded entity, <a href="https://www.irs.gov/forms-pubs/about-form-w-9" target='_blank'>see the instructions</a> for Part I, later. For other entities, it is your employer identification number (EIN). If you do not have a number, see How to get a TIN, later.</p>
            <p><strong>Note:</strong> If the account is in more than one name, see the instructions for line 1. Also see What Name and Number To Give the Requester for guidelines on whose number to enter.</p>
            <Row>
              <Col md={5}>
                <Form.Group className="mb-2" controlId="taxpayer-ssn">
                  <Form.Label>Social Security Number</Form.Label>
                  <Form.Control type="text" placeholder='Social Security Number' />
                </Form.Group>
              </Col>
              <Col md={2}>OR</Col>
              <Col md={5}>
                <Form.Group className="mb-2" controlId="taxpayer-ein">
                  <Form.Label>Employer Identification Number</Form.Label>
                  <Form.Control type="text" placeholder='Employer Identification Number' />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </div>
        <div className='part-2'>
          <div className='part-heading'>
            <strong>Part II</strong>
            <span>Certification</span>
          </div>
          <div className='part-content'>
            <p>Under penalties of perjury, I certify that:</p>
            <ul>
              <li>The number shown on this form is my correct taxpayer identification number (or I am waiting for a number to be issued to me); and</li>
              <li>I am not subject to backup withholding because: (a) I am exempt from backup withholding, or (b) I have not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest or dividends, or (c) the IRS has notified me that I am no longer subject to backup withholding; and</li>
              <li>I am a U.S. citizen or other U.S. person (defined below); and</li>
              <li>The FATCA code(s) entered on this form (if any) indicating that I am exempt from FATCA reporting is correct.</li>
            </ul>
            <p><strong>Certification instructions.</strong> You must cross out item 2 above if you have been notified by the IRS that you are currently subject to backup withholding because you have failed to report all interest and dividends on your tax return. For real estate transactions, item 2 does not apply. For mortgage interest paid, acquisition or abandonment of secured property, cancellation of debt, contributions to an individual retirement arrangement (IRA), and generally, payments other than interest and dividends, you are not required to sign the certification, but you must provide your correct TIN. See the instructions for Part II, later.</p>
            <p>Visit <a href='https://www.irs.gov/forms-pubs/about-form-w-9' target='_blank'>https://www.irs.gov/forms-pubs/about-form-w-9</a> for general instructions.</p>
            <Form.Group className="mb-3" controlId="acknowledge">
              <Form.Check type="checkbox" label="I acknowledge that it is my responsibility to ensure the generated tax form is completed accurately and that failure to provide accurate information will result in withheld payments from BlaklistEd until such time as a valid form is produced. I further confirm that I am authorizing the use of my digital signature as provided in this section for the purposes of producing the tax form." />
            </Form.Group>
          </div>
        </div>
      </Form>
      <SignatureField>Signature of U.S. person</SignatureField>
    </div>
  )
}
