import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Form, Button } from "react-bootstrap";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileUpload from "theblaklisted/components/FileUpload";
import AudioPreview from "theblaklisted/components/AudioPreview";

export default function AudioAndCovers() {
    const [audioFiles, setAudioFiles] = useState({}); // Managing files instead of previews

    const handleAudioChange = (id, file) => {
        if (file && file.type.startsWith("audio")) {
            const url = URL.createObjectURL(file);
            setAudioFiles(prev => ({ ...prev, [id]: { file, url } }));
        } else {
            setAudioFiles(prev => {
                const newState = { ...prev };
                delete newState[id];
                return newState;
            });
        }
    };

    const handleDeleteAudio = (id) => {
        // Revoke the object URL to avoid memory leaks
        if (audioFiles[id]?.url) {
            URL.revokeObjectURL(audioFiles[id].url);
        }

        setAudioFiles(prev => {
            const newState = { ...prev };
            delete newState[id];
            return newState;
        });

        // Optionally, clear the file input
        // document.getElementById(id)?.querySelector('input[type="file"]').value = "";
    };


    return (
        <>
            <div className='dbody-area'>
                <div className='dpage-heading'>
                    <h1>Audiobook Title</h1>
                </div>
                <div className='db-content'>
                    <div className="top-checks">
                        <ul>
                            <li><Link to='/theblaklisted/publish' className="step-done"><FontAwesomeIcon icon={solid("square-check")} /> <span>Get Started</span></Link></li>
                            <li><Link to='/theblaklisted/publish-metadata' className="step-done"><FontAwesomeIcon icon={solid("square-check")} /> <span>Metadata</span></Link></li>
                            <li><Link to='/theblaklisted/publish-audio-and-covers'><FontAwesomeIcon icon={regular("square")} /> <span>Audio & Covers</span></Link></li>
                            <li><Link to='/theblaklisted/publish-agreement' disabled><FontAwesomeIcon icon={regular("square")} /> <span>Agreement</span></Link></li>
                        </ul>
                    </div>
                    <div className="forms-area">
                        <div className="start-form">
                            <h3>Audio & Cover Images</h3>
                            <div className="heading-with-note mt-4">
                                <h4>Cover Images</h4>
                            </div>
                            <div className="cover-details-link">
                                <a href="#">View detailed cover image requirements <FontAwesomeIcon icon={solid("arrow-up-right-from-square")} /></a>
                            </div>

                            <div className="file-up">
                                <Row>
                                    <Col md={4}>
                                        <FileUpload />
                                        <div className="file-infos">
                                            <strong>Square Cover *</strong>
                                            <span>Exactly 2400 pixels x 2400 pixels (H x W)</span>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <FileUpload />
                                        <div className="file-infos">
                                            <strong>Rectangle Cover (Optional)
                                            </strong>
                                            <span>Exactly 680 pixels x 510 pixels (H x W)
                                            </span>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="instruct">
                                            <strong>General Image Requirements</strong>
                                            <ul>
                                                <li>File size: must be less than 5 MB
                                                </li>
                                                <li>Format: must be in JPG format
                                                </li>
                                                <li>Filename: must be alphanumeric
                                                </li>
                                                <li>Resolution: 72 pixels per inch (ppi) minimum
                                                </li>
                                                <li>Color: RBG, 24 bits per pixel minimum
                                                </li>
                                                <li>Contents: Must include title and author name. All content included must match the opening/closing credits track and metadata exactly.
                                                </li>

                                            </ul>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <div className="heading-with-note mt-4">
                                <h4>Audio Files</h4>
                            </div>
                            <div className="cover-details-link">
                                <a href="#">View detailed audio file requirements <FontAwesomeIcon icon={solid("arrow-up-right-from-square")} /></a>
                            </div>
                            <div className="instruct mt-2">
                                <strong>General Audio Requirements</strong>
                                <ul>
                                    <li>MP3 file format</li>
                                    <li>No larger than 170 MB
                                    </li>
                                    <li>Be composed of all mono, or all stereo files
                                    </li>
                                    <li>Contain 1 to 5 seconds of silence at the beginning and end of each track
                                    </li>
                                    <li>Measure between -23dB and -18dB RMS
                                    </li>
                                    <li>Have peak values no higher than -3dB
                                    </li>
                                    <li>Have a noise floor no higher than -60dB RMS
                                    </li>
                                    <li>192 kbps
                                    </li>
                                    <li>Constant Bit Rate (CBR)
                                    </li>
                                    <li>44.1 kHz
                                    </li>

                                </ul>
                            </div>
                            <div className="audio-upload">
                                <h5>Audio Files</h5>
                                <div className="audio-scroll-box">
                                    {/* OPENING TRACK */}
                                    <div className="audio-box">

                                        <Row>
                                            <Col lg={2}>
                                                <h6>Opening Track *</h6>
                                                <Form.Group controlId="openingTrack" className="fileup-btn mb-3">
                                                    <Form.Label>Select file</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        onChange={(e) => handleAudioChange('openingTrack', e.target.files[0])}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={10}>
                                                <div className="track-note">
                                                    <p>Maximum of 3 minutes.
                                                        The track must include the title, author, and narrator. The information within your opening credits track must match your cover art and metadata exactly.
                                                        For example: "This is The Dog Crosses the Road. Written by John Doe, and read for you by Jane Doe".</p>
                                                </div>
                                                {audioFiles.openingTrack?.url && (
                                                    <div className="audio-preview">
                                                        <audio controls src={audioFiles.openingTrack.url}>Your browser does not support the audio tag.</audio>
                                                        <Button variant="danger" onClick={() => handleDeleteAudio('openingTrack')} ><FontAwesomeIcon icon={solid("trash")} /></Button>
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>

                                    </div>
                                    {/* CLOSING TRACK */}
                                    <div className="audio-box">

                                        <Row>
                                            <Col lg={2}>
                                                <h6>Closing Track *</h6>
                                                <Form.Group controlId="closingTrack" className="fileup-btn mb-3">
                                                    <Form.Label>Select file</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        onChange={(e) => handleAudioChange('closingTrack', e.target.files[0])}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={10}>
                                                <div className="track-note">
                                                    <p>Maximum of 3 minutes.
                                                        Must include reference to the book ending (e.g. “The End.”). May include title, author, narrator, and additional credits.
                                                        For example: "You have been listening to The Dog Crosses the Road. Written by John Doe, and read for you by Jane Doe. Published by Fantastic Publishing. Artwork by Fantastic Artwork."</p>
                                                </div>
                                                {audioFiles.closingTrack?.url && (
                                                    <div className="audio-preview">
                                                        <audio controls src={audioFiles.closingTrack.url}>Your browser does not support the audio tag.</audio>
                                                        <Button variant="danger" onClick={() => handleDeleteAudio('closingTrack')} ><FontAwesomeIcon icon={solid("trash")} /></Button>
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>

                                    </div>
                                    {/* SAMPLE TRACK */}
                                    <div className="audio-box">

                                        <Row>
                                            <Col lg={2}>
                                                <h6>Sample Track *</h6>
                                                <Form.Group controlId="sampleTrack" className="fileup-btn mb-3">
                                                    <Form.Label>Select file</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        onChange={(e) => handleAudioChange('sampleTrack', e.target.files[0])}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={10}>
                                                <div className="track-note">
                                                    <p>Maximum of 3 minutes.
                                                        Must include reference to the book ending (e.g. “The End.”). May include title, author, narrator, and additional credits.
                                                        For example: "You have been listening to The Dog Crosses the Road. Written by John Doe, and read for you by Jane Doe. Published by Fantastic Publishing. Artwork by Fantastic Artwork."</p>
                                                </div>
                                                {audioFiles.sampleTrack?.url && (
                                                    <div className="audio-preview">
                                                        <audio controls src={audioFiles.sampleTrack.url}>Your browser does not support the audio tag.</audio>
                                                        <Button variant="danger" onClick={() => handleDeleteAudio('sampleTrack')} ><FontAwesomeIcon icon={solid("trash")} /></Button>
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>

                                    </div>
                                    {/* CHAPTER TRACK */}
                                    <div className="audio-box">
                                        <Row>
                                            <Col lg={2}>
                                                <h6>Chapter Tracks *</h6>
                                                <Form.Group controlId="chapterTrack" className="fileup-btn mb-3">
                                                    <Form.Label>Select file</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        onChange={(e) => handleAudioChange('chapterTrack', e.target.files[0])}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={10}>
                                                <div className="track-note">
                                                    <p>Maximum of 78 minutes each.
                                                        This is the actual content of the audiobook and may include chapters, parts, epilogue, and afterwords.
                                                        Chapters longer than 79 minutes may be broken up over multiple tracks.
                                                        Each track must have a section or chapter announcement at the beginning of each file.
                                                        Chapters must be sorted in the correct order. Pre-naming your files in order may help.</p>
                                                </div>
                                                {audioFiles.chapterTrack?.url && (
                                                    <div className="audio-preview">
                                                        <audio controls src={audioFiles.chapterTrack.url}>Your browser does not support the audio tag.</audio>
                                                        <Button variant="danger" onClick={() => handleDeleteAudio('chapterTrack')} ><FontAwesomeIcon icon={solid("trash")} /></Button>
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>

                                    </div>
                                </div>
                            </div>
                            <div className="heading-with-note mt-4">
                                <h4>Additional Materials (Optional)</h4>
                            </div>
                            <p>Optional single PDF of bonus material. Not all retailers will support this.</p>
                            <div className="instruct mt-2">
                                <strong>General Audio Requirements</strong>
                                <ul>
                                    <li>50 MB maximum.</li>
                                    <li>Supplemental material may include charts, graphs, workbooks, photos or illustrations.</li>
                                    <li>Supplemental material must NOT include your full manuscript, offensive materials, materials unsuitable for all ages.</li>
                                </ul>
                            </div>
                            <Form.Group controlId="up-pdf" className="up-pdf mb-3">
                                <Form.Label>Upload PDF</Form.Label>
                                <Form.Control type="file" />
                            </Form.Group>



                        </div>
                        <div className="setps-options">
                            <Row>
                                <Col>
                                    <div className="lft-side">
                                        <Link to="" className="btn-style black-btn">Cancel</Link>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="ryt-side">
                                        <Link to="/theblaklisted/publish-agreement" className="btn-style yellow-btn">Save & Continue</Link>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
