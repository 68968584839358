import React, { useState } from "react";
import { Col, Container, InputGroup, Form, Row, Button } from "react-bootstrap";
import {
  brands,
  solid,
  regular,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";



export default function Footer() {
  const [expanded, setExpanded] = useState(false);
  const setExpand = () => {
    setExpanded(false);
    window.scrollTo(0, 0);
  };
  return (
    <>
   
    </>
  );
}
