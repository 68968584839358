import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Tabs, Tab, Dropdown } from "react-bootstrap";

// MEDIA
import produceicon from "assets/005-book.png";
import publishicon from "assets/007-book-1.png";

export default function Dashboard() {
  const [expanded, setExpanded] = useState(false);
  const setExpand = () => {
    setExpanded(false);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className='dbody-area'>
        <div className='dpage-heading'>          
          <h1>Create a New Audiobook Project</h1>
        </div>
        <div className='db-content'>
          <div className='start-pro'>
            <Row className='justify-content-center'>
            <Col md={4}>
                <div className='spro-box'>
                  <div className='icon-holder'>
                    <img src={publishicon} alt='icon' />
                  </div>
                    <h4>Publish An Audiobook</h4>
                    <p>Already have an existing audiobook? Upload your finished audiobook files to showcase and sell your audiobook on the BlaklistEd app for free.</p>
                    <Link to='/theblaklisted/publish' className='btn-style yellow-btn'>Get Started</Link>
                  
                </div>
              </Col>
              <Col md={4}>
                <div className='spro-box'>
                  <div className='icon-holder'>
                    <img src={produceicon} alt='icon' />
                  </div>
                    <h4>Produce An Audiobook</h4>
                    <p>Turn your manuscript into an audiobook with us! We will produce your audiobook to showcase and sell on the BlaklistEd app for free.</p>
                    <Link to='/theblaklisted/newaudiobook' className='btn-style yellow-btn'>Get Started</Link>
                  
                </div>
              </Col>
              
            </Row>
          </div>
        </div>
      </div>
    </>
  )
}
