import React, { useState } from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Login() {
    return (
        <>
            <div className="login-page page-margins">
                <Container>
                    
                            <div className="login-form">
                                <div className="sec-head">
                                    <h1>Login</h1>
                                </div>
                                <Form>
                                    <Form.Group className="mb-3" controlId="full-name">
                                        <Form.Label>
                                        Username or email address
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Username or email address"
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="createPass">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" placeholder="Password" />
                                    </Form.Group>
                                    {/* <Button variant="primary" className="btn-style yellow-btn" type="submit">
                                        Sign In
                                    </Button> */}
                                    <Link className="btn-style yellow-btn" to="/theblaklisted/dashboard">
                                        Sign In
                                    </Link>
                                </Form>
                            </div>
                        
                </Container>
            </div>
        </>
    )
}
