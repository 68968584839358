import React, { useState, useEffect } from "react";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Slider from "react-slick";
import { Player } from "video-react";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// MEDIA
import starticon from "assets/start-button-2.png";
import uploadicon from "assets/upload.png";
import narratoricon from "assets/microphone.png";
import showcaseicon from "assets/documents.png";
import produceicon from "assets/audiobook.png";
import sellicon from "assets/money-insurance.png";
import publishicon from "assets/paper-plane.png";

export default function HowItWorks() {
  const [expanded, setExpanded] = useState(false);
  const setExpand = () => {
    setExpanded(false);
    window.scrollTo(0, 0);
  };

  const { section } = useParams(); 
  const [key, setKey] = useState(section);

  useEffect(() => {
    // Update the key (active tab) whenever the route parameter changes
    setKey(section);
  }, [section]);



  return (
    <>
      <div className="hiw-page">
        <Container>
          <div className="sec-head cen-head">
            <h1>How it works</h1>
            <p>Learn how to create and sell your audiobook with BlaklistEd.</p>
            <p>
              Ready to produce a top-quality audiobook with artificial
              intelligence narration technology? Ready to publish your new or
              existing audiobook on the BlaklistEd app to reach millions of
              listeners? Let's go!
            </p>
          </div>

          <Tabs
            defaultActiveKey="produce"
            id="audiobook-creation"
            className="mb-3 hiw-tabs"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab
              eventKey="produce"
              title={
                <>
                  <img src={produceicon} alt="produceicon" />{" "}
                  <span>Produce</span>
                </>
              }
            >
              <div className="steps-area">
                <div className="hiw-tabs-head">
                  <h4>Produce Your Audiobook</h4>
                  <p>Once you provide the input we need, our team will utilize artificial intelligence narration technology to bring your book to life.</p>
                </div>
                <Row className="align-items-center">
                  <Col md={6}>
                    <div className="step-box l-box">
                      <div className="sb-overlay">
                        <span>Step-01</span>
                      </div>
                      <div className="sb-content">
                        <h4>Create an Account</h4>
                        <p>
                          Set up your free account. It's quick and easy
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="step-icon">
                      <img src={starticon} alt="icon" />
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col md={6}>
                    <div className="step-icon">
                      <img src={uploadicon} alt="icon" />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="step-box r-box">
                      <div className="sb-overlay">
                        <span>Step-02</span>
                      </div>
                      <div className="sb-content">
                        <h4>Upload</h4>
                        <p>
                          You will need a manuscript of your book that's ready to be narrated. Upload your manuscript, metadata, and book cover art.
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col md={6}>
                    <div className="step-box r-box rl-style">
                      <div className="sb-overlay">
                        <span>Step-03</span>
                      </div>
                      <div className="sb-content">
                        <h4>Choose Your Narrator</h4>
                        <p>Choose from our selection your favorite A.I. narrator(s) or clone your voice. Once you've chosen, the magic will begin!</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="step-icon">
                      <img src={narratoricon} alt="icon" />
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col md={6}>
                    <div className="step-icon">
                      <img src={showcaseicon} alt="icon" />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="step-box r-box">
                      <div className="sb-overlay">
                        <span>Step-04</span>
                      </div>
                      <div className="sb-content">
                        <h4>Review Your Audiobook</h4>
                        <p>
                          This is the moment you've been waiting for! Review time! Once you review and approve your audiobook, we will upload it to the BlaklistEd app!
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col md={6}>
                    <div className="step-box r-box rl-style">
                      <div className="sb-overlay">
                        <span>Step-05</span>
                      </div>
                      <div className="sb-content">
                        <h4>Showcase and Sell</h4>
                        <p>
                          It's time to showcase your audiobook for the world to enjoy! Your audiobook will live in the BlaklistEd app to be discovered by an eager audience of listeners.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="step-icon">
                      <img src={sellicon} alt="icon" />
                    </div>
                  </Col>
                </Row>

                <Link className="btn-style yellow-btn" to="/register" onClick={setExpand}>
                  Get Started Today
                </Link>
              </div>
            </Tab>
            <Tab
              eventKey="publish"
              title={
                <>
                  <img src={publishicon} alt="publishicon" /> <span>Publish</span>
                </>
              }
            >
              <div className="steps-area">
                <div className="hiw-tabs-head">
                  <h4>Publish Your Audiobook</h4>
                  <p>Showcase and sell your new or existing audiobook on the BlaklistEd app to reach a new audience of listeners.</p>
                </div>
                <Row className="align-items-center">
                  <Col md={6}>
                    <div className="step-box l-box">
                      <div className="sb-overlay">
                        <span>Step-01</span>
                      </div>
                      <div className="sb-content">
                        <h4>Create an Account</h4>
                        <p>
                          Set up your free account. It's quick and easy.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="step-icon">
                      <img src={starticon} alt="icon" />
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col md={6}>
                    <div className="step-icon">
                      <img src={uploadicon} alt="icon" />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="step-box r-box">
                      <div className="sb-overlay">
                        <span>Step-02</span>
                      </div>
                      <div className="sb-content">
                        <h4>Upload</h4>
                        <p>
                          Already have an existing audiobook? You are ahead of the curve. We love it! Go ahead and just upload your metadata, cover art, and audio files and we will handle the rest.
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col md={6}>
                    <div className="step-box r-box rl-style">
                      <div className="sb-overlay">
                        <span>Step-03</span>
                      </div>
                      <div className="sb-content">
                        <h4>Review & Publish</h4>
                        <p>
                          Our incredible team will carefully go through everything for you. If anything comes up, we'll reach out and assist you in sorting it out. When everything aligns with the cover art and audio requirements, you're good to go for publishing on the BlaklistEd app!
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="step-icon">
                      <img src={showcaseicon} alt="icon" />
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col md={6}>
                    <div className="step-icon">
                      <img src={sellicon} alt="icon" />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="step-box r-box">
                      <div className="sb-overlay">
                        <span>Step-04</span>
                      </div>
                      <div className="sb-content">
                        <h4>Royalties</h4>
                        <p>
                          You'll receive 50% of royalties earned by your audiobook. Your audiobook will be available to reach millions of listeners through the BlaklistEd app.
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>


                <Link className="btn-style yellow-btn" to="/register" onClick={setExpand}>
                  Get Started Today
                </Link>
              </div>
            </Tab>
          </Tabs>
        </Container>
      </div>
    </>
  );
}
