import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Nav, Tabs, Tab, Dropdown, Form, Modal, Button } from "react-bootstrap";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default function NonFiction() {

    // CLONING
    const [narrators, setNarrators] = useState([
        { id: 1, name: '', gender: '', clone: false, publisherName: '' },
    ]);

    const addNarrator = () => {
        const newNarrator = {
            id: narrators.length + 1,
            name: '',
            gender: '',
            clone: false,
            publisherName: '',
        };
        setNarrators([...narrators, newNarrator]);
    };

    const handleChange = (index, field, value) => {
        const newNarrators = narrators.map((narrator, i) => {
            if (i === index) {
                return { ...narrator, [field]: value };
            }
            return narrator;
        });
        setNarrators(newNarrators);
    };

    const handleCloneChange = (index, value) => {
        setNarrators(narrators.map((narrator, i) => {
            if (i === index) {
                return { ...narrator, clone: value === 'Yes' };
            }
            return narrator;
        }));
    };

    const deleteNarrator = (indexToDelete) => {
        setNarrators(narrators.filter((_, index) => index !== indexToDelete));
    };
    return (
        <>
            {narrators.map((narrator, index) => (
                <Row key={narrator.id} className="narr-row">
                    <Button variant="danger" onClick={() => deleteNarrator(index)}>
                        <FontAwesomeIcon icon={solid("trash")} />
                    </Button>
                    <Col md={12}>
                        <Form.Group className="mb-3" controlId={`na-name-${narrator.id}`}>
                            <Form.Label>What is the narrator's name?</Form.Label>
                            <Form.Control
                                type="text"
                                value={narrator.name}
                                onChange={(e) => handleChange(index, 'name', e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId={`na-gender-${narrator.id}`}>
                            <Form.Label>What is the narrator's gender?</Form.Label>
                            <Form.Control
                                type="text"
                                value={narrator.gender}
                                onChange={(e) => handleChange(index, 'gender', e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId={`cloning-voice-${narrator.id}`}>
                            <Form.Label>Are you cloning your voice (and/or someone else's) to be narrator?</Form.Label>
                            <div className="mb-3">
                                <Form.Check
                                    inline
                                    label="Yes"
                                    name={`clone-${narrator.id}`}
                                    type="radio"
                                    id={`clone-yes-${narrator.id}`}
                                    onChange={() => handleCloneChange(index, 'Yes')}
                                    checked={narrator.clone === true}
                                />
                                <Form.Check
                                    inline
                                    label="No"
                                    name={`clone-${narrator.id}`}
                                    type="radio"
                                    id={`clone-no-${narrator.id}`}
                                    onChange={() => handleCloneChange(index, 'No')}
                                    checked={narrator.clone === false}
                                />
                            </div>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId={`publisher-name-${narrator.id}`}>
                            <Form.Label>
                                {narrator.clone
                                    ? (
                                        <>
                                            Record your voice or someone else's voice (with permission) acting as the narrator for at least 2 minutes, reading anything (preferably your book), in a very quiet space with no resonance (ex., record in a closet full of clothing).
                                            <br /><br />
                                            If you have multiple narrators where you or other people's voices (with permission) will be acting as different narrators, repeat this process by answering the above 3 questions again for each narrator and submit a separate voice file for each narrator.
                                            <br /><br />
                                            When reading aloud, be mindful of voice clarity, reading pace, and background noises (even as faint as an HVAC blowing thru the wall vents). Artificial Intelligence (A.I.) takes everything it hears into consideration when cloning a voice. Once completed, upload the voice file in MP3 format.
                                        </>
                                    )
                                    : (<>Create a free account at <a href="https://elevenlabs.io/" target="_blank">ElevenLabs</a>. Go to "Voice Library" and select a voice for the narrator. Please enter the voice name and title description below (example: Marcus - Strong Gamer).</>)}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                onChange={(e) => handleChange(index, 'voiceFile', e.target.files[0])}
                                accept=".mp3" // Optional: Specify accepted file types if needed
                                required
                                className="mb-2"
                            />
                            <p>If you have multiple narrators, repeat this process by clicking the button "Add New Narrator"</p>

                        </Form.Group>
                    </Col>
                </Row>
            ))}
            <Button className="btn-style yellow-btn" onClick={addNarrator}>Add New Narrator</Button>
        </>
    )
}
