import React, { useState } from 'react'
import SignatureField from '../SignatureField'
import { Row, Col, Form } from 'react-bootstrap'
import RadioButtonGroup from '../RadioButtonGroup';

export default function W8EXPComponent() {
  const [entity, setentity] = useState('');
  const [FATCAStatus, setFATCAStatus] = useState('');
  const entityOptions = [
    { label: 'Foreign Government', value: 'Foreign Government' },
    { label: 'International Organization', value: 'International Organization' },
    { label: 'Foreign Central Bank of Issue (not wholly owned by the foreign sovereign)', value: 'Foreign Central Bank of Issue (not wholly owned by the foreign sovereign)' },
    { label: 'Foreign Tax-Exempt Organization', value: 'Foreign Tax-Exempt Organization' },
    { label: 'Foreign Private Foundation', value: 'Foreign Private Foundation' },
    { label: 'Government of a U.S. Possession', value: 'Government of a U.S. Possession' },
  ];
  const FATCAStatusOptions = [
    { label: 'Participating FFI', value: 'Participating FFI' },
    { label: 'Reporting Model 1 FFI', value: 'Reporting Model 1 FFI' },
    { label: 'Reporting Model 2 FFI', value: 'Reporting Model 2 FFI' },
    { label: 'Registered Deemed-Compliant FFI (other than a Reporting Model 1 FFI)', value: 'Registered Deemed-Compliant FFI (other than a Reporting Model 1 FFI)' },
    { label: 'Nonreporting IGA FFI. Complete Part III', value: 'Nonreporting IGA FFI. Complete Part III' },
    { label: 'Territory Financial Institution. Complete Part III', value: 'Territory Financial Institution. Complete Part III' },
    { label: 'International Organization', value: 'International Organization' },
    { label: 'Foreign Government (Including a political subdivision), Government of a U.S. Possession, or Foreign Central Bank of Issue. Complete Part III', value: 'Foreign Government (Including a political subdivision), Government of a U.S. Possession, or Foreign Central Bank of Issue. Complete Part III' },
    { label: 'Exempt Retirement Plan of Foreign Government. Complete Part III', value: 'Exempt Retirement Plan of Foreign Government. Complete Part III' },
    { label: '501(c) Organization. Complete Part III', value: '501(c) Organization. Complete Part III' },
    { label: 'Passive NFFE. Complete Part III', value: 'Passive NFFE. Complete Part III' },
    { label: 'Direct Reporting NFFE', value: 'Direct Reporting NFFE' },
    { label: 'Sponsored Direct Reporting NFFE. Complete Part III', value: 'Sponsored Direct Reporting NFFE. Complete Part III' },
  ];
  return (
    <div>
      <Row>
        <Col md={12} className="top-pop-head">
          <strong>You are responsible for ensuring the accurary and completeness of the tax forms on submission to BlaklistEd</strong>
        </Col>
      </Row>
      <div className='pop-three'>
        <Row>
          <Col md={3}>
            <p>Form W-8EXP</p>
            <span>(Rev. July 2017) Department of Treasury Internal Revenue Service</span>
          </Col>
          <Col md={6}>
            <p>Certificate of Foreign Government or Other Foreign</p>
            <p>Organization for United States Tax Withholding and Reporting</p>
            <p>(For use by foreign governments, international organizations, foreign central banks of issue, foreign tax-exempt organizations, foreign private foundations, and governments of U.S. possessions.) For instructions go to <a href="https://www.irs.gov/forms-pubs/about-form-w-8-exp" target='_blank'>https://www.irs.gov/forms-pubs/about-form-w-8-exp</a></p>
          </Col>
          <Col md={3}>
            <span>OMB No. 1545-1621</span>
          </Col>
        </Row>
      </div>
      <div>
        <p>Do NOT use this form for:</p>
        <ul>
          <li>A foreign government or other foreign organization that is not claiming the applicability of section(s) 115(2), 501(c), 892, 895, or 1443(b) <strong>(use W-8BEN-E or W-8ECI instead)</strong></li>
          <li>A beneficial owner solely claiming foreign status or treaty benefits <strong>(use W-8BEN or W-8BENE instead)</strong></li>
          <li>A foreign partnership or foreign trust <strong>(use W-8BEN-E or W-8IMY instead)</strong></li>
          <li>A person claiming that income is effectively connected with the conduct of a trade or business in the United States <strong>(use W-8ECI instead)</strong></li>
          <li>A person acting as an intermediary <strong>(use W-8IMY instead)</strong></li>
        </ul>
      </div>
      <Form>
        <div className='part-1'>
          <div className='part-heading'>
            <strong>Part I</strong>
            <span>Identification of Beneficial Owner</span>
          </div>
          <div className='part-content'>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-2" controlId="name">
                  <Form.Label>1. Name of Organization</Form.Label>
                  <Form.Control type="text" name='name' />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-2" controlId="countryofincorporationororganization">
                  <Form.Label>2. Country of Incorporation or Organization.</Form.Label>
                  <Form.Control type="text" name='countryofincorporationororganization' />
                </Form.Group>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md={12}>
                <Form.Label>3. Type of Entity.</Form.Label>
              </Col>
              <RadioButtonGroup
                options={entityOptions}
                name="TaxW8EXPType"
                selectedValue={entity}
                onChange={setentity}
                columnClassName="col-md-6"
              />
            </Row>
            <hr />
            <Row>
              <Col md={12}>
                <Form.Label>4. Chapter 4 Status (FATCA Status).</Form.Label>
              </Col>
              <RadioButtonGroup
                options={FATCAStatusOptions}
                name="taxw8expfatcastatus"
                selectedValue={FATCAStatus}
                onChange={setFATCAStatus}
                columnClassName="col-md-6"
              />
            </Row>
            <hr />
            <div>
              <Row>
                <Col md={12}>
                  <Form.Group className="mb-2" controlId="permanentaddress">
                    <Form.Label>5. Permanent Address (street, apt. or suite no., or rural route). Do not use a P.O. box or in-care-of address (other than a registered address)</Form.Label>
                    <Form.Control type="text" name='permanentaddress' placeholder='Permanent Address' />
                  </Form.Group>
                </Col>
                <Col md={8}>
                  <Form.Group className="mb-2" controlId="citystatezip">
                    <Form.Label>City or Town, State or Province. Include postal code where appropriate</Form.Label>
                    <Form.Control type="text" name='citystatezip' placeholder='City, State/Province and Postal Code' />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-2" controlId="country">
                    <Form.Label>Country</Form.Label>
                    <Form.Control type="text" name='country' />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group className="mb-2" controlId="mailingpermanentaddress">
                    <Form.Label>6. Mailing Address (if different from above).</Form.Label>
                    <Form.Control type="text" name='mailingpermanentaddress' placeholder='Permanent Address' />
                  </Form.Group>
                </Col>
                <Col md={8}>
                  <Form.Group className="mb-2" controlId="mailingcitystatezip">
                    <Form.Label>City or Town, State or Province. Include postal code where appropriate</Form.Label>
                    <Form.Control type="text" name='mailingcitystatezip' placeholder='City, State/Province and Postal Code' />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-2" controlId="mailingcountry">
                    <Form.Label>Country</Form.Label>
                    <Form.Control type="text" name='mailingcountry' />
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group className="mb-2" controlId="ustaxidentificationnumber">
                    <Form.Label>7. U.S. TIN, if required</Form.Label>
                    <Form.Control type="password" name='ustaxidentificationnumber' placeholder='US Tax Identification Number' />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-2" controlId="GIIN">
                    <Form.Label>8a. GIIN</Form.Label>
                    <Form.Control minlength="9" maxlength="20" type="text" name='GIIN' placeholder='GIIN' />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-2" controlId="foreigntaxidentificationnumber">
                    <Form.Label>8b. Foreign TIN (see instructions)</Form.Label>
                    <Form.Control type="password" placeholder='Foreign Tax ID' name='foreigntaxidentificationnumber' />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group className="mb-2" controlId="referencenumber">
                    <Form.Label>9. Reference Number(s) (see instructions)</Form.Label>
                    <Form.Control type="password" placeholder='Reference Number(s)' name='referencenumber' />
                  </Form.Group>
                </Col>
              </Row>

            </div>
          </div>
        </div>
        <div className='part-2'>
          <div className='part-heading'>
            <strong>Part II</strong>
            <span>Qualification Statement for Chapter 3 Status</span>
          </div>
          <div className='part-content'>
            <p>10. For a foreign government:</p>
            <ol type="a" className="alpha-list">
              <li>
                <strong className='olli-align'>.</strong>
                <Form.Group className="mb-1" controlId="Chk10a">
                  <Form.Check type="checkbox" label="I certify that the entity identified in Part I is a foreign government within the meaning of section 892 and the payments are within the scope of the exemption granted by section 892" name="Chk10a" />
                </Form.Group>
              </li>
              <li>
                <strong>Check box 10b or box 10c, whichever applies</strong>
                <Form.Group className="mb-1" controlId="Chk10b">
                  <Form.Check type="checkbox" label="The entity identified in Part I is an integral part of the government of" name="Chk10b" />
                </Form.Group>
                <Form.Control type="text" name='Txt10b' />
              </li>
              <li>
                <strong className='olli-align'>.</strong>
                <Form.Group className="mb-1" controlId="Chk10c">
                  <Form.Check type="checkbox" label="The entity identified in Part I is a controlled entity of the government of" name="Chk10c" />
                </Form.Group>
                <Form.Control type="text" name='Txt10c' />
              </li>
            </ol>
            <div>
              <p>11. For an international organization:</p>
              <Form.Group className="mb-1" controlId="Chk11">
                <Form.Check type="checkbox" label="I certify that" name="Chk11" />
              </Form.Group>
              <ul>
                <li>The entity identified in Part I is an international organization within the meaning of section 7701(a)(18), and</li>
                <li>The payments are within the scope of the exemption granted by section 892</li>
              </ul>
            </div>
            <div>
              <p>12. For a foreign central bank of issue (not wholly owned by the foreign sovereign):</p>
              <Form.Group className="mb-1" controlId="Chk12">
                <Form.Check type="checkbox" label="I certify that" name="Chk12" />
              </Form.Group>
              <ul>
                <li>The entity identified in Part I is a foreign central bank of issue,</li>
                <li>The entity identified in Part I does not hold obligations or bank deposits to which this form relates for use in connection with the conduct of a commercial banking function or other commercial activity, and</li>
                <li>The payments are within the scope of the exemption granted by section 895</li>
              </ul>
            </div>
            <div>
              <p>13. For a foreign tax-exempt organization, including foreign private foundations:<br /><strong>Check either box 13a or box 13b</strong></p>

              <ol type="a" className="alpha-list">
                <li>
                  <strong className='olli-align'>.</strong>
                  <Form.Group className="mb-1" controlId="Chk13a">
                    <Form.Check type="checkbox" label="I certify that the entity identified in Part I has been issued a determination letter by the IRS dated" name="Chk13a" />
                  </Form.Group>
                  <Form.Control type="date" name='Txt13a' />
                  <span>that is currently in effect and that concludes that it is an exempt organization described in section 501(c).</span>
                </li>
                <li>
                  <strong className='olli-align'>.</strong>
                  <Form.Group className="mb-1" controlId="Chk13b">
                    <Form.Check type="checkbox" label=" I have attached to this form an opinion from U.S. counsel concluding that the entity identified in Part I is described in section 501(c)." name="Chk13b" />
                  </Form.Group>
                  <Form.Control type="text" name='Txt10c' />
                  <p>For section 501(c)(3) organizations only, check either box 13c or box 13d.</p>
                </li>
                <li>
                  <strong className='olli-align'>.</strong>
                  <Form.Group className="mb-1" controlId="Chk13c">
                    <Form.Check type="checkbox" label="If the determination letter or opinion of counsel concludes that the entity identified in Part I is described in section 501(c)(3), I certify that the organization is not a private foundation described in section 509. I have attached an affidavit of the organization setting forth sufficient facts for the IRS to determine that the organization is not a private foundation because it meets one of the exceptions described in section 509(a)(1), (2), (3), or (4)." name="Chk13c" />
                  </Form.Group>
                </li>
                <li>
                  <strong className='olli-align'>.</strong>
                  <Form.Group className="mb-1" controlId="Chk13d">
                    <Form.Check type="checkbox" label="If the determination letter or opinion of counsel concludes that the entity identified in Part I is described in section 501(c)(3), I certify that the organization is a private foundation described in section 509." name="Chk13d" />
                  </Form.Group>
                </li>
              </ol>
              <div>
                <p>14. For a government of a U.S. possession:</p>
                <Form.Group className="mb-3" controlId="Chk12">
                  <Form.Check type="checkbox" label="I certify that the entity identified in Part I is a government of a possession of the United States, or is a political subdivision thereof, and is claiming the exemption granted by section 115(2)." name="Chk12" />
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
        <div className='part-3'>
          <div className='part-heading'>
            <strong>Part III</strong>
            <span>Qualification Statement for Chapter 4 Status (if required)</span>
          </div>
          <div className='part-content'>
            <div>
              <p>15. For a nonreporting IGA FFI:</p>
              <Form.Group className="mb-1" controlId="Chk15">
                <Form.Check type="checkbox" label="I certify that the entity identified in Part I:" name="Chk15" />
              </Form.Group>
              <ul>
                <li>
                  <strong className='olli-align'>.</strong>
                  <Form.Group className="mb-3" controlId="Txt15_1">
                    <Form.Label>Meets the requirements to be considered a nonreporting financial institution pursuant to an applicable IGA between the United States and</Form.Label>
                    <Form.Control type="text" placeholder="You country" name="Txt15_1" />
                    <span>;</span>
                  </Form.Group>
                </li>
                <li>
                  <Form.Group className="mb-3" controlId="Txt15_2">
                    <Form.Label>Is treated as a</Form.Label>
                    <Form.Control type="text" placeholder="You country" name="Txt15_2" />
                    <span>under the provisions of the applicable IGA (see instructions); and</span>
                  </Form.Group>
                </li>
                <li>
                  <strong className='olli-align'>.</strong>
                  <Form.Group className="mb-3" controlId="Txt15_2">
                    <Form.Label>If you are an FFI treated as a registered deemed-compliant FFI under an applicable Model 2 IGA, provide your GIIN:</Form.Label>
                    <Form.Control type="text" placeholder="GIIN" name="Txt15_3" />
                  </Form.Group>
                </li>
              </ul>
            </div>
            <div>
              <p>16. For a territory financial institution:</p>
              <Form.Group className="mb-3" controlId="Chk16">
                <Form.Check type="checkbox" label=" I certify that the entity identified in Part I is a financial institution (other than an investment entity) that is incorporated or organized under the laws of a possession of the United States." name="Chk16" />
              </Form.Group>
            </div>
            <div>
              <p>17. For a foreign government (including a political subdivision), government of a U.S. possession, or foreign central bank of issue:</p>
              <Form.Group className="mb-3" controlId="Chk17">
                <Form.Check type="checkbox" label=" I certify that the entity identified in Part I is the beneficial owner of the payment and is not engaged in commercial financial activities of a type engaged in by an insurance company, custodial institution, or depository institution with respect to the payments, accounts, or obligations for which this form is submitted (except as permitted in Regulations section 1.1471-6(h)(2))." name="Chk17" />
              </Form.Group>
            </div>

            <div>
              <p>18. For an exempt retirement plan of a foreign government:</p>
              <Form.Group className="mb-3" controlId="Chk18">
                <Form.Check type="checkbox" label="I certify that the entity identified in Part I:" name="Chk18" />
              </Form.Group>

              <ul>
                <li>Is established and sponsored by a foreign government, international organization, central bank of issue, or government of a U.S. possession (each as defined in Regulations section 1.1471-6 or an applicable Model 1 or Model 2 IGA) to provide retirement, disability, or death benefits to beneficiaries or participants that are current or former employees of the sponsor (or persons designated by such employees); or</li>
                <li>Is established and sponsored by a foreign government, international organization, central bank of issue, or government of a U.S. possession (each as defined in Regulations section 1.1471-6 or an applicable Model 1 or Model 2 IGA) to provide retirement, disability, or death benefits to beneficiaries or participants that are not current or former employees of such sponsor, but are in consideration of personal services performed for the sponsor.</li>
              </ul>
            </div>
            <div>
              <p>19. For a 501(c) organization:</p>
              <Form.Group className="mb-3" controlId="Chk19">
                <Form.Check type="checkbox" label="I certify that the entity identified in Part I is an entity described in section 501(c) but is not an insurance company described in section 501(c)(15)." name="Chk19" />
              </Form.Group>
            </div>
            <div>
              <p>20. For a passive NFFE:</p>
              <ol type="a" className="alpha-list">
                <li>
                  <strong className='olli-align'>.</strong>
                  <Form.Group className="mb-1" controlId="Chk20a">
                    <Form.Check type="checkbox" label="I certify that the entity identified in Part I is a foreign entity that is not a financial institution (other than an investment entity organized in a possession of the United States)." name="Chk20a" />
                  </Form.Group>
                  <strong>Check box 20b or 20c, whichever applies.</strong>
                </li>
                <li>
                  <strong className='olli-align'>.</strong>
                  <Form.Group className="mb-1" controlId="Chk20b">
                    <Form.Check type="checkbox" label="I further certify that the entity identified in Part I has no substantial U.S. owners, or" name="Chk20b" />
                  </Form.Group>

                </li>
                <li>
                  <strong className='olli-align'>.</strong>
                  <Form.Group className="mb-1" controlId="Chk20c">
                    <Form.Check type="checkbox" label="I further certify that the entity identified in Part I has provided a statement including the name, address, and TIN of each substantial U.S. owner of the NFFE (see instructions)." name="Chk20c" />
                  </Form.Group>
                </li>
              </ol>

            </div>
            <div>
              <p>21. Name of sponsoring entity:</p>
              <Form.Control type="text" name='Txt21' placeholder='Sponsoring Entity' />
              <Form.Group className="mb-3" controlId="Chk20a">
                <Form.Check type="checkbox" label="I certify that the entity identified in Part I is a direct reporting NFFE that is sponsored by the entity identified in line 21." name="Chk20a" />
              </Form.Group>
            </div>
          </div>

        </div>
        <div className='part-4'>
          <div className='part-heading'>
            <strong>Part IV</strong>
            <span>Certification</span>
          </div>
          <div className='part-content'>
            <p><span>Under penalties of perjury, I declare that I have examined the information on this form and to the best of my knowledge and belief it is true, correct, and complete. I further certify under penalties of perjury that:</span></p>
            <ul>
              <li>The organization for which I am signing is the beneficial owner of the income and other payments to which this form relates,</li>
              <li>The beneficial owner is not a U.S. person,</li>
              <li>For a beneficial owner that is a controlled entity of a foreign sovereign (other than a central bank of issue wholly owned by a foreign sovereign), the beneficial owner is not engaged in commercial activities within or outside the United States, and</li>
              <li>For a beneficial owner that is a central bank of issue wholly owned by a foreign sovereign, the beneficial owner is not engaged in commercial activities within the United States.</li>
            </ul>
            <p><span>Furthermore, I authorize this form to be provided to any withholding agent that has control, receipt, or custody of the payments of which I am the beneficial owner or any withholding agent that can disburse or make payments of the amounts of which I am the beneficial owner.</span></p>

            <Form.Group className="mb-3" controlId="acknowledgesignature">
              <Form.Check type="checkbox" label="I acknowledge that it is my responsibility to ensure the generated tax form is completed accurately and that failure to provide accurate information will result in withheld payments from BlaklistEd until such time as a valid form is produced. I further confirm that I am authorizing the use of my digital signature as provided in this section for the purposes of producing the tax form." required />
            </Form.Group>

            <Form.Group className="mb-2" controlId="PrintName">
              <Form.Label>Print Name</Form.Label>
              <Form.Control type="text" name='PrintName' placeholder='Print Name of Authorized Official'/>
            </Form.Group>

          </div>
        </div>
        <SignatureField>Signature of Authorized Official</SignatureField>


      </Form >
    </div >
  )
}
