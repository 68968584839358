import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  InputGroup,
  Form,
  Button,
  DropdownButton,
  Dropdown,
  Nav,
  NavDropdown,
  Navbar,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  solid,
  regular,
  brands,
} from "@fortawesome/fontawesome-svg-core/import.macro";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Logo from 'assets/logobk.png';



export default function LPheader() {
  const [expanded, setExpanded] = useState(false);
  const setExpand = () => {
    setExpanded(false);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <header className="lp-header">
        <Container>
          <Row className="align-items-center">
            <Col md={5}>
              <div className="lp-logo">
                <Link to="/" onClick={setExpand}>
                  <img src={Logo} alt="logo" />
                </Link>
              </div>
            </Col>
            <Col md={7}>
              <div className="top-btns">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    How it works
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/how-it-works/produce">Produce</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/how-it-works/publish">Publish</Dropdown.Item>
                    
                  </Dropdown.Menu>
                </Dropdown>
                <Link className="btn-style yellow-btn" to="/register" onClick={setExpand}>
                  Sign Up
                </Link>
                <Link className="btn-style black-outline-btn" to="/login" onClick={setExpand}>
                  Login
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </header>
    </>
  );
}
