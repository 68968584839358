import React from 'react'
import SignatureField from '../SignatureField'
import { Row, Col, Form } from 'react-bootstrap'

export default function W8BENComponent() {
  return (
    <div>
      <Row>
        <Col md={12} className="top-pop-head">
          <strong>You are responsible for ensuring the accurary and completeness of the tax forms on submission to BlaklistEd</strong>
        </Col>
      </Row>

      <div className='pop-three'>
        <Row>
          <Col md={3}>
            <p>Form W-8BEN</p>
            <span>(Rev. July 2017) Department of Treasury Internal Revenue Service</span>
          </Col>
          <Col md={6}>
            <p>Certificate of Foreign Status of Beneficial Owner for United States Tax Withholding and Reporting (Individuals)</p>
            <p>For use by individuals. Entities must use Form W-8BEN-E.</p>
            <p>Go to <a href='https://www.irs.gov/forms-pubs/about-form-w-8-ben' target='_blank'>www.irs.gov/FormW8BEN</a> for instructions and the latest information.</p>
            <p>Give this form to the withholding agent or payer. Do not send to the IRS.</p>
          </Col>
          <Col md={3}>
            <span>OMB No. 1545-1621</span>
          </Col>
        </Row>
      </div>

      <div>
        <p>Do NOT use this form if:</p>
        <ul>
          <li>You are NOT an individual (use W-8BEN-E instead)</li>
          <li>You are a U.S. citizen or other U.S. person, including a resident alien individual (use W-9 instead)</li>
          <li>You are a beneficial owner claiming that income is effectively connected with the conduct of trade or business within the U.S. (other than personal services) (use W-8ECI instead)</li>
          <li>You are a beneficial owner who is receiving compensation for personal services performed in the United States (use 8233 or W-4 instead)</li>
          <li>You are a person acting as an intermediary (use W-8IMY instead)</li>
        </ul>
      </div>
      <Form>
        <div className='part-1'>
          <div className='part-heading'>
            <strong>Part I</strong>
            <span>Identification of Beneficial Owner <a href="https://www.irs.gov/forms-pubs/about-form-w-8-ben" target='_blank'>(See Instructions)</a></span>
          </div>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-2" controlId="name-ind">
                <Form.Label>1. Name of Individual who is the beneficial owner</Form.Label>
                <Form.Control type="text" />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-2" controlId="country-citizenship">
                <Form.Label>2. Country of Citizenship</Form.Label>
                <Form.Control type="text" />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-2" controlId="pr-address">
                <Form.Label>3. Permanent residence address (street, apt. or suite no., or rural route). Do not use a P.O. box or in-case-of address.</Form.Label>
                <Form.Control type="text" />
              </Form.Group>
              <Form.Label>City or town, state or province, include postal code where appropriate</Form.Label>
              <Form.Group className="mb-1" controlId="pr-city">
                <Form.Control type="text" placeholder='City' />
              </Form.Group>
              <Form.Group className="mb-1" controlId="pr-state">
                <Form.Control type="text" placeholder='State/Province' />
              </Form.Group>
              <Form.Group className="mb-2" controlId="pr-zip">
                <Form.Control type="text" placeholder='ZIP Code/Postal Code' />
              </Form.Group>
              <Form.Group className="mb-2" controlId="pr-country">
                <Form.Label>Country</Form.Label>
                <Form.Control type="text" placeholder='Country' />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-2" controlId="mailing-address">
                <Form.Label>4. Mailing address (if different from your permanent residence address in item #3)</Form.Label>
                <Form.Control type="text" />
              </Form.Group>
              <Form.Label>City or town, state or province, include postal code where appropriate</Form.Label>

              <Form.Group className="mb-1" controlId="mailing-city">
                <Form.Control type="text" placeholder='City' />
              </Form.Group>
              <Form.Group className="mb-1" controlId="mailing-state">
                <Form.Control type="text" placeholder='State/Province' />
              </Form.Group>
              <Form.Group className="mb-2" controlId="mailing-zip">
                <Form.Control type="text" placeholder='ZIP Code/Postal Code' />
              </Form.Group>
              <Form.Group className="mb-2" controlId="mailing-country">
                <Form.Label>Country</Form.Label>
                <Form.Control type="text" placeholder='Country' />
              </Form.Group>
            </Col>
            <Col md={12}>
              <p className='mb-3'><a href='https://www.irs.gov/forms-pubs/about-form-w-8-ben'>(See Instructions)</a></p>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-2" controlId="ssn-itin">
                <Form.Label>5. U.S. taxpayer identification number, if required</Form.Label>
                <Form.Control type="text" placeholder='SSN or ITIN' />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-2" controlId="ref-num">
                <Form.Label>7. Reference number(s)</Form.Label>
                <Form.Control type="text" />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-2" controlId="foreign-tax">
                <Form.Label>6. Foreign tax identifying number</Form.Label>
                <Form.Control type="text" placeholder='Foreign Tax ID' />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-2" controlId="foreign-tax">
                <Form.Label>8. Date of birth</Form.Label>
                <Form.Control type="date" />
              </Form.Group>
            </Col>

          </Row>

        </div>
        <div className='part-2'>
          <div className='part-heading'>
            <strong>Part II</strong>
            <span>Claim of Tax Treaty Benefits (for chapter 3 purposes only) <a href="https://www.irs.gov/forms-pubs/about-form-w-8-ben" target='_blank'>(See Instructions)</a></span>
          </div>
          <div className='part-content'>
            <Row>
              <Col md={12}>
                <Form.Group className="mb-2" controlId="owner-resident">
                  <Form.Label>9. I certify that the beneficial owner is a resident of</Form.Label>
                  <Form.Control type="text" placeholder='country' />
                  <p>within the meaning of the income tax treaty between the United States and that country.</p>
                </Form.Group>

              </Col>
              <Col md={12}>
                <Form.Label>10. Special rates and conditions if applicable -- <a href="https://www.irs.gov/forms-pubs/about-form-w-8-ben">(See Instructions)</a>: The beneficial owner is claiming the provisions of Article and Paragraph</Form.Label>
                <Form.Group className="mb-1" controlId="articalparagraph">
                  <Form.Control type="text" />
                </Form.Group>
                <span>of the treaty identified on line #9 above to claim a</span>
                <Form.Group className="mb-1" controlId="claimpercent">
                  <Form.Control type="number" />
                </Form.Group>
                <span>% rate of withholding on (specify type of income):</span>
                <Form.Group className="mb-2" controlId="typeofincome">
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="mb-2" controlId="explainconditions">
                  <Form.Label>Explain the additional conditions in the Article and paragraph the beneficial owner meets to be eligible for the rate of withholding:</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </div>
        <div className='part-3'>
          <div className='part-heading'>
            <strong>Part III</strong>
            <span>Certification</span>
          </div>
          <div className='part-content'>
            <p>Under penalties of perjury, I declare that I have examined the information on this form and to the best of my knowledge and belief it is true, correct, and complete. I further certify under penalties of perjury that:</p>
            <ul>
              <li>I am the individual that is the beneficial owner (or am authorized to sign for the individual that is the beneficial owner) of all the income to which this form relates or am using this form to document myself for chapter 4 purposes.</li>
              <li>The person named on line 1 of this form is not a U.S. person,</li>
              <li>The income to which this form relates is:
                <ul>
                  <li>not effectively connected with the conduct of a trade or business in the United States,</li>
                  <li>effectively connected but is not subject to tax under an applicable income tax treaty, or</li>
                  <li>the partner's share of a partnership's effectively connected income,</li>
                </ul>
              </li>
              <li>The person named on line 1 of this form is a resident of the treaty country listed on line 9 of the form (if any) within the meaning of the income tax treaty between the United States and that country, and</li>
              <li>For broker transactions or barter exchanges, the beneficial owner is an exempt foreign person as defined in the instructions.</li>
            </ul>
            <p>Furthermore, I authorize this form to be provided to any withholding agent that has control, receipt, or custody of the income of which I am the beneficial owner or any withholding agent that can disburse or make payments of the income of which I am the beneficial owner. <strong>I agree that I will submit a new form within 30 days if any certification made on this form becomes incorrect.</strong></p>
            <hr />
            <Row>
              <Col md={6}>
                <Form.Group className="mb-2" controlId="signername">
                  <Form.Label>Print name of signer</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-2" controlId="signercapacity">
                  <Form.Label>Capacity in which acting (if form is not signed by beneficial owner)</Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="mb-3" controlId="acknowledgesignature">
                  <Form.Check type="checkbox" label="I acknowledge that it is my responsibility to ensure the generated tax form is completed accurately and that failure to provide accurate information will result in withheld payments from BlaklistEd until such time as a valid form is produced. I further confirm that I am authorizing the use of my digital signature as provided in this section for the purposes of producing the tax form." />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </div>
      </Form>
      <SignatureField>Signature of beneficial owner (or individual authorized to sign for beneficial owner)</SignatureField>

    </div>

  )
}
