
import { 
  BrowserRouter, 
  Routes, 
  Route,
  Navigate,
  useLocation, 
} from "react-router-dom";
import Home from "pages/Home";
import "./App.css";
import Header from "components/Header";
import About from "pages/About";
import Signup from "pages/Signup";
import LPheader from "components/LPheader";
import Footer from "components/Footer";
import HowItWorks from "pages/HowItWorks";
import Register from "pages/Register";
import Login from "pages/Login";
import MainApp from "theblaklisted/MainApp"

// Define a custom hook for determining the header
function useCustomHeader() {
  const location = useLocation();

  const isMainAppPath = ["/", "/register", "/login", "/how-it-works", "/signup"].includes(location.pathname);
  const isSubAppPath = location.pathname.startsWith("/theblaklisted");

  if (isMainAppPath) {
      return <LPheader />;
  } else if (isSubAppPath) {
      return null; // or your specific header for sub-app
  } else {
      return <LPheader />;
  }
}

function App() {
  const CustomHeader = useCustomHeader; // Use the hook here

  return (
    <BrowserRouter>
      <header className="pageheader">
        <CustomHeader />
      </header>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/how-it-works/:section" element={<HowItWorks />} />
        <Route path="/how-it-works" element={<Navigate replace to="/how-it-works/produce" />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/about" element={<About />} />        
        <Route path="/signup" element={<Signup />} />
        <Route path="/theblaklisted/*" element={<MainApp />} />       
      </Routes>
      <footer>
        <Footer />
      </footer>
    </BrowserRouter>
  );
}

export default App;
