import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Nav, Tabs, Tab, Dropdown, Form, Modal, Button } from "react-bootstrap";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NonFiction from "theblaklisted/components/NonFiction";
import Fiction from "theblaklisted/components/Fiction";

export default function NarratorDetails() {

    const [expanded, setExpanded] = useState(false);
    const setExpand = () => {
        setExpanded(false);
        window.scrollTo(0, 0);
    };

    return (
        <>
            <div className='dbody-area'>
                <div className='dpage-heading'>
                    <h1>Narrator/Character Details</h1>
                </div>
                <div className='db-content'>
                    <div className="top-checks">
                        <ul>
                            <li><Link className="step-done" to='/theblaklisted/newaudiobook'><FontAwesomeIcon icon={solid("square-check")} /> <span>Get Started</span></Link></li>

                            <li><Link className="step-done" to='/theblaklisted/audiobookdetails'><FontAwesomeIcon icon={solid("square-check")} /> <span>Audiobook Details</span></Link></li>

                            <li><Link to='/theblaklisted/narratordetails'><FontAwesomeIcon icon={regular("square")} /> <span>Narrator/Character Details</span></Link></li>

                            <li><Link to='/theblaklisted/newaudiobook' disabled><FontAwesomeIcon icon={regular("square")} /> <span>Agreement</span></Link></li>

                        </ul>
                    </div>

                    <div className="forms-area">
                        <div className="start-form">
                            <Row>
                                <Col md={8}>
                                    <div className="book-selection">
                                        <p>What category is your book?</p>
                                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                            <Row>
                                                <Col sm={3}>
                                                    <Nav variant="pills" className="flex-column">
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="first">Fiction Book</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="second">Nonfiction Book</Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>
                                                </Col>
                                                <Col sm={9}>
                                                    <Tab.Content>
                                                        <Tab.Pane eventKey="first">
                                                            <Fiction />
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="second">
                                                            <NonFiction />
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </Col>
                                            </Row>
                                        </Tab.Container>
                                    </div>
                                </Col>
                            </Row>


                        </div>

                        <div className="setps-options">
                            <Row>
                                <Col>
                                    <div className="lft-side">
                                        <Link to="" className="btn-style black-btn">Cancel</Link>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="ryt-side">
                                        <Link to="/theblaklisted/audiobookdetails" className="btn-style yellow-btn">Submit</Link>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
