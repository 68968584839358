import React, { useState } from 'react';
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FileUpload = () => {
    const [previewSrc, setPreviewSrc] = useState('');
    const [isHovering, setIsHovering] = useState(false);
  
    const handleFileChange = (event) => {
      const file = event.target.files[0];
      if (file && file.type.substr(0, 5) === "image") {
        const reader = new FileReader();
        
        reader.onloadend = () => {
          setPreviewSrc(reader.result);
        };
  
        reader.readAsDataURL(file);
      } else {
        setPreviewSrc('');
      }
    };
  
    return (
      <label
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}        
      >
        <input type="file" onChange={handleFileChange} style={{ display: 'none' }} />
        {previewSrc ? (
          <>
            <img src={previewSrc} alt="Preview" style={{ maxWidth: '100%', height: 'auto' }} />
            {isHovering && (
              <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(0,0,0,0.5)',
                color: 'white',
                fontSize: '20px',
              }}>
                Select a file
              </div>
            )}
          </>
        ) : (
          <div className='upload-icon'><FontAwesomeIcon icon={solid("cloud-arrow-up")} /><span>Select a file</span></div>
        )}
      </label>
    );
  };
  
  export default FileUpload;


