import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Button } from 'react-bootstrap';

const SignatureField = ({ children, canvasProps = {}, clearButtonProps = {} }) => {
  const sigCanvas = useRef({});

  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  // Yadi children prop pass kiya gaya hai to wo use karein, nahi to default text
  const label = children || "Signature of U.S. person";

  return (
    <div className='person-sig'>
      <SignatureCanvas
        ref={sigCanvas}
        penColor="black"
        canvasProps={{ className: 'sigCanvas', ...canvasProps }}
      />
      <label>{label}</label>
      <Button variant="secondary" onClick={clearSignature} {...clearButtonProps}>
        Clear Signature
      </Button>
    </div>
  );
};
  
  export default SignatureField;