import React, { useState } from 'react'
import SignatureField from '../SignatureField'
import { Row, Col, Form } from 'react-bootstrap'
import RadioButtonGroup from '../RadioButtonGroup';


export default function W8BENEComponent() {
  const [status, setstatus] = useState('');
  const statusOptions = [
    { label: 'corporation', value: 'corporation' },
    { label: 'disregarded entity', value: 'disregarded entity' },
    { label: 'simple trust', value: 'simple trust' },
    { label: 'grantor trust', value: 'grantor trust' },
    { label: 'complex trust', value: 'complex trust' },
    { label: 'estate', value: 'estate' },
    { label: 'government', value: 'government' },
    { label: 'central bank of issue', value: 'central bank of issue' },
    { label: 'tax-exempt organization', value: 'tax-exempt organization' },
    { label: 'private foundation', value: 'private foundation' },
    { label: 'international organization', value: 'international organization' },
  ];

  const [status4, setstatus4] = useState('');
  const status4Options = [
    { label: 'Nonparticipating FFI (including an FFI related to a Reporting IGA FFI other than a deemed-compliant FFI, participating FFI, or exempt beneficial owner).', value: 'Nonparticipating FFI (including an FFI related to a Reporting IGA FFI other than a deemed-compliant FFI, participating FFI, or exempt beneficial owner).' },
    { label: 'Participating FFI.', value: 'Participating FFI.' },
    { label: 'Reporting Model 1 FFI.', value: 'Reporting Model 1 FFI.' },
    { label: 'Reporting Model 2 FFI.', value: 'Reporting Model 2 FFI.' },
    { label: 'Registered deemed-compliant FFI (other than a reporting Model 1 FFI, sponsored FFI, or nonreporting IGA FFI covered in Part XII). See instructions.', value: 'Registered deemed-compliant FFI (other than a reporting Model 1 FFI, sponsored FFI, or nonreporting IGA FFI covered in Part XII). See instructions.' },
    { label: 'Sponsored FFI. Complete Part IV.', value: 'Sponsored FFI. Complete Part IV.' },
    { label: 'Certified deemed-compliant nonregistering local bank. Complete Part V.', value: 'Certified deemed-compliant nonregistering local bank. Complete Part V.' },
    { label: 'Certified deemed-compliant FFI with only low-value accounts. Complete Part VI.', value: 'Certified deemed-compliant FFI with only low-value accounts. Complete Part VI.' },
    { label: 'Certified deemed-compliant sponsored, closely held investment vehicle. Complete Part VII.', value: 'Certified deemed-compliant sponsored, closely held investment vehicle. Complete Part VII.' },
    { label: 'Certified deemed-compliant limited life debt investment entity. Complete Part VIII.', value: 'Certified deemed-compliant limited life debt investment entity. Complete Part VIII.' },
    { label: 'Certain investment entities that do not maintain financial accounts. Complete Part IX.', value: 'Certain investment entities that do not maintain financial accounts. Complete Part IX.' },

    { label: 'Owner-documented FFI. Complete Part X.', value: 'Owner-documented FFI. Complete Part X.' },
    { label: 'Restricted distributor. Complete Part XI.', value: 'Restricted distributor. Complete Part XI.' },
    { label: 'Nonreporting IGA FFI. Complete Part XII', value: 'Nonreporting IGA FFI. Complete Part XII' },
    { label: 'Foreign government, government of a U.S. possession, or foreign central bank of issue. Complete Part XIII.', value: 'Foreign government, government of a U.S. possession, or foreign central bank of issue. Complete Part XIII.' },
    { label: 'International organization. Complete Part XIV.', value: 'International organization. Complete Part XIV.' },
    { label: 'Exempt retirement plans. Complete Part XV.', value: 'Exempt retirement plans. Complete Part XV.' },

    { label: 'Entity wholly owned by exempt beneficial owners. Complete Part XVI.', value: 'Entity wholly owned by exempt beneficial owners. Complete Part XVI.' },
    { label: 'Territory financial institution. Complete Part XVII.', value: 'Territory financial institution. Complete Part XVII.' },
    { label: 'Excepted nonfinancial group entity. Complete Part XVIII.', value: 'Excepted nonfinancial group entity. Complete Part XVIII.' },
    { label: 'Excepted nonfinancial start-up company. Complete Part XIX.', value: 'Excepted nonfinancial start-up company. Complete Part XIX.' },
    { label: 'Excepted nonfinancial entity in liquidation or bankruptcy. Complete Part XX.', value: 'Excepted nonfinancial entity in liquidation or bankruptcy. Complete Part XX.' },
    { label: '501(c) organization. Complete Part XXI.', value: '501(c) organization. Complete Part XXI.' },
    { label: 'Nonprofit organization. Complete Part XXII.', value: 'Nonprofit organization. Complete Part XXII.' },
    { label: 'Publicly traded NFFE or NFFE affiliate of a publicly traded corporation. Complete Part XXIII.', value: 'Publicly traded NFFE or NFFE affiliate of a publicly traded corporation. Complete Part XXIII.' },
    { label: 'Excepted territory NFFE. Complete Part XXIV.', value: 'Excepted territory NFFE. Complete Part XXIV.' },
    { label: 'Active NFFE. Complete Part XXV.', value: 'Active NFFE. Complete Part XXV.' },
    { label: 'Passive NFFE. Complete Part XXVI.', value: 'Passive NFFE. Complete Part XXVI.' },
    { label: 'Excepted inter-affiliate FFI. Complete Part XXVII.', value: 'Excepted inter-affiliate FFI. Complete Part XXVII.' },
    { label: 'Sponsored direct reporting NFFE. Complete Part XXVIII.', value: 'Sponsored direct reporting NFFE. Complete Part XXVIII.' },
    { label: 'Account that is not a financial account.', value: 'Account that is not a financial account.' },
  ];

  const [FATCA, setFATCA] = useState('');
  const FATCAOptions = [
    { label: 'Branch Treated as nonparticipating FFI.', value: 'Branch Treated as nonparticipating FFI.' },
    { label: 'Participating FFI.', value: 'Participating FFI.' },
    { label: 'Reporting Model 1 FFI.', value: 'Reporting Model 1 FFI.' },
    { label: 'Reporting Model 2 FFI.', value: 'Reporting Model 2 FFI.' },
    { label: 'U.S. Branch.', value: 'U.S. Branch.' }
  ];

  // const [beneficial, setbeneficial] = useState('');
  // const beneficialOptions = [
  //   { label: 'Branch Treated as nonparticipating FFI.', value: 'Branch Treated as nonparticipating FFI.' },
  //   { label: 'Participating FFI.', value: 'Participating FFI.' },
  //   { label: 'Reporting Model 1 FFI.', value: 'Reporting Model 1 FFI.' },
  //   { label: 'Reporting Model 2 FFI.', value: 'Reporting Model 2 FFI.' },
  //   { label: 'U.S. Branch.', value: 'U.S. Branch.' }
  // ];
  return (
    <div>
      <Row>
        <Col md={12} className="top-pop-head">
          <strong>You are responsible for ensuring the accurary and completeness of the tax forms on submission to BlaklistEd</strong>
        </Col>
      </Row>

      <div className='pop-three'>
        <Row>
          <Col md={3}>
            <p>Form W-8BEN-E</p>
            <span>(Rev. July 2017) Department of Treasury Internal Revenue Service</span>
          </Col>
          <Col md={6}>
            <p>Certificate of Status of Beneficial Owner for United States Tax Withholding and Reporting (Entities)</p>
            <p>For use by entities. Individuals must use Form W-8BEN.</p>
            <p>Section references are to the Internal Revenue Code.</p>
            <p>Go to <a href="https://www.irs.gov/pub/irs-pdf/iw8bene.pdf" target='_blank'>www.irs.gov/FormW8BENE for instructions and the latest information</a></p>
            <p>Give this form to the withholding agent or payer. Do not send to the IRS.</p>
          </Col>
          <Col md={3}>
            <span>OMB No. 1545-1621</span>
          </Col>
        </Row>
      </div>

      <div>
        <p>Do NOT use this form for:</p>
        <ul>
          <li>U.S. entity or U.S. citizen or resident <strong>(use W-9 instead)</strong></li>
          <li>A foreign individual <strong>(use W-8BEN (individual) or Form 8233 instead)</strong></li>
          <li>A foreign individual or entity claiming that income is effectively connected with the conduct of trade or business within the U.S. (unless claiming treaty benefits) <strong>(use W-8ECI instead)</strong></li>
          <li>A foreign partnership, a foreign simple trust, or a foreign grantor trust (unless claiming treaty benefits) (<a href="https://www.irs.gov/pub/irs-pdf/iw8bene.pdf" target='_blank'>see instructions</a> for exceptions) <strong>(use W-8IMY instead)</strong></li>
          <li>A foreign government, international organization, foreign central bank of issue, foreign tax-exempt organization, foreign private foundation, or government of a U.S. possession claiming that income is effectively connected U.S. income or that is claiming the applicability of section(s) 115(2), 501(c), 892, 895, or 1443(b) (unless claiming treaty benefits) (see instructions for other exceptions) <strong>(use W-8ECI or W-8EXP instead)</strong></li>
          <li>Any person acting as an intermediary (including a qualified intermediary acting as a qualified derivatives dealer) <strong>(use W-8IMY instead)</strong></li>
        </ul>
      </div>

      <Form>
        <div className='part-1'>
          <div className='part-heading'>
            <strong>Part I</strong>
            <span>Identification of Beneficial Owner</span>
          </div>
          <div className='part-content'>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-2" controlId="nameoforganization">
                  <Form.Label>1. Name of organization that is the beneficial owner</Form.Label>
                  <Form.Control type="text" name='nameoforganization' />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-2" controlId="countryofincorporation">
                  <Form.Label>2. Country of incorporation or organization</Form.Label>
                  <Form.Control type="text" name='countryofincorporation' />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="mb-2" controlId="nameofdisregardedentity">
                  <Form.Label>3. Name of disregarded entity receiving the payment (if applicable, <a href="https://www.irs.gov/pub/irs-pdf/iw8bene.pdf" target='_blank'>see instructions</a>)</Form.Label>
                  <Form.Control type="text" name='nameofdisregardedentity' />
                </Form.Group>
              </Col>
            </Row>
            <div className='chap-status'>
              <Form.Label>4. Chapter 3 Status (entity type) (Must check one box only).</Form.Label>
              <Row>
                <RadioButtonGroup
                  options={statusOptions}
                  name="w8benechapter3status"
                  selectedValue={status}
                  onChange={setstatus}
                />

                <Col md={12}>
                  <Form.Label>If you entered disregarded entity, partnership, simple trust, or grantor trust above, is the entity a hybrid making a treaty claim? If "Yes" complete Part III.</Form.Label>
                  <Form.Check
                    inline
                    name="iscompletepart3true"
                    type="radio"
                    label="Yes"
                    value={true}
                    className='mb-2'
                  />
                  <Form.Check
                    inline
                    name="iscompletepart3true"
                    type="radio"
                    label="No"
                    value={false}
                    className='mb-2'
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Form.Label className='col-12'>5. Chapter 4 Status (FATCA status) (See instructions for details and complete the certification below for the entity's applicable status).</Form.Label>
                <RadioButtonGroup
                  options={status4Options}
                  name="w8benechapter4status"
                  selectedValue={status4}
                  onChange={setstatus4}
                  columnClassName="col-md-6"
                />
              </Row>
              <hr />
              <div>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-2" controlId="permresaddress">
                      <Form.Label>6. Permanent residence address (street, apt. or suite no., or rural route). Do not use a P.O. box or in-care-of address (other than a registered address).</Form.Label>
                      <Form.Control type="text" placeholder='Permanent residence address' name="permresaddress" />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="mb-2" controlId="permrescity">
                      <Form.Label>City or town</Form.Label>
                      <Form.Control type="text" placeholder='City' name="permrescity" />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="mb-2" controlId="permresstateprovince">
                      <Form.Label>State or province</Form.Label>
                      <Form.Control type="text" placeholder='State or province' name="permresstateprovince" />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="mb-2" controlId="permrespostalcode">
                      <Form.Label>Postal code</Form.Label>
                      <Form.Control type="text" placeholder='Postal code' name="permrespostalcode" />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="mb-2" controlId="permrescountry">
                      <Form.Label>Country</Form.Label>
                      <Form.Control type="text" placeholder='Country' name="permrescountry" />
                    </Form.Group>
                  </Col>
                </Row>

              </div>
              <hr />
              <div>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-2" controlId="permresmailingaddress">
                      <Form.Label>7. Mailing address (If different from above).</Form.Label>
                      <Form.Control type="text" placeholder='Mailing address' name="permresmailingaddress" />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="mb-2" controlId="permresmailingcity">
                      <Form.Label>City or town</Form.Label>
                      <Form.Control type="text" placeholder='City' name="permresmailingcity" />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="mb-2" controlId="permresmailingstateprovince">
                      <Form.Label>State or province</Form.Label>
                      <Form.Control type="text" placeholder='State or province' name="permresmailingstateprovince" />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="mb-2" controlId="permresmailingpostalcode">
                      <Form.Label>Postal code</Form.Label>
                      <Form.Control type="text" placeholder='Postal code' name="permresmailingpostalcode" />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="mb-2" controlId="permresmailingcountry">
                      <Form.Label>Country</Form.Label>
                      <Form.Control type="text" placeholder='Country' name="permresmailingcountry" />
                    </Form.Group>
                  </Col>
                </Row>

              </div>
              <hr />
              <div>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-2" controlId="taxpayeridnumber">
                      <Form.Label>8. U.S. taxpayer identification number (TIN), if required</Form.Label>
                      <Form.Control type="text" name="taxpayeridnumber" />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-2" controlId="GIIN">
                      <Form.Label>9a GIIN</Form.Label>
                      <Form.Control type="text" name="GIIN" />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-2" controlId="foreigntin">
                      <Form.Label>9b TIN</Form.Label>
                      <Form.Control type="text" name="foreigntin" />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              <hr />
              <div>
                <Form.Group className="mb-2" controlId="referencenumbers">
                  <Form.Label>Reference number(s) <a href='https://www.irs.gov/pub/irs-pdf/iw8bene.pdf' target='_blank'>(See instructions)</a></Form.Label>
                  <Form.Control type="text" name="referencenumbers" />
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
        <div className='part-2'>
          <span><strong>Note:</strong> Please complete remainder of the form including signing the form in Part XXX.</span>
          <div className='part-heading'>
            <strong>Part II</strong>
            <span>Disregarded Entity or Branch Receiving Payment. (Complete only if a disregarded entity with a GIIN or a branch of an FFI in a country other than the FFI's country of residence. <a href='https://www.irs.gov/pub/irs-pdf/iw8bene.pdf' target='_blank'>See instructions)</a>.</span>
          </div>
          <div className='part-content'>
            <Row>
              <Col md={12}>
                <Form.Label>11. Chapter 4 Status (FATCA status) or disregarded entity or branch receiving payment</Form.Label>
              </Col>
              <RadioButtonGroup
                options={FATCAOptions}
                name="w8benechapter4fatcastatus"
                selectedValue={FATCA}
                onChange={setFATCA}
                columnClassName="col-md-4"
              />
            </Row>

            <div>
              <Row>
                <Col md={12}>
                  <Form.Group className="mb-2" controlId="DisregardedEntityAddress">
                    <Form.Label>12. Address of disregarded entity or branch (street, apt. or suite no., or rural route). Do not use a P.O. box or in-care-of address (other than a registered address).</Form.Label>
                    <Form.Control type="text" placeholder='Address of disregarded entity or branch' name="DisregardedEntityAddress" />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-2" controlId="DisregardedEntityCity">
                    <Form.Label>City or town</Form.Label>
                    <Form.Control type="text" placeholder='City or town' name="DisregardedEntityCity" />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-2" controlId="DisregardedEntityStateProvince">
                    <Form.Label>State or province</Form.Label>
                    <Form.Control type="text" placeholder='State or province' name="DisregardedEntityStateProvince" />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-2" controlId="DisregardedEntityPostalCode">
                    <Form.Label>Postal code</Form.Label>
                    <Form.Control type="text" placeholder='Postal code' name="DisregardedEntityPostalCode" />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-2" controlId="DisregardedEntityCountry">
                    <Form.Label>Country</Form.Label>
                    <Form.Control type="text" placeholder='Country' name="DisregardedEntityCountry" />
                  </Form.Group>
                </Col>
              </Row>

            </div>
            <hr />
            <div>
              <Form.Group className="mb-2" controlId="GIINpt13">
                <Form.Label>13. GIIN (if any)</Form.Label>
                <Form.Control type="text" name="GIINpt13" />
              </Form.Group>
            </div>
          </div>
        </div>
        <div className='part-3'>
          <div className='part-heading'>
            <strong>Part III</strong>
            <span>Claim of Tax Treaty Benefits. (if applicable). (For chapter 3 purposes only).</span>
          </div>
          <div className='part-content'>
            <div>
              <p>14. I certify that (check all that apply):</p>
              <ol type="a" className="alpha-list">
                <li>
                  <strong className='olli-align'>.</strong>
                  <Form.Group className="mb-1" controlId="IsBeneficialOwner">
                    <Form.Check type="checkbox" label="The beneficial owner is a resident of" name="IsBeneficialOwner" />
                  </Form.Group>
                  <Form.Control type="text" name='BeneficialOwnerCountry' />
                  <span>within the meaning of the income tax treaty between the United States and that country.</span>
                  <br/>
                  <br/>
                </li>
                <li>
                <strong className='olli-align'>.</strong>
                <Form.Group className="mb-1" controlId="IsDerivedBeneficialOwner">
                    <Form.Check type="checkbox" label="The beneficial owner derives the item (or items) of income for which the treaty benefits are claimed, and, if applicable, meets the requirements of the treaty provision dealing with limitation on benefits. The following are types of limitation on benefits provisions that may be included in an applicable tax treaty (check only one)" name="IsDerivedBeneficialOwner" />
                  </Form.Group>
                  <a class="see-ins" href="https://www.irs.gov/pub/irs-pdf/iw8bene.pdf" target="_blank">See instructions</a>
                   
                </li>
              </ol>

              <Row>
                <Col md={6}>
                <div className="form-check mb-2">
                  <input className="form-check-input" type="radio" name="W8BENEBeneficialOwnerProvisions" id="bop-radio1" value="BOGovernment"/>
                  <label className="form-check-label" htmlFor="bop-radio1">
                  Government  
                  </label>
                </div>
                </Col>
                <Col md={6}>
                <div className="form-check mb-2">
                  <input className="form-check-input" type="radio" name="W8BENEBeneficialOwnerProvisions" id="bop-radio2" value="BOTaxExemptPensionTrust"/>
                  <label className="form-check-label" htmlFor="bop-radio2">
                  Tax exempt pension trust or pension fund
                  </label>
                </div>
                </Col>
                <Col md={6}>
                <div className="form-check mb-2">
                  <input className="form-check-input" type="radio" name="W8BENEBeneficialOwnerProvisions" id="bop-radio3" value="BOOtherTaxExemptOrganization"/>
                  <label className="form-check-label" htmlFor="bop-radio3">
                  Other tax exempt organization
                  </label>
                </div>
                </Col>
                <Col md={6}>
                <div className="form-check mb-2">
                  <input className="form-check-input" type="radio" name="W8BENEBeneficialOwnerProvisions" id="bop-radio4" value="BOPubliclyTradedCorporation"/>
                  <label className="form-check-label" htmlFor="bop-radio4">
                  Publicly traded corporation
                  </label>
                </div>
                </Col>
                <Col md={6}>
                <div className="form-check mb-2">
                  <input className="form-check-input" type="radio" name="W8BENEBeneficialOwnerProvisions" id="bop-radio5" value="BOSubsidiaryOfPubliclyTraded"/>
                  <label className="form-check-label" htmlFor="bop-radio5">
                  Subsidiary of a publicly traded corporation
                  </label>
                </div>
                </Col>
                <Col md={6}>
                <div className="form-check mb-2">
                  <input className="form-check-input" type="radio" name="W8BENEBeneficialOwnerProvisions" id="bop-radio6" value="BOCompanyMeetsErosionTest"/>
                  <label className="form-check-label" htmlFor="bop-radio6">
                  Company that meets the ownership and base erosion test
                  </label>
                </div>
                </Col>
                <Col md={6}>
                <div className="form-check mb-2">
                  <input className="form-check-input" type="radio" name="W8BENEBeneficialOwnerProvisions" id="bop-radio7" value="BOCompanyThatMeetsDerivativeBenefitsTest"/>
                  <label className="form-check-label" htmlFor="bop-radio7">
                  Company that meets the derivative benefits test
                  </label>
                </div>
                </Col>
                <Col md={6}>
                <div className="form-check mb-2">
                  <input className="form-check-input" type="radio" name="W8BENEBeneficialOwnerProvisions" id="bop-radio8" value="BOCompanyWithIncomeMeetsActiveTradeTest"/>
                  <label className="form-check-label" htmlFor="bop-radio8">
                  Company with an item of income that meets active trade or business test
                  </label>
                </div>
                </Col>
                
                <Col md={6}>
                <div className="form-check mb-2">
                  <input className="form-check-input" type="radio" name="W8BENEBeneficialOwnerProvisions" id="bop-radio9" value="BOFavorableCompetentAuthorityReceived"/>
                  <label className="form-check-label" htmlFor="bop-radio9">
                  Favorable discretionary determination by the U.S. competent authority received
                  </label>
                </div>
                </Col>
                <Col md={6}>
                <div className="form-check mb-2">
                  <input className="form-check-input" type="radio" name="W8BENEBeneficialOwnerProvisions" id="bop-radio10" value="BOOther"/>
                  <label className="form-check-label" htmlFor="bop-radio10">
                  Other (specify Article and Paragraph)
                  </label>
                  <Form.Control type="text" name='BOOtherSpecify' placeholder="Specify Article and Paragraph" />
                </div>
                </Col>
              </Row>
            </div>

          </div>
        </div>
      </Form>

      <SignatureField />
    </div>
  )
}
