import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
// import Slider from "react-slick";
import { Player } from 'video-react';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import 'video-react/dist/video-react.css';

// MEDIA
import demovideo from 'assets/AudiobookCreationAdshort.mp4';

export default function Home() {
  const [expanded, setExpanded] = useState(false);
  const setExpand = () => {
    setExpanded(false);
    window.scrollTo(0, 0);
  };



  return (
    <>
      <div className="banner-top">
        <Container>
          <Row className="align-items-center">
            <Col md={7}>
              <div className="conten-side">
                <h1>Let us <span className="yellow-txt">create</span> your audiobook for <span className="yellow-txt">free!</span></h1>
                <p>You created an incredible book. Let us create an incredible audiobook.</p>
                <div className="we-will">
                  <p>We will:</p>
                  <ul>
                    <li><span><FontAwesomeIcon icon={solid("check")} /></span>Utilize the most advanced artficial intelligence narration technology to create your top-quality audiobook.</li>
                    <li><span><FontAwesomeIcon icon={solid("check")} /></span>Introduce your audiobook to a new set of fans who enjoy listening to books.</li>
                    <li><span><FontAwesomeIcon icon={solid("check")} /></span>Sell your audiobook to listeners in our BlaklistEd app to reach millions of listeners.</li>
                    <li><span><FontAwesomeIcon icon={solid("check")} /></span>Create a new stream of income for you through audiobook royalties.</li>
                  </ul>
                  <div className="btns-area">
                    <Link className="btn-style yellow-btn" to="/register" onClick={setExpand}>
                      Create an account
                    </Link>
                    <Link className="btn-style black-outline-btn" to="/how-it-works" onClick={setExpand}>
                      Learn more
                    </Link>
                  </div>
                  
                </div>
              </div>
            </Col>
            <Col md={5}>
              <div className="vid-side">
                <Player>
                  <source src={demovideo} />
                </Player>
              </div>
            </Col>
            <Col md={12}>
            <p className="sub-txt">BlaklistEd is an app that provides audiobooks for the culturally conscious and those who want to delve into the Black experience. Creating a more empathetic and equitable world.</p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
