
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import './index.css';
import Home from "pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer"
import Dashboard from "./pages/Dashboard";
import { Row, Col, Container } from "react-bootstrap";
import Sidebar from "./components/Sidebar";
import Produce from "./pages/Produce";
import NewAudiobook from "./pages/NewAudiobook";
import AudioBookDetails from "./pages/AudioBookDetails";
import NarratorDetails from "./pages/NarratorDetails";
import Account from "./pages/Account";
import MyAudiobooks from "./pages/MyAudiobooks";
import Publish from "./pages/Publish";
import PublishMetadata from "./pages/PublishMetaData";
import AudioAndCovers from "./pages/AudioAndCovers";
import Agreement from "./pages/Agreement";

function MainApp() {
  return (
    <>
      
      <Header /> 
      
      <Row className="dbody">
        <Col md={3} lg={2} className="sidebar-side">
          <Sidebar/>
        </Col>
        <Col md={9} lg={10} className="content-side">
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/account" element={<Account />} />
            <Route path="/produce" element={<Produce />} />
            <Route path="/newaudiobook" element={<NewAudiobook />} />
            <Route path="/audiobookdetails" element={<AudioBookDetails />} />
            <Route path="/narratordetails" element={<NarratorDetails />} />
            <Route path="/myaudiobooks" element={<MyAudiobooks />} />
            <Route path="/publish" element={<Publish />} />
            <Route path="/publish-metadata" element={<PublishMetadata />} />
            <Route path="/publish-audio-and-covers" element={<AudioAndCovers />} />
            <Route path="/publish-agreement" element={<Agreement />} />
          </Routes>
        </Col>
      </Row>
      
      <Footer />
      
    </>
  );
}

export default MainApp;
