import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// MEDIA


export default function Sidebar() {
  const [expanded, setExpanded] = useState(false);
  const setExpand = () => {
    setExpanded(false);
    window.scrollTo(0, 0);
  };

  return (
    <div className="sidebar-nav">

      <ul class="main-nav">
        <li>
          <NavLink className="" to="dashboard" onClick={setExpand}>
            <FontAwesomeIcon icon={solid("table-cells-large")} />
            <span>Dashboard</span>
          </NavLink>
        </li>
        <li>
          <NavLink className="" to="myaudiobooks" onClick={setExpand}>
            <FontAwesomeIcon icon={solid("book")} />
            <span>My Audiobooks</span>
          </NavLink>
        </li>
        <li>
          <NavLink className="" to="account" onClick={setExpand}>
            <FontAwesomeIcon icon={solid("circle-user")} />
            <span>My Account</span>
          </NavLink>
        </li>
        <li>
          <NavLink className="" to="messages" onClick={setExpand}>
            <FontAwesomeIcon icon={solid("message")} />
            <span>Messages</span>
          </NavLink>
        </li>
      </ul>
    </div>
  )
}
