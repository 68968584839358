import React, { useState } from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Register() {
  const [expanded, setExpanded] = useState(false);
  const setExpand = () => {
    setExpanded(false);
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div className="reg-page  page-margins">
        <Container>
          <Row>
            <Col md={7}>
              <div className="sticky-top content-side">
                <div className="sec-head">
                  <h1>Create and sell audiobooks <span className="yellow-txt">for free!</span></h1>
                </div>
                <p>Sign up to reach millions of listeners.</p>
                <ul>
                  <li>
                    <span>
                      <FontAwesomeIcon icon={solid("check")} />
                    </span>
                    Utilize artificial intelligence to create a top-quality
                    audiobook.
                  </li>
                  <li>
                    <span>
                      <FontAwesomeIcon icon={solid("check")} />
                    </span>
                    Sell your new or existing audiobook on the BlaklistEd app to
                    reach a new audience.
                  </li>
                  <li>
                    <span>
                      <FontAwesomeIcon icon={solid("check")} />
                    </span>
                    Create a new stream of income with audiobook listeners.
                  </li>
                </ul>
                <strong>
                  Create your <span className="yellow-txt">Free</span> account
                  to get started!
                </strong>
              </div>
            </Col>
            <Col md={5}>
              <div className="form-side">
                <Form>
                  <h3>Register for your FREE account!</h3>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      How will you primarily use this account?
                    </Form.Label>
                    {["radio"].map((type) => (
                      <div key={`accfor-${type}`} className="mb-3">
                        <Form.Check
                          type={type}
                          name="accfor"
                          id={`accfor-${type}`}
                          label={`I am an author.`}
                        />

                        <Form.Check
                          name="accfor"
                          type={type}
                          label={` I am a publisher.`}
                          id={`accfor-${type}`}
                        />
                      </div>
                    ))}
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="full-name">
                    <Form.Label>
                      Your Full Name <span>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="First name, Last name"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="full-name">
                    <Form.Label>
                      Email Address <span>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Your email address"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="country-name">
                    <Form.Label>
                      Country <span>*</span>
                    </Form.Label>
                    <Form.Select aria-label="Select Country">
                      <option>--Select--</option>
                      <option value="0">--Select--</option>
                      <option value="233">United States</option>
                      <option value="77">United Kingdom</option>
                      <option value="38">Canada</option>
                      <option value="13">Australia</option>
                      <option value="3">Afghanistan</option>
                      <option value="15">Åland Islands</option>
                      <option value="6">Albania</option>
                      <option value="62">Algeria</option>
                      <option value="11">American Samoa</option>
                      <option value="1">Andorra</option>
                      <option value="8">Angola</option>
                      <option value="5">Anguilla</option>
                      <option value="9">Antarctica</option>
                      <option value="4">Antigua and Barbuda</option>
                      <option value="10">Argentina</option>
                      <option value="7">Armenia</option>
                      <option value="14">Aruba</option>
                      <option value="12">Austria</option>
                      <option value="16">Azerbaijan</option>
                      <option value="32">Bahamas</option>
                      <option value="23">Bahrain</option>
                      <option value="19">Bangladesh</option>
                      <option value="18">Barbados</option>
                      <option value="36">Belarus</option>
                      <option value="20">Belgium</option>
                      <option value="37">Belize</option>
                      <option value="25">Benin</option>
                      <option value="27">Bermuda</option>
                      <option value="33">Bhutan</option>
                      <option value="29">Bolivia, Plurinational State of</option>
                      <option value="30">Bonaire, Sint Eustatius and Saba</option>
                      <option value="17">Bosnia and Herzegovina</option>
                      <option value="35">Botswana</option>
                      <option value="34">Bouvet Island</option>
                      <option value="31">Brazil</option>
                      <option value="106">British Indian Ocean Territory</option>
                      <option value="28">Brunei Darussalam</option>
                      <option value="22">Bulgaria</option>
                      <option value="21">Burkina Faso</option>
                      <option value="24">Burundi</option>
                      <option value="52">Cabo Verde</option>
                      <option value="117">Cambodia</option>
                      <option value="47">Cameroon</option>
                      <option value="124">Cayman Islands</option>
                      <option value="41">Central African Republic</option>
                      <option value="215">Chad</option>
                      <option value="46">Chile</option>
                      <option value="48">China</option>
                      <option value="54">Christmas Island</option>
                      <option value="39">Cocos (Keeling) Islands</option>
                      <option value="49">Colombia</option>
                      <option value="119">Comoros</option>
                      <option value="42">Congo</option>
                      <option value="40">Congo, Democratic Republic of the</option>
                      <option value="45">Cook Islands</option>
                      <option value="50">Costa Rica</option>
                      <option value="44">Cote d’Ivoire</option>
                      <option value="98">Croatia</option>
                      <option value="51">Cuba</option>
                      <option value="53">Curaçao</option>
                      <option value="55">Cyprus</option>
                      <option value="56">Czech Republic</option>
                      <option value="59">Denmark</option>
                      <option value="58">Djibouti</option>
                      <option value="60">Dominica</option>
                      <option value="61">Dominican Republic</option>
                      <option value="63">Ecuador</option>
                      <option value="65">Egypt</option>
                      <option value="210">El Salvador</option>
                      <option value="88">Equatorial Guinea</option>
                      <option value="67">Eritrea</option>
                      <option value="64">Estonia</option>
                      <option value="69">Ethiopia</option>
                      <option value="72">Falkland Islands (Malvinas)</option>
                      <option value="74">Faroe Islands</option>
                      <option value="71">Fiji</option>
                      <option value="70">Finland</option>
                      <option value="75">France</option>
                      <option value="80">French Guiana</option>
                      <option value="175">French Polynesia</option>
                      <option value="216">French Southern Territories</option>
                      <option value="76">Gabon</option>
                      <option value="85">Gambia</option>
                      <option value="79">Georgia</option>
                      <option value="57">Germany</option>
                      <option value="82">Ghana</option>
                      <option value="83">Gibraltar</option>
                      <option value="89">Greece</option>
                      <option value="84">Greenland</option>
                      <option value="78">Grenada</option>
                      <option value="87">Guadeloupe</option>
                      <option value="92">Guam</option>
                      <option value="91">Guatemala</option>
                      <option value="81">Guernsey</option>
                      <option value="86">Guinea</option>
                      <option value="93">Guinea-Bissau</option>
                      <option value="94">Guyana</option>
                      <option value="99">Haiti</option>
                      <option value="96">Heard Island and McDonald Islands</option>
                      <option value="236">Holy See (Vatican City State)</option>
                      <option value="97">Honduras</option>
                      <option value="95">Hong Kong</option>
                      <option value="100">Hungary</option>
                      <option value="109">Iceland</option>
                      <option value="105">India</option>
                      <option value="101">Indonesia</option>
                      <option value="108">Iran, Islamic Republic of</option>
                      <option value="107">Iraq</option>
                      <option value="102">Ireland</option>
                      <option value="104">Isle of Man</option>
                      <option value="103">Israel</option>
                      <option value="110">Italy</option>
                      <option value="112">Jamaica</option>
                      <option value="114">Japan</option>
                      <option value="111">Jersey</option>
                      <option value="113">Jordan</option>
                      <option value="125">Kazakhstan</option>
                      <option value="115">Kenya</option>
                      <option value="118">Kiribati</option>
                      <option value="121">Korea, Democratic People’s Republic of</option>
                      <option value="122">Korea, Republic of</option>
                      <option value="123">Kuwait</option>
                      <option value="116">Kyrgyzstan</option>
                      <option value="126">Lao People’s Democratic Republic</option>
                      <option value="135">Latvia</option>
                      <option value="127">Lebanon</option>
                      <option value="132">Lesotho</option>
                      <option value="131">Liberia</option>
                      <option value="136">Libya</option>
                      <option value="129">Liechtenstein</option>
                      <option value="133">Lithuania</option>
                      <option value="134">Luxembourg</option>
                      <option value="148">Macao</option>
                      <option value="144">Macedonia, the former Yugoslav Republic of</option>
                      <option value="142">Madagascar</option>
                      <option value="156">Malawi</option>
                      <option value="158">Malaysia</option>
                      <option value="155">Maldives</option>
                      <option value="145">Mali</option>
                      <option value="153">Malta</option>
                      <option value="143">Marshall Islands</option>
                      <option value="150">Martinique</option>
                      <option value="151">Mauritania</option>
                      <option value="154">Mauritius</option>
                      <option value="246">Mayotte</option>
                      <option value="157">Mexico</option>
                      <option value="73">Micronesia, Federated States of</option>
                      <option value="139">Moldova, Repubic of</option>
                      <option value="138">Monaco</option>
                      <option value="147">Mongolia</option>
                      <option value="140">Montenegro</option>
                      <option value="152">Montserrat</option>
                      <option value="137">Morocco</option>
                      <option value="159">Mozambique</option>
                      <option value="146">Myanmar</option>
                      <option value="160">Namibia</option>
                      <option value="169">Nauru</option>
                      <option value="168">Nepal</option>
                      <option value="166">Netherlands</option>
                      <option value="161">New Caledonia</option>
                      <option value="171">New Zealand</option>
                      <option value="165">Nicaragua</option>
                      <option value="162">Niger</option>
                      <option value="164">Nigeria</option>
                      <option value="170">Niue</option>
                      <option value="163">Norfolk Island</option>
                      <option value="149">Northern Mariana Islands</option>
                      <option value="167">Norway</option>
                      <option value="172">Oman</option>
                      <option value="178">Pakistan</option>
                      <option value="185">Palau</option>
                      <option value="183">Palestine, State of</option>
                      <option value="173">Panama</option>
                      <option value="176">Papua New Guinea</option>
                      <option value="186">Paraguay</option>
                      <option value="174">Peru</option>
                      <option value="177">Philippines</option>
                      <option value="181">Pitcairn</option>
                      <option value="179">Poland</option>
                      <option value="184">Portugal</option>
                      <option value="182">Puerto Rico</option>
                      <option value="187">Qatar</option>
                      <option value="188">Réunion</option>
                      <option value="189">Romania</option>
                      <option value="191">Russian Federation</option>
                      <option value="192">Rwanda</option>
                      <option value="26">Saint Barthélemy</option>
                      <option value="199">Saint Helena, Ascension and Tristan da Cunha</option>
                      <option value="120">Saint Kitts and Nevis</option>
                      <option value="128">Saint Lucia</option>
                      <option value="141">Saint Martin (French part)</option>
                      <option value="180">Saint Pierre and Miquelon</option>
                      <option value="237">Saint Vincent and the Grenadines</option>
                      <option value="244">Samoa</option>
                      <option value="204">San Marino</option>
                      <option value="209">Sao Tome and Principe</option>
                      <option value="193">Saudi Arabia</option>
                      <option value="205">Senegal</option>
                      <option value="190">Serbia</option>
                      <option value="195">Seychelles</option>
                      <option value="203">Sierra Leone</option>
                      <option value="198">Singapore</option>
                      <option value="211">Sint Maarten (Dutch part)</option>
                      <option value="202">Slovakia</option>
                      <option value="200">Slovenia</option>
                      <option value="194">Solomon Islands</option>
                      <option value="206">Somalia</option>
                      <option value="247">South Africa</option>
                      <option value="90">South Georgia and the South Sandwich Islands</option>
                      <option value="208">South Sudan</option>
                      <option value="68">Spain</option>
                      <option value="130">Sri Lanka</option>
                      <option value="196">Sudan</option>
                      <option value="207">Suriname</option>
                      <option value="201">Svalbard and Jan Mayen</option>
                      <option value="213">Swaziland</option>
                      <option value="197">Sweden</option>
                      <option value="43">Switzerland</option>
                      <option value="212">Syrian Arab Republic</option>
                      <option value="228">Taiwan, Province of China</option>
                      <option value="219">Tajikistan</option>
                      <option value="229">Tanzania, United Republic of</option>
                      <option value="218">Thailand</option>
                      <option value="221">Timor-Leste</option>
                      <option value="217">Togo</option>
                      <option value="220">Tokelau</option>
                      <option value="224">Tonga</option>
                      <option value="226">Trinidad and Tobago</option>
                      <option value="223">Tunisia</option>
                      <option value="225">Turkey</option>
                      <option value="222">Turkmenistan</option>
                      <option value="214">Turks and Caicos Islands</option>
                      <option value="227">Tuvalu</option>
                      <option value="231">Uganda</option>
                      <option value="230">Ukraine</option>
                      <option value="2">United Arab Emirates</option>
                      <option value="232">United States Minor Outlying Islands</option>
                      <option value="234">Uruguay</option>
                      <option value="235">Uzbekistan</option>
                      <option value="242">Vanuatu</option>
                      <option value="238">Venezuela, Bolivarian Republic of</option>
                      <option value="241">Viet Nam</option>
                      <option value="239">Virgin Islands, British</option>
                      <option value="240">Virgin Islands, US</option>
                      <option value="243">Wallis and Futuna</option>
                      <option value="66">Western Sahara</option>
                      <option value="245">Yemen</option>
                      <option value="248">Zambia</option>
                      <option value="249">Zimbabwe</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>
                      Do you have an existing audiobook to distribute? *
                    </Form.Label>
                    {["radio"].map((type) => (
                      <div key={`a-distribute-${type}`} className="mb-3">
                        <Form.Check
                          type={type}
                          name="a-distribute"
                          id={`a-distribute-${type}`}
                          label={`Yes`}
                        />

                        <Form.Check
                          name="a-distribute"
                          type={type}
                          label={`No`}
                          id={`a-distribute-${type}`}
                        />
                      </div>
                    ))}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>
                      Do you have a print or ebook? *
                    </Form.Label>
                    {["radio"].map((type) => (
                      <div key={`print-ebook-${type}`} className="mb-3">
                        <Form.Check
                          type={type}
                          name="print-ebook"
                          id={`print-ebook-${type}`}
                          label={`Yes`}
                        />

                        <Form.Check
                          name="print-ebook"
                          type={type}
                          label={`No`}
                          id={`print-ebook-${type}`}
                        />
                      </div>
                    ))}
                  </Form.Group>

                  <hr />
                  <Form.Group className="mb-3" controlId="createPass">
                    <Form.Label>Password *</Form.Label>
                    <Form.Control type="password" placeholder="Password" />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="confirmPassword">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" />
                  </Form.Group>
                </Form>
                <Link to='/' class="btn-style black-outline-btn">Create Account</Link>
                {/* <Button variant="primary" class="btn-style yellow-btn" type="submit">
                  Create Account
                </Button> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
