import React from 'react';
import { Form, Col } from 'react-bootstrap';

const RadioButtonGroup = ({ options, name, selectedValue, onChange, columnClassName }) => {
  const colSize = columnClassName || 'col-md-4';

  return (
    <>
       {options.map((option, index) => (
        <Col key={index} className={colSize}>  {/* Updated to use colSize */}
          <Form.Check 
            type="radio"
            id={`${name}-${option.value}`}
            name={name}
            label={option.label}
            value={option.value}
            checked={selectedValue === option.value}
            onChange={(e) => onChange(e.target.value)}
            className='mb-2'
          />        
        </Col>
      ))}
    </>
  );
};

export default RadioButtonGroup;
