import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Nav, Tabs, Tab, Dropdown, Form, Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// MEDIA
import userimg from "assets/userimg.png"
import TaxForms from "theblaklisted/components/TaxForms";

export default function Account() {

    const [expanded, setExpanded] = useState(false);
    const setExpand = () => {
        setExpanded(false);
        window.scrollTo(0, 0);
    };

    const [editProfile, setEditProfile] = useState(false);
    const editProfileClose = () => setEditProfile(false);
    const editProfileShow = () => setEditProfile(true);

    // State to hold the selected option value
    const [selectedOption, setSelectedOption] = useState("10");

    // Function to handle option selection
    const handleThrusChange = (event) => {
        setSelectedOption(event.target.value);
    };

    // MEDIA
    const mediaTooltip = (props) => (
        <Tooltip id="mediaTooltip" {...props}>
            A profile photo or logo that represents you or your business
        </Tooltip>
    );

    // SUBSCRIBE
    const subscribeTooltip = (props) => (
        <Tooltip id="subscribeTooltip" {...props}>
            Turning off e-mails will only unsubscribe you from marketing e-mails and newsletters. You will continue to receive e-mails pertaining to your audiobooks and other account activity.
        </Tooltip>
    );

    // THRUSHOLD
    const thrusTooltip = (props) => (
        <Tooltip id="thrusTooltip" {...props}>
            This is the minimum accumulated royalty you would like to earn before payment is sent to you.
        </Tooltip>
    );




    return (
        <>
            <div className='dbody-area'>
                <Row>
                    <Col md={8}>
                        <div className="acc-side">
                            <div className="acc-top">
                                <Row className="align-items-center">
                                    <Col md={7}>
                                        <h1>My Account</h1>
                                    </Col>
                                    <Col md={5}>
                                        <div className="help">
                                            <a href="#">
                                                <FontAwesomeIcon icon={solid("question")} /> Help
                                            </a>
                                        </div>
                                    </Col>
                                </Row>
                                <p>To complete your account, click through and complete each of the three tabs. Including tax forms, government ID and payout information. Please contact info@blaklisted if you have any questions or concerns.</p>
                            </div>
                            <Tabs
                                defaultActiveKey="profile"
                                id="profile"
                                className="mb-3 pob-tabs"
                            >
                                <Tab eventKey="profile" title="My Profile">
                                    <div className="acc-information">
                                        <h4>Account Information</h4>
                                        <Form>
                                            <Form.Group className="mb-3" controlId="username">
                                                <Form.Label>Username</Form.Label>
                                                <Form.Control type="text" placeholder="" name="" />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="penname">
                                                <Form.Label>Pen name</Form.Label>
                                                <Form.Control type="text" placeholder="" name="" />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="emailaddress">
                                                <Form.Label>Email Address</Form.Label>
                                                <Form.Control type="email" placeholder="" name="" />
                                            </Form.Group>
                                            <Form.Group className="mb-3 subs-email" controlId="subs-email">
                                                <Form.Check type="checkbox" label="Subscribe to Emails" />
                                                <OverlayTrigger
                                                    placement="right"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={subscribeTooltip}
                                                >
                                                    <Button variant="link">
                                                        <FontAwesomeIcon icon={solid("circle-info")} />
                                                    </Button>
                                                </OverlayTrigger>
                                            </Form.Group>
                                            <Button className="btn-style yellow-btn">
                                                Save
                                            </Button>

                                            <Modal className="editprofile-modal" show={editProfile} onHide={editProfileClose} size="lg">
                                                <Modal.Header closeButton>
                                                    <Modal.Title>My Rights Holder Profile</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <div className="modal-form">
                                                        <p>This is your rights holder information which will be displayed with any audiobook projects you create in the marketplace.</p>
                                                        <Form>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <div className="media-up">
                                                                        <Form.Group className="mb-3" controlId="book-lang">
                                                                            <Form.Label>
                                                                                Profile Photo
                                                                                <OverlayTrigger
                                                                                    placement="right"
                                                                                    delay={{ show: 250, hide: 400 }}
                                                                                    overlay={mediaTooltip}
                                                                                >
                                                                                    <Button variant="link">
                                                                                        <FontAwesomeIcon icon={solid("circle-info")} />
                                                                                    </Button>
                                                                                </OverlayTrigger>

                                                                            </Form.Label>
                                                                            <Form.Control type="file" />

                                                                        </Form.Group>
                                                                    </div>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <div className="edit-profile-fields">
                                                                        <Row>
                                                                            <Col md={6}>
                                                                                <Form.Group className="mb-3" controlId="firstname">
                                                                                    <Form.Label>First Name</Form.Label>
                                                                                    <Form.Control type="text" placeholder="" name="" />
                                                                                </Form.Group>
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <Form.Group className="mb-3" controlId="lastname">
                                                                                    <Form.Label>Last Name</Form.Label>
                                                                                    <Form.Control type="text" placeholder="" name="" />
                                                                                </Form.Group>
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <Form.Group className="mb-3" controlId="businessname">
                                                                                    <Form.Label>Business Name</Form.Label>
                                                                                    <Form.Control type="text" placeholder="" name="" />
                                                                                </Form.Group>
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <Form.Group className="mb-3" controlId="firstname">
                                                                                    <Form.Label>Country</Form.Label>
                                                                                    <Form.Control type="text" placeholder="" name="" />
                                                                                </Form.Group>
                                                                            </Col>
                                                                            <Col md={12}>

                                                                                <Form.Group className="mb-3" controlId="bio">
                                                                                    <Form.Label>Bio</Form.Label>
                                                                                    <Form.Control type="text" placeholder="" name="" />
                                                                                </Form.Group>
                                                                                <Button className="btn-style yellow-btn">
                                                                                    Save
                                                                                </Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    </div>
                                                </Modal.Body>

                                            </Modal>
                                        </Form>
                                    </div>
                                    <hr />
                                    <div className="change-pass">
                                        <h4>Change Password</h4>
                                        <Form.Group className="mb-3" controlId="oldpass">
                                            <Form.Label>Old Password</Form.Label>
                                            <Form.Control type="password" placeholder="" name="" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="newpass">
                                            <Form.Label>New Password</Form.Label>
                                            <Form.Control type="password" placeholder="" name="" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="confirmpass">
                                            <Form.Label>Confirm Password</Form.Label>
                                            <Form.Control type="password" placeholder="" name="" />
                                        </Form.Group>
                                        <Button className="btn-style yellow-btn">
                                            Update Password
                                        </Button>
                                    </div>
                                </Tab>
                                <Tab eventKey="tax" title="Tax Forms & ID">
                                    <div className="tax-pane">
                                        <TaxForms />
                                    </div>
                                </Tab>
                                <Tab eventKey="payout" title="Payout Info">
                                    <div className="payout-pane">
                                        <h4>Royalty Payout Information</h4>
                                        <p>This is where we’ll send your royalty payments, so make sure it’s correct!</p>
                                        <div className="threshold mb-3">
                                            <div className="threshold-value">
                                                Payout Threshold <span>${selectedOption}.00</span> <OverlayTrigger
                                                    placement="right"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={thrusTooltip}
                                                >
                                                    <Button variant="link">
                                                        <FontAwesomeIcon icon={solid("circle-info")} />
                                                    </Button>
                                                </OverlayTrigger>
                                            </div>
                                            <Form.Select onChange={handleThrusChange}>
                                                <option value="10" selected>$10.00</option>
                                                <option value="15">$15.00</option>
                                                <option value="20">$20.00</option>
                                                <option value="25">$25.00</option>
                                                <option value="30">$30.00</option>
                                                <option value="35">$35.00</option>
                                                <option value="40">$40.00</option>
                                                <option value="45">$45.00</option>
                                                <option value="50">$50.00</option>
                                                <option value="55">$55.00</option>
                                                <option value="60">$60.00</option>
                                                <option value="65">$65.00</option>
                                                <option value="70">$70.00</option>
                                                <option value="75">$75.00</option>
                                                <option value="80">$80.00</option>
                                                <option value="85">$85.00</option>
                                                <option value="90">$90.00</option>
                                                <option value="95">$95.00</option>
                                                <option value="100">$100.00</option>
                                                <option value="105">$105.00</option>
                                                <option value="110">$110.00</option>
                                                <option value="115">$115.00</option>
                                                <option value="120">$120.00</option>
                                                <option value="125">$125.00</option>
                                                <option value="130">$130.00</option>
                                                <option value="135">$135.00</option>
                                                <option value="140">$140.00</option>
                                                <option value="145">$145.00</option>
                                                <option value="150">$150.00</option>
                                                <option value="155">$155.00</option>
                                                <option value="160">$160.00</option>
                                                <option value="165">$165.00</option>
                                                <option value="170">$170.00</option>
                                                <option value="175">$175.00</option>
                                                <option value="180">$180.00</option>
                                                <option value="185">$185.00</option>
                                                <option value="190">$190.00</option>
                                                <option value="195">$195.00</option>
                                                <option value="200">$200.00</option>
                                                <option value="205">$205.00</option>
                                                <option value="210">$210.00</option>
                                                <option value="215">$215.00</option>
                                                <option value="220">$220.00</option>
                                                <option value="225">$225.00</option>
                                                <option value="230">$230.00</option>
                                                <option value="235">$235.00</option>
                                                <option value="240">$240.00</option>
                                                <option value="245">$245.00</option>
                                                <option value="250">$250.00</option>
                                                <option value="255">$255.00</option>
                                                <option value="260">$260.00</option>
                                                <option value="265">$265.00</option>
                                                <option value="270">$270.00</option>
                                                <option value="275">$275.00</option>
                                                <option value="280">$280.00</option>
                                                <option value="285">$285.00</option>
                                                <option value="290">$290.00</option>
                                                <option value="295">$295.00</option>
                                                <option value="300">$300.00</option>
                                                <option value="305">$305.00</option>
                                                <option value="310">$310.00</option>
                                                <option value="315">$315.00</option>
                                                <option value="320">$320.00</option>
                                                <option value="325">$325.00</option>
                                                <option value="330">$330.00</option>
                                                <option value="335">$335.00</option>
                                                <option value="340">$340.00</option>
                                                <option value="345">$345.00</option>
                                                <option value="350">$350.00</option>
                                                <option value="355">$355.00</option>
                                                <option value="360">$360.00</option>
                                                <option value="365">$365.00</option>
                                                <option value="370">$370.00</option>
                                                <option value="375">$375.00</option>
                                                <option value="380">$380.00</option>
                                                <option value="385">$385.00</option>
                                                <option value="390">$390.00</option>
                                                <option value="395">$395.00</option>
                                                <option value="400">$400.00</option>
                                                <option value="405">$405.00</option>
                                                <option value="410">$410.00</option>
                                                <option value="415">$415.00</option>
                                                <option value="420">$420.00</option>
                                                <option value="425">$425.00</option>
                                                <option value="430">$430.00</option>
                                                <option value="435">$435.00</option>
                                                <option value="440">$440.00</option>
                                                <option value="445">$445.00</option>
                                                <option value="450">$450.00</option>
                                                <option value="455">$455.00</option>
                                                <option value="460">$460.00</option>
                                                <option value="465">$465.00</option>
                                                <option value="470">$470.00</option>
                                                <option value="475">$475.00</option>
                                                <option value="480">$480.00</option>
                                                <option value="485">$485.00</option>
                                                <option value="490">$490.00</option>
                                                <option value="495">$495.00</option>
                                                <option value="500">$500.00</option>
                                                <option value="505">$505.00</option>
                                                <option value="510">$510.00</option>
                                                <option value="515">$515.00</option>
                                                <option value="520">$520.00</option>
                                                <option value="525">$525.00</option>
                                                <option value="530">$530.00</option>
                                                <option value="535">$535.00</option>
                                                <option value="540">$540.00</option>
                                                <option value="545">$545.00</option>
                                                <option value="550">$550.00</option>
                                                <option value="555">$555.00</option>
                                                <option value="560">$560.00</option>
                                                <option value="565">$565.00</option>
                                                <option value="570">$570.00</option>
                                                <option value="575">$575.00</option>
                                                <option value="580">$580.00</option>
                                                <option value="585">$585.00</option>
                                                <option value="590">$590.00</option>
                                                <option value="595">$595.00</option>
                                                <option value="600">$600.00</option>
                                                <option value="605">$605.00</option>
                                                <option value="610">$610.00</option>
                                                <option value="615">$615.00</option>
                                                <option value="620">$620.00</option>
                                                <option value="625">$625.00</option>
                                                <option value="630">$630.00</option>
                                                <option value="635">$635.00</option>
                                                <option value="640">$640.00</option>
                                                <option value="645">$645.00</option>
                                                <option value="650">$650.00</option>
                                                <option value="655">$655.00</option>
                                                <option value="660">$660.00</option>
                                                <option value="665">$665.00</option>
                                                <option value="670">$670.00</option>
                                                <option value="675">$675.00</option>
                                                <option value="680">$680.00</option>
                                                <option value="685">$685.00</option>
                                                <option value="690">$690.00</option>
                                                <option value="695">$695.00</option>
                                                <option value="700">$700.00</option>
                                                <option value="705">$705.00</option>
                                                <option value="710">$710.00</option>
                                                <option value="715">$715.00</option>
                                                <option value="720">$720.00</option>
                                                <option value="725">$725.00</option>
                                                <option value="730">$730.00</option>
                                                <option value="735">$735.00</option>
                                                <option value="740">$740.00</option>
                                                <option value="745">$745.00</option>
                                                <option value="750">$750.00</option>
                                                <option value="755">$755.00</option>
                                                <option value="760">$760.00</option>
                                                <option value="765">$765.00</option>
                                                <option value="770">$770.00</option>
                                                <option value="775">$775.00</option>
                                                <option value="780">$780.00</option>
                                                <option value="785">$785.00</option>
                                                <option value="790">$790.00</option>
                                                <option value="795">$795.00</option>
                                                <option value="800">$800.00</option>
                                                <option value="805">$805.00</option>
                                                <option value="810">$810.00</option>
                                                <option value="815">$815.00</option>
                                                <option value="820">$820.00</option>
                                                <option value="825">$825.00</option>
                                                <option value="830">$830.00</option>
                                                <option value="835">$835.00</option>
                                                <option value="840">$840.00</option>
                                                <option value="845">$845.00</option>
                                                <option value="850">$850.00</option>
                                                <option value="855">$855.00</option>
                                                <option value="860">$860.00</option>
                                                <option value="865">$865.00</option>
                                                <option value="870">$870.00</option>
                                                <option value="875">$875.00</option>
                                                <option value="880">$880.00</option>
                                                <option value="885">$885.00</option>
                                                <option value="890">$890.00</option>
                                                <option value="895">$895.00</option>
                                                <option value="900">$900.00</option>
                                                <option value="905">$905.00</option>
                                                <option value="910">$910.00</option>
                                                <option value="915">$915.00</option>
                                                <option value="920">$920.00</option>
                                                <option value="925">$925.00</option>
                                                <option value="930">$930.00</option>
                                                <option value="935">$935.00</option>
                                                <option value="940">$940.00</option>
                                                <option value="945">$945.00</option>
                                                <option value="950">$950.00</option>
                                                <option value="955">$955.00</option>
                                                <option value="960">$960.00</option>
                                                <option value="965">$965.00</option>
                                                <option value="970">$970.00</option>
                                                <option value="975">$975.00</option>
                                                <option value="980">$980.00</option>
                                                <option value="985">$985.00</option>
                                                <option value="990">$990.00</option>
                                                <option value="995">$995.00</option>
                                                <option value="1000">$1000.00</option>
                                                <option value="1005">$1005.00</option>
                                                <option value="1010">$1010.00</option>
                                                <option value="1015">$1015.00</option>
                                                <option value="1020">$1020.00</option>
                                                <option value="1025">$1025.00</option>
                                                <option value="1030">$1030.00</option>
                                                <option value="1035">$1035.00</option>
                                                <option value="1040">$1040.00</option>
                                                <option value="1045">$1045.00</option>
                                                <option value="1050">$1050.00</option>
                                                <option value="1055">$1055.00</option>
                                                <option value="1060">$1060.00</option>
                                                <option value="1065">$1065.00</option>
                                                <option value="1070">$1070.00</option>
                                                <option value="1075">$1075.00</option>
                                                <option value="1080">$1080.00</option>
                                                <option value="1085">$1085.00</option>
                                                <option value="1090">$1090.00</option>
                                                <option value="1095">$1095.00</option>
                                                <option value="1100">$1100.00</option>
                                                <option value="1105">$1105.00</option>
                                                <option value="1110">$1110.00</option>
                                                <option value="1115">$1115.00</option>
                                                <option value="1120">$1120.00</option>
                                                <option value="1125">$1125.00</option>
                                                <option value="1130">$1130.00</option>
                                                <option value="1135">$1135.00</option>
                                                <option value="1140">$1140.00</option>
                                                <option value="1145">$1145.00</option>
                                                <option value="1150">$1150.00</option>
                                                <option value="1155">$1155.00</option>
                                                <option value="1160">$1160.00</option>
                                                <option value="1165">$1165.00</option>
                                                <option value="1170">$1170.00</option>
                                                <option value="1175">$1175.00</option>
                                                <option value="1180">$1180.00</option>
                                                <option value="1185">$1185.00</option>
                                                <option value="1190">$1190.00</option>
                                                <option value="1195">$1195.00</option>
                                                <option value="1200">$1200.00</option>
                                                <option value="1205">$1205.00</option>
                                                <option value="1210">$1210.00</option>
                                                <option value="1215">$1215.00</option>
                                                <option value="1220">$1220.00</option>
                                                <option value="1225">$1225.00</option>
                                                <option value="1230">$1230.00</option>
                                                <option value="1235">$1235.00</option>
                                                <option value="1240">$1240.00</option>
                                                <option value="1245">$1245.00</option>
                                                <option value="1250">$1250.00</option>
                                                <option value="1255">$1255.00</option>
                                                <option value="1260">$1260.00</option>
                                                <option value="1265">$1265.00</option>
                                                <option value="1270">$1270.00</option>
                                                <option value="1275">$1275.00</option>
                                                <option value="1280">$1280.00</option>
                                                <option value="1285">$1285.00</option>
                                                <option value="1290">$1290.00</option>
                                                <option value="1295">$1295.00</option>
                                                <option value="1300">$1300.00</option>
                                                <option value="1305">$1305.00</option>
                                                <option value="1310">$1310.00</option>
                                                <option value="1315">$1315.00</option>
                                                <option value="1320">$1320.00</option>
                                                <option value="1325">$1325.00</option>
                                                <option value="1330">$1330.00</option>
                                                <option value="1335">$1335.00</option>
                                                <option value="1340">$1340.00</option>
                                                <option value="1345">$1345.00</option>
                                                <option value="1350">$1350.00</option>
                                                <option value="1355">$1355.00</option>
                                                <option value="1360">$1360.00</option>
                                                <option value="1365">$1365.00</option>
                                                <option value="1370">$1370.00</option>
                                                <option value="1375">$1375.00</option>
                                                <option value="1380">$1380.00</option>
                                                <option value="1385">$1385.00</option>
                                                <option value="1390">$1390.00</option>
                                                <option value="1395">$1395.00</option>
                                                <option value="1400">$1400.00</option>
                                                <option value="1405">$1405.00</option>
                                                <option value="1410">$1410.00</option>
                                                <option value="1415">$1415.00</option>
                                                <option value="1420">$1420.00</option>
                                                <option value="1425">$1425.00</option>
                                                <option value="1430">$1430.00</option>
                                                <option value="1435">$1435.00</option>
                                                <option value="1440">$1440.00</option>
                                                <option value="1445">$1445.00</option>
                                                <option value="1450">$1450.00</option>
                                                <option value="1455">$1455.00</option>
                                                <option value="1460">$1460.00</option>
                                                <option value="1465">$1465.00</option>
                                                <option value="1470">$1470.00</option>
                                                <option value="1475">$1475.00</option>
                                                <option value="1480">$1480.00</option>
                                                <option value="1485">$1485.00</option>
                                                <option value="1490">$1490.00</option>
                                                <option value="1495">$1495.00</option>
                                                <option value="1500">$1500.00</option>
                                                <option value="1505">$1505.00</option>
                                                <option value="1510">$1510.00</option>
                                                <option value="1515">$1515.00</option>
                                                <option value="1520">$1520.00</option>
                                                <option value="1525">$1525.00</option>
                                                <option value="1530">$1530.00</option>
                                                <option value="1535">$1535.00</option>
                                                <option value="1540">$1540.00</option>
                                                <option value="1545">$1545.00</option>
                                                <option value="1550">$1550.00</option>
                                                <option value="1555">$1555.00</option>
                                                <option value="1560">$1560.00</option>
                                                <option value="1565">$1565.00</option>
                                                <option value="1570">$1570.00</option>
                                                <option value="1575">$1575.00</option>
                                                <option value="1580">$1580.00</option>
                                                <option value="1585">$1585.00</option>
                                                <option value="1590">$1590.00</option>
                                                <option value="1595">$1595.00</option>
                                                <option value="1600">$1600.00</option>
                                                <option value="1605">$1605.00</option>
                                                <option value="1610">$1610.00</option>
                                                <option value="1615">$1615.00</option>
                                                <option value="1620">$1620.00</option>
                                                <option value="1625">$1625.00</option>
                                                <option value="1630">$1630.00</option>
                                                <option value="1635">$1635.00</option>
                                                <option value="1640">$1640.00</option>
                                                <option value="1645">$1645.00</option>
                                                <option value="1650">$1650.00</option>
                                                <option value="1655">$1655.00</option>
                                                <option value="1660">$1660.00</option>
                                                <option value="1665">$1665.00</option>
                                                <option value="1670">$1670.00</option>
                                                <option value="1675">$1675.00</option>
                                                <option value="1680">$1680.00</option>
                                                <option value="1685">$1685.00</option>
                                                <option value="1690">$1690.00</option>
                                                <option value="1695">$1695.00</option>
                                                <option value="1700">$1700.00</option>
                                                <option value="1705">$1705.00</option>
                                                <option value="1710">$1710.00</option>
                                                <option value="1715">$1715.00</option>
                                                <option value="1720">$1720.00</option>
                                                <option value="1725">$1725.00</option>
                                                <option value="1730">$1730.00</option>
                                                <option value="1735">$1735.00</option>
                                                <option value="1740">$1740.00</option>
                                                <option value="1745">$1745.00</option>
                                                <option value="1750">$1750.00</option>
                                                <option value="1755">$1755.00</option>
                                                <option value="1760">$1760.00</option>
                                                <option value="1765">$1765.00</option>
                                                <option value="1770">$1770.00</option>
                                                <option value="1775">$1775.00</option>
                                                <option value="1780">$1780.00</option>
                                                <option value="1785">$1785.00</option>
                                                <option value="1790">$1790.00</option>
                                                <option value="1795">$1795.00</option>
                                                <option value="1800">$1800.00</option>
                                                <option value="1805">$1805.00</option>
                                                <option value="1810">$1810.00</option>
                                                <option value="1815">$1815.00</option>
                                                <option value="1820">$1820.00</option>
                                                <option value="1825">$1825.00</option>
                                                <option value="1830">$1830.00</option>
                                                <option value="1835">$1835.00</option>
                                                <option value="1840">$1840.00</option>
                                                <option value="1845">$1845.00</option>
                                                <option value="1850">$1850.00</option>
                                                <option value="1855">$1855.00</option>
                                                <option value="1860">$1860.00</option>
                                                <option value="1865">$1865.00</option>
                                                <option value="1870">$1870.00</option>
                                                <option value="1875">$1875.00</option>
                                                <option value="1880">$1880.00</option>
                                                <option value="1885">$1885.00</option>
                                                <option value="1890">$1890.00</option>
                                                <option value="1895">$1895.00</option>
                                                <option value="1900">$1900.00</option>
                                                <option value="1905">$1905.00</option>
                                                <option value="1910">$1910.00</option>
                                                <option value="1915">$1915.00</option>
                                                <option value="1920">$1920.00</option>
                                                <option value="1925">$1925.00</option>
                                                <option value="1930">$1930.00</option>
                                                <option value="1935">$1935.00</option>
                                                <option value="1940">$1940.00</option>
                                                <option value="1945">$1945.00</option>
                                                <option value="1950">$1950.00</option>
                                                <option value="1955">$1955.00</option>
                                                <option value="1960">$1960.00</option>
                                                <option value="1965">$1965.00</option>
                                                <option value="1970">$1970.00</option>
                                                <option value="1975">$1975.00</option>
                                                <option value="1980">$1980.00</option>
                                                <option value="1985">$1985.00</option>
                                                <option value="1990">$1990.00</option>
                                                <option value="1995">$1995.00</option>
                                                <option value="2000">$2000.00</option>
                                                <option value="2005">$2005.00</option>
                                                <option value="2010">$2010.00</option>
                                                <option value="2015">$2015.00</option>
                                                <option value="2020">$2020.00</option>
                                                <option value="2025">$2025.00</option>
                                                <option value="2030">$2030.00</option>
                                                <option value="2035">$2035.00</option>
                                                <option value="2040">$2040.00</option>
                                                <option value="2045">$2045.00</option>
                                                <option value="2050">$2050.00</option>
                                                <option value="2055">$2055.00</option>
                                                <option value="2060">$2060.00</option>
                                                <option value="2065">$2065.00</option>
                                                <option value="2070">$2070.00</option>
                                                <option value="2075">$2075.00</option>
                                                <option value="2080">$2080.00</option>
                                                <option value="2085">$2085.00</option>
                                                <option value="2090">$2090.00</option>
                                                <option value="2095">$2095.00</option>
                                                <option value="2100">$2100.00</option>
                                                <option value="2105">$2105.00</option>
                                                <option value="2110">$2110.00</option>
                                                <option value="2115">$2115.00</option>
                                                <option value="2120">$2120.00</option>
                                                <option value="2125">$2125.00</option>
                                                <option value="2130">$2130.00</option>
                                                <option value="2135">$2135.00</option>
                                                <option value="2140">$2140.00</option>
                                                <option value="2145">$2145.00</option>
                                                <option value="2150">$2150.00</option>
                                                <option value="2155">$2155.00</option>
                                                <option value="2160">$2160.00</option>
                                                <option value="2165">$2165.00</option>
                                                <option value="2170">$2170.00</option>
                                                <option value="2175">$2175.00</option>
                                                <option value="2180">$2180.00</option>
                                                <option value="2185">$2185.00</option>
                                                <option value="2190">$2190.00</option>
                                                <option value="2195">$2195.00</option>
                                                <option value="2200">$2200.00</option>
                                                <option value="2205">$2205.00</option>
                                                <option value="2210">$2210.00</option>
                                                <option value="2215">$2215.00</option>
                                                <option value="2220">$2220.00</option>
                                                <option value="2225">$2225.00</option>
                                                <option value="2230">$2230.00</option>
                                                <option value="2235">$2235.00</option>
                                                <option value="2240">$2240.00</option>
                                                <option value="2245">$2245.00</option>
                                                <option value="2250">$2250.00</option>
                                                <option value="2255">$2255.00</option>
                                                <option value="2260">$2260.00</option>
                                                <option value="2265">$2265.00</option>
                                                <option value="2270">$2270.00</option>
                                                <option value="2275">$2275.00</option>
                                                <option value="2280">$2280.00</option>
                                                <option value="2285">$2285.00</option>
                                                <option value="2290">$2290.00</option>
                                                <option value="2295">$2295.00</option>
                                                <option value="2300">$2300.00</option>
                                                <option value="2305">$2305.00</option>
                                                <option value="2310">$2310.00</option>
                                                <option value="2315">$2315.00</option>
                                                <option value="2320">$2320.00</option>
                                                <option value="2325">$2325.00</option>
                                                <option value="2330">$2330.00</option>
                                                <option value="2335">$2335.00</option>
                                                <option value="2340">$2340.00</option>
                                                <option value="2345">$2345.00</option>
                                                <option value="2350">$2350.00</option>
                                                <option value="2355">$2355.00</option>
                                                <option value="2360">$2360.00</option>
                                                <option value="2365">$2365.00</option>
                                                <option value="2370">$2370.00</option>
                                                <option value="2375">$2375.00</option>
                                                <option value="2380">$2380.00</option>
                                                <option value="2385">$2385.00</option>
                                                <option value="2390">$2390.00</option>
                                                <option value="2395">$2395.00</option>
                                                <option value="2400">$2400.00</option>
                                                <option value="2405">$2405.00</option>
                                                <option value="2410">$2410.00</option>
                                                <option value="2415">$2415.00</option>
                                                <option value="2420">$2420.00</option>
                                                <option value="2425">$2425.00</option>
                                                <option value="2430">$2430.00</option>
                                                <option value="2435">$2435.00</option>
                                                <option value="2440">$2440.00</option>
                                                <option value="2445">$2445.00</option>
                                                <option value="2450">$2450.00</option>
                                                <option value="2455">$2455.00</option>
                                                <option value="2460">$2460.00</option>
                                                <option value="2465">$2465.00</option>
                                                <option value="2470">$2470.00</option>
                                                <option value="2475">$2475.00</option>
                                                <option value="2480">$2480.00</option>
                                                <option value="2485">$2485.00</option>
                                                <option value="2490">$2490.00</option>
                                                <option value="2495">$2495.00</option>
                                                <option value="2500">$2500.00</option>
                                                <option value="2505">$2505.00</option>
                                                <option value="2510">$2510.00</option>
                                                <option value="2515">$2515.00</option>
                                                <option value="2520">$2520.00</option>
                                                <option value="2525">$2525.00</option>
                                                <option value="2530">$2530.00</option>
                                                <option value="2535">$2535.00</option>
                                                <option value="2540">$2540.00</option>
                                                <option value="2545">$2545.00</option>
                                                <option value="2550">$2550.00</option>
                                                <option value="2555">$2555.00</option>
                                                <option value="2560">$2560.00</option>
                                                <option value="2565">$2565.00</option>
                                                <option value="2570">$2570.00</option>
                                                <option value="2575">$2575.00</option>
                                                <option value="2580">$2580.00</option>
                                                <option value="2585">$2585.00</option>
                                                <option value="2590">$2590.00</option>
                                                <option value="2595">$2595.00</option>
                                                <option value="2600">$2600.00</option>
                                                <option value="2605">$2605.00</option>
                                                <option value="2610">$2610.00</option>
                                                <option value="2615">$2615.00</option>
                                                <option value="2620">$2620.00</option>
                                                <option value="2625">$2625.00</option>
                                                <option value="2630">$2630.00</option>
                                                <option value="2635">$2635.00</option>
                                                <option value="2640">$2640.00</option>
                                                <option value="2645">$2645.00</option>
                                                <option value="2650">$2650.00</option>
                                                <option value="2655">$2655.00</option>
                                                <option value="2660">$2660.00</option>
                                                <option value="2665">$2665.00</option>
                                                <option value="2670">$2670.00</option>
                                                <option value="2675">$2675.00</option>
                                                <option value="2680">$2680.00</option>
                                                <option value="2685">$2685.00</option>
                                                <option value="2690">$2690.00</option>
                                                <option value="2695">$2695.00</option>
                                                <option value="2700">$2700.00</option>
                                                <option value="2705">$2705.00</option>
                                                <option value="2710">$2710.00</option>
                                                <option value="2715">$2715.00</option>
                                                <option value="2720">$2720.00</option>
                                                <option value="2725">$2725.00</option>
                                                <option value="2730">$2730.00</option>
                                                <option value="2735">$2735.00</option>
                                                <option value="2740">$2740.00</option>
                                                <option value="2745">$2745.00</option>
                                                <option value="2750">$2750.00</option>
                                                <option value="2755">$2755.00</option>
                                                <option value="2760">$2760.00</option>
                                                <option value="2765">$2765.00</option>
                                                <option value="2770">$2770.00</option>
                                                <option value="2775">$2775.00</option>
                                                <option value="2780">$2780.00</option>
                                                <option value="2785">$2785.00</option>
                                                <option value="2790">$2790.00</option>
                                                <option value="2795">$2795.00</option>
                                                <option value="2800">$2800.00</option>
                                                <option value="2805">$2805.00</option>
                                                <option value="2810">$2810.00</option>
                                                <option value="2815">$2815.00</option>
                                                <option value="2820">$2820.00</option>
                                                <option value="2825">$2825.00</option>
                                                <option value="2830">$2830.00</option>
                                                <option value="2835">$2835.00</option>
                                                <option value="2840">$2840.00</option>
                                                <option value="2845">$2845.00</option>
                                                <option value="2850">$2850.00</option>
                                                <option value="2855">$2855.00</option>
                                                <option value="2860">$2860.00</option>
                                                <option value="2865">$2865.00</option>
                                                <option value="2870">$2870.00</option>
                                                <option value="2875">$2875.00</option>
                                                <option value="2880">$2880.00</option>
                                                <option value="2885">$2885.00</option>
                                                <option value="2890">$2890.00</option>
                                                <option value="2895">$2895.00</option>
                                                <option value="2900">$2900.00</option>
                                                <option value="2905">$2905.00</option>
                                                <option value="2910">$2910.00</option>
                                                <option value="2915">$2915.00</option>
                                                <option value="2920">$2920.00</option>
                                                <option value="2925">$2925.00</option>
                                                <option value="2930">$2930.00</option>
                                                <option value="2935">$2935.00</option>
                                                <option value="2940">$2940.00</option>
                                                <option value="2945">$2945.00</option>
                                                <option value="2950">$2950.00</option>
                                                <option value="2955">$2955.00</option>
                                                <option value="2960">$2960.00</option>
                                                <option value="2965">$2965.00</option>
                                                <option value="2970">$2970.00</option>
                                                <option value="2975">$2975.00</option>
                                                <option value="2980">$2980.00</option>
                                                <option value="2985">$2985.00</option>
                                                <option value="2990">$2990.00</option>
                                                <option value="2995">$2995.00</option>
                                                <option value="3000">$3000.00</option>
                                                <option value="3005">$3005.00</option>
                                                <option value="3010">$3010.00</option>
                                                <option value="3015">$3015.00</option>
                                                <option value="3020">$3020.00</option>
                                                <option value="3025">$3025.00</option>
                                                <option value="3030">$3030.00</option>
                                                <option value="3035">$3035.00</option>
                                                <option value="3040">$3040.00</option>
                                                <option value="3045">$3045.00</option>
                                                <option value="3050">$3050.00</option>
                                                <option value="3055">$3055.00</option>
                                                <option value="3060">$3060.00</option>
                                                <option value="3065">$3065.00</option>
                                                <option value="3070">$3070.00</option>
                                                <option value="3075">$3075.00</option>
                                                <option value="3080">$3080.00</option>
                                                <option value="3085">$3085.00</option>
                                                <option value="3090">$3090.00</option>
                                                <option value="3095">$3095.00</option>
                                                <option value="3100">$3100.00</option>
                                                <option value="3105">$3105.00</option>
                                                <option value="3110">$3110.00</option>
                                                <option value="3115">$3115.00</option>
                                                <option value="3120">$3120.00</option>
                                                <option value="3125">$3125.00</option>
                                                <option value="3130">$3130.00</option>
                                                <option value="3135">$3135.00</option>
                                                <option value="3140">$3140.00</option>
                                                <option value="3145">$3145.00</option>
                                                <option value="3150">$3150.00</option>
                                                <option value="3155">$3155.00</option>
                                                <option value="3160">$3160.00</option>
                                                <option value="3165">$3165.00</option>
                                                <option value="3170">$3170.00</option>
                                                <option value="3175">$3175.00</option>
                                                <option value="3180">$3180.00</option>
                                                <option value="3185">$3185.00</option>
                                                <option value="3190">$3190.00</option>
                                                <option value="3195">$3195.00</option>
                                                <option value="3200">$3200.00</option>
                                                <option value="3205">$3205.00</option>
                                                <option value="3210">$3210.00</option>
                                                <option value="3215">$3215.00</option>
                                                <option value="3220">$3220.00</option>
                                                <option value="3225">$3225.00</option>
                                                <option value="3230">$3230.00</option>
                                                <option value="3235">$3235.00</option>
                                                <option value="3240">$3240.00</option>
                                                <option value="3245">$3245.00</option>
                                                <option value="3250">$3250.00</option>
                                                <option value="3255">$3255.00</option>
                                                <option value="3260">$3260.00</option>
                                                <option value="3265">$3265.00</option>
                                                <option value="3270">$3270.00</option>
                                                <option value="3275">$3275.00</option>
                                                <option value="3280">$3280.00</option>
                                                <option value="3285">$3285.00</option>
                                                <option value="3290">$3290.00</option>
                                                <option value="3295">$3295.00</option>
                                                <option value="3300">$3300.00</option>
                                                <option value="3305">$3305.00</option>
                                                <option value="3310">$3310.00</option>
                                                <option value="3315">$3315.00</option>
                                                <option value="3320">$3320.00</option>
                                                <option value="3325">$3325.00</option>
                                                <option value="3330">$3330.00</option>
                                                <option value="3335">$3335.00</option>
                                                <option value="3340">$3340.00</option>
                                                <option value="3345">$3345.00</option>
                                                <option value="3350">$3350.00</option>
                                                <option value="3355">$3355.00</option>
                                                <option value="3360">$3360.00</option>
                                                <option value="3365">$3365.00</option>
                                                <option value="3370">$3370.00</option>
                                                <option value="3375">$3375.00</option>
                                                <option value="3380">$3380.00</option>
                                                <option value="3385">$3385.00</option>
                                                <option value="3390">$3390.00</option>
                                                <option value="3395">$3395.00</option>
                                                <option value="3400">$3400.00</option>
                                                <option value="3405">$3405.00</option>
                                                <option value="3410">$3410.00</option>
                                                <option value="3415">$3415.00</option>
                                                <option value="3420">$3420.00</option>
                                                <option value="3425">$3425.00</option>
                                                <option value="3430">$3430.00</option>
                                                <option value="3435">$3435.00</option>
                                                <option value="3440">$3440.00</option>
                                                <option value="3445">$3445.00</option>
                                                <option value="3450">$3450.00</option>
                                                <option value="3455">$3455.00</option>
                                                <option value="3460">$3460.00</option>
                                                <option value="3465">$3465.00</option>
                                                <option value="3470">$3470.00</option>
                                                <option value="3475">$3475.00</option>
                                                <option value="3480">$3480.00</option>
                                                <option value="3485">$3485.00</option>
                                                <option value="3490">$3490.00</option>
                                                <option value="3495">$3495.00</option>
                                                <option value="3500">$3500.00</option>
                                                <option value="3505">$3505.00</option>
                                                <option value="3510">$3510.00</option>
                                                <option value="3515">$3515.00</option>
                                                <option value="3520">$3520.00</option>
                                                <option value="3525">$3525.00</option>
                                                <option value="3530">$3530.00</option>
                                                <option value="3535">$3535.00</option>
                                                <option value="3540">$3540.00</option>
                                                <option value="3545">$3545.00</option>
                                                <option value="3550">$3550.00</option>
                                                <option value="3555">$3555.00</option>
                                                <option value="3560">$3560.00</option>
                                                <option value="3565">$3565.00</option>
                                                <option value="3570">$3570.00</option>
                                                <option value="3575">$3575.00</option>
                                                <option value="3580">$3580.00</option>
                                                <option value="3585">$3585.00</option>
                                                <option value="3590">$3590.00</option>
                                                <option value="3595">$3595.00</option>
                                                <option value="3600">$3600.00</option>
                                                <option value="3605">$3605.00</option>
                                                <option value="3610">$3610.00</option>
                                                <option value="3615">$3615.00</option>
                                                <option value="3620">$3620.00</option>
                                                <option value="3625">$3625.00</option>
                                                <option value="3630">$3630.00</option>
                                                <option value="3635">$3635.00</option>
                                                <option value="3640">$3640.00</option>
                                                <option value="3645">$3645.00</option>
                                                <option value="3650">$3650.00</option>
                                                <option value="3655">$3655.00</option>
                                                <option value="3660">$3660.00</option>
                                                <option value="3665">$3665.00</option>
                                                <option value="3670">$3670.00</option>
                                                <option value="3675">$3675.00</option>
                                                <option value="3680">$3680.00</option>
                                                <option value="3685">$3685.00</option>
                                                <option value="3690">$3690.00</option>
                                                <option value="3695">$3695.00</option>
                                                <option value="3700">$3700.00</option>
                                                <option value="3705">$3705.00</option>
                                                <option value="3710">$3710.00</option>
                                                <option value="3715">$3715.00</option>
                                                <option value="3720">$3720.00</option>
                                                <option value="3725">$3725.00</option>
                                                <option value="3730">$3730.00</option>
                                                <option value="3735">$3735.00</option>
                                                <option value="3740">$3740.00</option>
                                                <option value="3745">$3745.00</option>
                                                <option value="3750">$3750.00</option>
                                                <option value="3755">$3755.00</option>
                                                <option value="3760">$3760.00</option>
                                                <option value="3765">$3765.00</option>
                                                <option value="3770">$3770.00</option>
                                                <option value="3775">$3775.00</option>
                                                <option value="3780">$3780.00</option>
                                                <option value="3785">$3785.00</option>
                                                <option value="3790">$3790.00</option>
                                                <option value="3795">$3795.00</option>
                                                <option value="3800">$3800.00</option>
                                                <option value="3805">$3805.00</option>
                                                <option value="3810">$3810.00</option>
                                                <option value="3815">$3815.00</option>
                                                <option value="3820">$3820.00</option>
                                                <option value="3825">$3825.00</option>
                                                <option value="3830">$3830.00</option>
                                                <option value="3835">$3835.00</option>
                                                <option value="3840">$3840.00</option>
                                                <option value="3845">$3845.00</option>
                                                <option value="3850">$3850.00</option>
                                                <option value="3855">$3855.00</option>
                                                <option value="3860">$3860.00</option>
                                                <option value="3865">$3865.00</option>
                                                <option value="3870">$3870.00</option>
                                                <option value="3875">$3875.00</option>
                                                <option value="3880">$3880.00</option>
                                                <option value="3885">$3885.00</option>
                                                <option value="3890">$3890.00</option>
                                                <option value="3895">$3895.00</option>
                                                <option value="3900">$3900.00</option>
                                                <option value="3905">$3905.00</option>
                                                <option value="3910">$3910.00</option>
                                                <option value="3915">$3915.00</option>
                                                <option value="3920">$3920.00</option>
                                                <option value="3925">$3925.00</option>
                                                <option value="3930">$3930.00</option>
                                                <option value="3935">$3935.00</option>
                                                <option value="3940">$3940.00</option>
                                                <option value="3945">$3945.00</option>
                                                <option value="3950">$3950.00</option>
                                                <option value="3955">$3955.00</option>
                                                <option value="3960">$3960.00</option>
                                                <option value="3965">$3965.00</option>
                                                <option value="3970">$3970.00</option>
                                                <option value="3975">$3975.00</option>
                                                <option value="3980">$3980.00</option>
                                                <option value="3985">$3985.00</option>
                                                <option value="3990">$3990.00</option>
                                                <option value="3995">$3995.00</option>
                                                <option value="4000">$4000.00</option>
                                                <option value="4005">$4005.00</option>
                                                <option value="4010">$4010.00</option>
                                                <option value="4015">$4015.00</option>
                                                <option value="4020">$4020.00</option>
                                                <option value="4025">$4025.00</option>
                                                <option value="4030">$4030.00</option>
                                                <option value="4035">$4035.00</option>
                                                <option value="4040">$4040.00</option>
                                                <option value="4045">$4045.00</option>
                                                <option value="4050">$4050.00</option>
                                                <option value="4055">$4055.00</option>
                                                <option value="4060">$4060.00</option>
                                                <option value="4065">$4065.00</option>
                                                <option value="4070">$4070.00</option>
                                                <option value="4075">$4075.00</option>
                                                <option value="4080">$4080.00</option>
                                                <option value="4085">$4085.00</option>
                                                <option value="4090">$4090.00</option>
                                                <option value="4095">$4095.00</option>
                                                <option value="4100">$4100.00</option>
                                                <option value="4105">$4105.00</option>
                                                <option value="4110">$4110.00</option>
                                                <option value="4115">$4115.00</option>
                                                <option value="4120">$4120.00</option>
                                                <option value="4125">$4125.00</option>
                                                <option value="4130">$4130.00</option>
                                                <option value="4135">$4135.00</option>
                                                <option value="4140">$4140.00</option>
                                                <option value="4145">$4145.00</option>
                                                <option value="4150">$4150.00</option>
                                                <option value="4155">$4155.00</option>
                                                <option value="4160">$4160.00</option>
                                                <option value="4165">$4165.00</option>
                                                <option value="4170">$4170.00</option>
                                                <option value="4175">$4175.00</option>
                                                <option value="4180">$4180.00</option>
                                                <option value="4185">$4185.00</option>
                                                <option value="4190">$4190.00</option>
                                                <option value="4195">$4195.00</option>
                                                <option value="4200">$4200.00</option>
                                                <option value="4205">$4205.00</option>
                                                <option value="4210">$4210.00</option>
                                                <option value="4215">$4215.00</option>
                                                <option value="4220">$4220.00</option>
                                                <option value="4225">$4225.00</option>
                                                <option value="4230">$4230.00</option>
                                                <option value="4235">$4235.00</option>
                                                <option value="4240">$4240.00</option>
                                                <option value="4245">$4245.00</option>
                                                <option value="4250">$4250.00</option>
                                                <option value="4255">$4255.00</option>
                                                <option value="4260">$4260.00</option>
                                                <option value="4265">$4265.00</option>
                                                <option value="4270">$4270.00</option>
                                                <option value="4275">$4275.00</option>
                                                <option value="4280">$4280.00</option>
                                                <option value="4285">$4285.00</option>
                                                <option value="4290">$4290.00</option>
                                                <option value="4295">$4295.00</option>
                                                <option value="4300">$4300.00</option>
                                                <option value="4305">$4305.00</option>
                                                <option value="4310">$4310.00</option>
                                                <option value="4315">$4315.00</option>
                                                <option value="4320">$4320.00</option>
                                                <option value="4325">$4325.00</option>
                                                <option value="4330">$4330.00</option>
                                                <option value="4335">$4335.00</option>
                                                <option value="4340">$4340.00</option>
                                                <option value="4345">$4345.00</option>
                                                <option value="4350">$4350.00</option>
                                                <option value="4355">$4355.00</option>
                                                <option value="4360">$4360.00</option>
                                                <option value="4365">$4365.00</option>
                                                <option value="4370">$4370.00</option>
                                                <option value="4375">$4375.00</option>
                                                <option value="4380">$4380.00</option>
                                                <option value="4385">$4385.00</option>
                                                <option value="4390">$4390.00</option>
                                                <option value="4395">$4395.00</option>
                                                <option value="4400">$4400.00</option>
                                                <option value="4405">$4405.00</option>
                                                <option value="4410">$4410.00</option>
                                                <option value="4415">$4415.00</option>
                                                <option value="4420">$4420.00</option>
                                                <option value="4425">$4425.00</option>
                                                <option value="4430">$4430.00</option>
                                                <option value="4435">$4435.00</option>
                                                <option value="4440">$4440.00</option>
                                                <option value="4445">$4445.00</option>
                                                <option value="4450">$4450.00</option>
                                                <option value="4455">$4455.00</option>
                                                <option value="4460">$4460.00</option>
                                                <option value="4465">$4465.00</option>
                                                <option value="4470">$4470.00</option>
                                                <option value="4475">$4475.00</option>
                                                <option value="4480">$4480.00</option>
                                                <option value="4485">$4485.00</option>
                                                <option value="4490">$4490.00</option>
                                                <option value="4495">$4495.00</option>
                                                <option value="4500">$4500.00</option>
                                                <option value="4505">$4505.00</option>
                                                <option value="4510">$4510.00</option>
                                                <option value="4515">$4515.00</option>
                                                <option value="4520">$4520.00</option>
                                                <option value="4525">$4525.00</option>
                                                <option value="4530">$4530.00</option>
                                                <option value="4535">$4535.00</option>
                                                <option value="4540">$4540.00</option>
                                                <option value="4545">$4545.00</option>
                                                <option value="4550">$4550.00</option>
                                                <option value="4555">$4555.00</option>
                                                <option value="4560">$4560.00</option>
                                                <option value="4565">$4565.00</option>
                                                <option value="4570">$4570.00</option>
                                                <option value="4575">$4575.00</option>
                                                <option value="4580">$4580.00</option>
                                                <option value="4585">$4585.00</option>
                                                <option value="4590">$4590.00</option>
                                                <option value="4595">$4595.00</option>
                                                <option value="4600">$4600.00</option>
                                                <option value="4605">$4605.00</option>
                                                <option value="4610">$4610.00</option>
                                                <option value="4615">$4615.00</option>
                                                <option value="4620">$4620.00</option>
                                                <option value="4625">$4625.00</option>
                                                <option value="4630">$4630.00</option>
                                                <option value="4635">$4635.00</option>
                                                <option value="4640">$4640.00</option>
                                                <option value="4645">$4645.00</option>
                                                <option value="4650">$4650.00</option>
                                                <option value="4655">$4655.00</option>
                                                <option value="4660">$4660.00</option>
                                                <option value="4665">$4665.00</option>
                                                <option value="4670">$4670.00</option>
                                                <option value="4675">$4675.00</option>
                                                <option value="4680">$4680.00</option>
                                                <option value="4685">$4685.00</option>
                                                <option value="4690">$4690.00</option>
                                                <option value="4695">$4695.00</option>
                                                <option value="4700">$4700.00</option>
                                                <option value="4705">$4705.00</option>
                                                <option value="4710">$4710.00</option>
                                                <option value="4715">$4715.00</option>
                                                <option value="4720">$4720.00</option>
                                                <option value="4725">$4725.00</option>
                                                <option value="4730">$4730.00</option>
                                                <option value="4735">$4735.00</option>
                                                <option value="4740">$4740.00</option>
                                                <option value="4745">$4745.00</option>
                                                <option value="4750">$4750.00</option>
                                                <option value="4755">$4755.00</option>
                                                <option value="4760">$4760.00</option>
                                                <option value="4765">$4765.00</option>
                                                <option value="4770">$4770.00</option>
                                                <option value="4775">$4775.00</option>
                                                <option value="4780">$4780.00</option>
                                                <option value="4785">$4785.00</option>
                                                <option value="4790">$4790.00</option>
                                                <option value="4795">$4795.00</option>
                                                <option value="4800">$4800.00</option>
                                                <option value="4805">$4805.00</option>
                                                <option value="4810">$4810.00</option>
                                                <option value="4815">$4815.00</option>
                                                <option value="4820">$4820.00</option>
                                                <option value="4825">$4825.00</option>
                                                <option value="4830">$4830.00</option>
                                                <option value="4835">$4835.00</option>
                                                <option value="4840">$4840.00</option>
                                                <option value="4845">$4845.00</option>
                                                <option value="4850">$4850.00</option>
                                                <option value="4855">$4855.00</option>
                                                <option value="4860">$4860.00</option>
                                                <option value="4865">$4865.00</option>
                                                <option value="4870">$4870.00</option>
                                                <option value="4875">$4875.00</option>
                                                <option value="4880">$4880.00</option>
                                                <option value="4885">$4885.00</option>
                                                <option value="4890">$4890.00</option>
                                                <option value="4895">$4895.00</option>
                                                <option value="4900">$4900.00</option>
                                                <option value="4905">$4905.00</option>
                                                <option value="4910">$4910.00</option>
                                                <option value="4915">$4915.00</option>
                                                <option value="4920">$4920.00</option>
                                                <option value="4925">$4925.00</option>
                                                <option value="4930">$4930.00</option>
                                                <option value="4935">$4935.00</option>
                                                <option value="4940">$4940.00</option>
                                                <option value="4945">$4945.00</option>
                                                <option value="4950">$4950.00</option>
                                                <option value="4955">$4955.00</option>
                                                <option value="4960">$4960.00</option>
                                                <option value="4965">$4965.00</option>
                                                <option value="4970">$4970.00</option>
                                                <option value="4975">$4975.00</option>
                                                <option value="4980">$4980.00</option>
                                                <option value="4985">$4985.00</option>
                                                <option value="4990">$4990.00</option>
                                                <option value="4995">$4995.00</option>
                                                <option value="5000">$5000.00</option>

                                            </Form.Select>
                                        </div>
                                        <Form.Group className="mb-3" controlId="paypal-id">
                                            <Form.Label>PayPal Email Address</Form.Label>
                                            <Form.Control type="email" placeholder="Enter email" />
                                            <Form.Text className="text-muted">
                                                Don’t have a PayPal account? Click <a href="https://www.paypal.com/us/signup/account" target="_blank">here</a> to create one.
                                            </Form.Text>
                                        </Form.Group>
                                        <Button className="btn-style yellow-btn" type="submit">
                                            Save
                                        </Button>

                                        <p className="mini-note">Ready to begin the audiobook production and distribution process? Click the "+ New Audiobook" button in the top navigation bar to get started!</p>
                                    </div>
                                </Tab>
                            </Tabs>

                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="show-side sticky-top">
                            <div className="acc-profile-box">
                                <div className="acc-pb-img">
                                    <img src={userimg} alt="image" />
                                </div>
                                <div className="acc-pb-list">
                                    <ul>
                                        <li><FontAwesomeIcon icon={solid("user")} /> <span>Taj Salaam</span></li>
                                        <li><FontAwesomeIcon icon={solid("pen-fancy")} /> <span>Tj</span></li>
                                        <li><FontAwesomeIcon icon={solid("briefcase")} /> <span>BlaklistEd</span></li>
                                        <li><FontAwesomeIcon icon={solid("envelope")} /> <span>abc@gmail.com</span></li>
                                        <li><FontAwesomeIcon icon={solid("id-card-clip")} /> <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span></li>
                                    </ul>
                                </div>
                                <div className="acc-ob-edit">
                                    <Button className="btn-style yellow-btn" onClick={editProfileShow}>
                                        <FontAwesomeIcon icon={regular("pen-to-square")} /> Edit my profile
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>



            </div>
        </>
    )
}
