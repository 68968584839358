import React, { useState, useRef } from 'react';
import { Form, Modal, Button, OverlayTrigger, Tooltip, Row, Col } from 'react-bootstrap';
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import W9Component from '../W9Component';
import W8BENComponent from '../W8BENComponent';
import W8BENEComponent from '../W8BENEComponent';
import W8EXPComponent from '../W8EXPComponent';


export default function TaxForms() {
    const [selectedValue, setSelectedValue] = useState('');
    const [showModal, setShowModal] = useState(false);

    const handleSelectChange = (event) => {
        const value = event.target.value;
        setSelectedValue(value);
        if (value) {
            setShowModal(true);
        }
    };
    const handleCloseModal = () => setShowModal(false);
    const renderSelectedComponent = () => {
        switch (selectedValue) {
            case '1':
                return <W9Component />;
            case '2':
                return <W8BENComponent />;
            case '3':
                return <W8BENEComponent />;
            case '4':
                return <W8EXPComponent />;
            default:
                return null;
        }
    };

    // PHOTO ID
    const [selectedImage, setSelectedImage] = useState(null);
    const inputFileRef = useRef(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/') && (file.type === 'image/jpeg' || file.type === 'image/jpg')) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            alert('Please select a valid JPEG or JPG image file.');
        }
    };

    const handleRemoveImage = () => {
        setSelectedImage(null);
        if (inputFileRef.current) {
            inputFileRef.current.value = ''; // Reset the file input field value
        }
    };

    // ID TOOLTIP
    const imageIDTooltip = (props) => (
        <Tooltip id="imageIDTooltip" {...props}>
            Your government issued photo ID is required to confirm the information provided in your tax form is complete and accurate. (eg. Driver's license)
        </Tooltip>
    );

    return (
        <>
            <h4>Tax Forms</h4>
            <div className="tax-forms">
                <Form.Group controlId="formTaxFormSelect">
                    <Form.Label>Choose a Tax Form</Form.Label>
                    <Form.Select aria-label="Select tax form" value={selectedValue} onChange={handleSelectChange}>
                        <option value="">--Select--</option>
                        <option value="1">W9 (US Person / US Entity)</option>
                        <option value="2">W8BEN (Foreign (non US) Person)</option>
                        <option value="3">W8BENE (Foreign (non US) Company)</option>
                        <option value="4">W8EXP (Foreign Tax-Exempt Organization)</option>
                    </Form.Select>
                    <span>Please note that submitting a tax form through this platform will generate the actual tax form on completion. We will only retain the PDF tax form for financial and legal purposes; BlaklistEd does not retain any of the tax form data when submitted. Submitting false tax information will result in closure of your account.</span>
                </Form.Group>
                <Modal show={showModal} onHide={handleCloseModal} className='acc-pop'>
                    <Modal.Header closeButton>                        
                    </Modal.Header>
                    <Modal.Body>
                        {renderSelectedComponent()}
                    </Modal.Body>
                </Modal>
            </div>
            <div className="govt-id mt-4">
                <h4>Government Issued Photo ID</h4>
                <p>To protect authors and narrators against fraudulent transactions, government issued photo ID is required for all users. It helps us verify that the information provided in your tax form is complete and accurate.</p>

                <Form.Label>Government Issued Photo ID
                    <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={imageIDTooltip}
                    >
                        <Button variant="link">
                            <FontAwesomeIcon icon={solid("circle-info")} />
                        </Button>
                    </OverlayTrigger>
                </Form.Label>
                <Form.Group>
                    <Form.Control type="file" accept=".jpg,.jpeg" onChange={handleFileChange} ref={inputFileRef} />
                    <span>*Required JPG or JPEG files only</span>
                </Form.Group>
                {selectedImage && (
                    <div className="id-preview">
                        <img src={selectedImage} alt="Uploaded" />
                        <Button variant="danger" onClick={handleRemoveImage} style={{ marginLeft: '10px' }}>Remove</Button>
                    </div>
                )}

            </div>
            <div className="valid-points">
                <Row>
                    <Col md={5} className='right-points'>
                        <h6>Valid Photo ID</h6>
                        <ul>
                            <li><FontAwesomeIcon icon={solid("check")} /> Driver's License</li>
                            <li><FontAwesomeIcon icon={solid("check")} /> Passport</li>
                            <li><FontAwesomeIcon icon={solid("check")} /> Military Identification Card</li>
                            <li><FontAwesomeIcon icon={solid("check")} /> Indigenous Status Card</li>
                            <li><FontAwesomeIcon icon={solid("check")} /> Citizenship Card</li>
                            <li><FontAwesomeIcon icon={solid("check")} /> National Identity Card</li>
                            <li><FontAwesomeIcon icon={solid("check")} /> Proof of Age Photo Identification</li>
                        </ul>
                    </Col>
                    <Col md={5} className='neg-points'>
                        <h6>Invalid Photo ID</h6>
                        <ul>
                            <li><FontAwesomeIcon icon={solid("xmark")} /> Selfies</li>
                            <li><FontAwesomeIcon icon={solid("xmark")} /> A photo from your latest vacation</li>
                            <li><FontAwesomeIcon icon={solid("xmark")} /> Dating profile photos</li>
                            <li><FontAwesomeIcon icon={solid("xmark")} /> Prom Photos</li>
                            <li><FontAwesomeIcon icon={solid("xmark")} /> Yearbook photos from 1995</li>   
                        </ul>
                    </Col>
                </Row>
            </div>
        </>
    )
}
