import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Nav, Tabs, Tab, Dropdown, Form, Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// MEDIA
import audioicon from "assets/006-audio-book-2.png"
export default function MyAudiobooks() {
    return (
        <>
            <div className="dpage-heading">
                <h1>My Audiobooks</h1>
            </div>
            <Tabs
                defaultActiveKey="production"
                id="drafts-tab"
                className="mb-3 draft-tabs"
            >
                <Tab eventKey="production" title="Production Audiobooks">
                    <div className="draft-layout">
                        <Row>
                            <Col lg={3}>
                                <div className="draft-box">
                                    <div class="img-holder">
                                        <img src={audioicon} alt="icon" />
                                    </div>
                                    <div className="draft-name">
                                        <span>Lorem Ipsum</span>
                                        <Link to='/theblaklisted/newaudiobook'>
                                            View project  <FontAwesomeIcon icon={solid("circle-right")} />
                                        </Link>
                                    </div>

                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="draft-box">
                                    <div class="img-holder">
                                        <img src={audioicon} alt="icon" />
                                    </div>
                                    <div className="draft-name">
                                        <span>Lorem Ipsum</span>
                                        <Link to='/theblaklisted/newaudiobook'>
                                            View project  <FontAwesomeIcon icon={solid("circle-right")} />
                                        </Link>
                                    </div>

                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="draft-box">
                                    <div class="img-holder">
                                        <img src={audioicon} alt="icon" />
                                    </div>
                                    <div className="draft-name">
                                        <span>Lorem Ipsum</span>
                                        <Link to='/theblaklisted/newaudiobook'>
                                            View project  <FontAwesomeIcon icon={solid("circle-right")} />
                                        </Link>
                                    </div>

                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="draft-box">
                                    <div class="img-holder">
                                        <img src={audioicon} alt="icon" />
                                    </div>
                                    <div className="draft-name">
                                        <span>Lorem Ipsum</span>
                                        <Link to='/theblaklisted/newaudiobook'>
                                            View project  <FontAwesomeIcon icon={solid("circle-right")} />
                                        </Link>
                                    </div>

                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="draft-box">
                                    <div class="img-holder">
                                        <img src={audioicon} alt="icon" />
                                    </div>
                                    <div className="draft-name">
                                        <span>Lorem Ipsum</span>
                                        <Link to='/theblaklisted/newaudiobook'>
                                            View project  <FontAwesomeIcon icon={solid("circle-right")} />
                                        </Link>
                                    </div>

                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="draft-box">
                                    <div class="img-holder">
                                        <img src={audioicon} alt="icon" />
                                    </div>
                                    <div className="draft-name">
                                        <span>Lorem Ipsum</span>
                                        <Link to='/theblaklisted/newaudiobook'>
                                            View project  <FontAwesomeIcon icon={solid("circle-right")} />
                                        </Link>
                                    </div>

                                </div>
                            </Col>
                        </Row>
                    </div>
                </Tab>
                <Tab eventKey="distribution" title="Distribution Audiobooks">
                    <div className="draft-layout">
                        <Row>
                            <Col lg={3}>
                                <div className="draft-box">
                                    <div class="img-holder">
                                        <img src={audioicon} alt="icon" />
                                    </div>
                                    <div className="draft-name">
                                        <span>Lorem Ipsum</span>
                                        <Link to='/theblaklisted/newaudiobook'>
                                            View project  <FontAwesomeIcon icon={solid("circle-right")} />
                                        </Link>
                                    </div>

                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="draft-box">
                                    <div class="img-holder">
                                        <img src={audioicon} alt="icon" />
                                    </div>
                                    <div className="draft-name">
                                        <span>Lorem Ipsum</span>
                                        <Link to='/theblaklisted/newaudiobook'>
                                            View project  <FontAwesomeIcon icon={solid("circle-right")} />
                                        </Link>
                                    </div>

                                </div>
                            </Col>                            
                        </Row>
                    </div>
                </Tab>
            </Tabs>
        </>
    )
}
